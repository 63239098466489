import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CssBaseline } from '@mui/material';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import Api from '../../Api';

function EmailActivation(props: any) {
  let [params, SetParams] = useSearchParams();
  let navigate = useNavigate();
  let code = params.get('code'); // code from email for email activation
  let source = params.get('source');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [bodyContent, setBodyContent] = useState<any>('');

  const StyledText = {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#E449BD',
    marginTop: '15px',
  };

  const StyledSignInBtn = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.mode == 'light' ? '#18768C' : '#2CDADA',
    padding: '0px',
    ':hover': {
      color: theme.palette.mode == 'light' ? '#18768C' : '#2CDADA',
    },
  };

  useEffect(() => {
    // send off code to verify
    Api.verifyEmail(code)
      .then((res) => {
        if (res.success) {
          if (source) {
            navigate(`/login/?source=${source}`);
          } else {
            navigate('/login');
          }
        } else {
          const link = <a href={window.location.origin + '/register'} style={StyledSignInBtn}>Return to sign-up screen</a>;
          const msg = <><p style={StyledText}>Your activation link has expired.<br /></p>{link}</>;
          setBodyContent(msg);
        }
      })
      .catch(() => {
        navigate('/login');
      });
  }, []); // <-- empty array means 'run once'

  const TitleStyle = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: isMobile ? '31px' : '41px',
    lineHeight: isMobile ? '41px' : '54px',
    color: theme.palette.mode == 'light' ? '#002136' : '#FFFFFF',
    textAlign: 'left',
  };

  const MainStyledBox = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  };

  const StyledDividerLeft = {
    borderLeft: isMobile
      ? ' '
      : theme.palette.mode == 'light'
        ? '1px solid rgba(0, 33, 54, 0.2)'
        : '1px solid  rgba(255, 255, 255, 0.2)',
    paddingLeft: isMobile ? ' ' : '40px',
    marginTop: isMobile ? '40px' : '140px',
    width: isMobile ? '100%' : '50%',
  };

  const StyledBoxMainWrapper = {
    background:
      theme.palette.mode == 'light'
        ? isMobile
          ? 'url(res/SignInBGLightMobile.svg) no-repeat top'
          : 'url(res/SignInBGLight.svg) no-repeat top'
        : isMobile
          ? 'url(res/SignInBGDarkMobile.svg) no-repeat top'
          : 'url(res/SignInBGDark.svg) no-repeat top',
    backgroundPosition: isMobile ? 'right bottom' : 'right',
    height: isMobile ? '510px' : '570px',
  };

  return (
    <>
      <CssBaseline />
      <Header />
      <Box sx={StyledBoxMainWrapper}>
        <Container maxWidth="lg">
          <Box sx={StyledDividerLeft}>
            <Box>
              <Box sx={MainStyledBox}>
                <Typography variant="h2" sx={TitleStyle}>
                  Activating Email
                </Typography>
                {bodyContent}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default EmailActivation;
