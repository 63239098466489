import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import HDCalendar from '../../hd-ui-kit/HDCalendar/HDCalendar';

const CoachCalenderPicker = (props: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const StyledCalenderWrapper = { width: isMobile ? '100%' : '85%', marginTop: '30px', marginBottom: '30px' };

  return (
    <Box sx={StyledCalenderWrapper}>
      <HDCalendar
        coachAvailableDates={props?.availableDates}
        onDateSelect={(date: any) => props.dateSelect(date)}
        defaultMonthDate={props?.defaultMonthDate}
        onCalendarMonthChange={(date: any) => props?.onCalendarMonthChange(date)}
      />{' '}
    </Box>
  );
};

export default CoachCalenderPicker;
