import React from 'react';
import { Box, Grid } from '@mui/material';
import HDDropDown from '../../hd-ui-kit/HDDropDown';

export interface CoachDatePickerTypes {
  month: any;
  monthList: Array<any>;
  onMonthChange: any;
  day: any;
  dayList: Array<any>;
  onDayChange: any;
  year: any;
  yearList: Array<any>;
  onYearChange: any;
}

function CoachDatePicker({ month, monthList, onMonthChange, day, dayList, onDayChange, year, yearList, onYearChange }: CoachDatePickerTypes) {
  const sxDateTimeBox = {
    marginTop: '32px',
    marginBottom: '76px',
  };

  return (
    <Box sx={sxDateTimeBox}>
      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <HDDropDown
            fieldLabel="Month"
            defaultValue=""
            value={month}
            dropDownItem={monthList}
            fullWidth={false}
            disabled={false}
            isCheckmark
            onChange={({ target }: any) => {
              onMonthChange(target);
            }}
            id='month-label'
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <HDDropDown
            fieldLabel="Day"
            defaultValue=""
            value={day}
            dropDownItem={dayList}
            fullWidth={false}
            disabled={false}
            isCheckmark
            onChange={({ target }: any) => {
              onDayChange(target);
            }}
            id='day-label'
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <HDDropDown
            fieldLabel="Year"
            defaultValue=""
            value={year}
            dropDownItem={yearList}
            fullWidth={false}
            disabled={false}
            isCheckmark
            onChange={({ target }: any) => {
              onYearChange(target);
            }}
            id='year-label'
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default CoachDatePicker;
