import React, { useState, useContext } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import HDModal from './hd-ui-kit/HDModal';
import Countdown from 'react-countdown';
import HDButton from './hd-ui-kit/HDButton';
import { Box } from '@mui/material';
import { CssBaseline } from '@mui/material';
import Api from './Api';
import { useNavigate } from 'react-router';
import { globalContext } from './store';

function IdleTimer() {
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate();
  const { dispatch } = useContext(globalContext);

  const logoutUser = () => {
    setLogoutModal(false);
    dispatch({ type: 'PURGE_STATE', payload: {} });
    Api.logout()
      .then((res) => {
        // if (res.success) {
        //   navigate('/login');
        // } else {
          navigate('/login');
        // }
      })
      .catch((err) => {
        console.log(err);
        navigate('/login');
      });
  };

  const onIdle = () => {
    const token = sessionStorage.getItem('token');
    if (token != '' && token != undefined) {
      setLogoutModal(true);
    }
  };

  const closeModal = () => {
    setLogoutModal(false);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useIdleTimer({
    onIdle,
    timeout: parseInt(process.env.REACT_APP_SESSION_TIME_OUT),
    promptTimeout: 0,
    events: ['mousemove', 'keydown', 'wheel', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'MSPointerDown', 'MSPointerMove', 'visibilitychange'],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: true,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  });

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <>{() => logoutUser()}</>;
    } else {
      // Render a countdown
      return (
        <span>
          {'0' + hours}:{minutes <= 9 ? '0' + minutes : minutes}:{seconds <= 9 ? '0' + seconds : seconds}
        </span>
      );
    }
  };

  const ModalDescription = () => {
    return (
      <div>
        Your session is about to expire in&nbsp;
        <Countdown date={Date.now() + parseInt(process.env.REACT_APP_SESSION_COUNT_DOWN)} renderer={renderer} />. Would you like to remain logged in?
      </div>
    );
  };

  const actionBtnStyle = {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
  };

  const btnOneStyle = {
    padding: '10px 40px',
    marginRight: '10px',
  };

  const btnTwoStyle = {
    padding: '10px 40px',
  };

  const ModalAction = () => {
    return (
      <Box sx={actionBtnStyle}>
        <HDButton onClick={logoutUser} text={'No'} variant={'contained'} size={'medium'} sx={btnOneStyle} />
        <HDButton onClick={() => setLogoutModal(false)} text={'Yes'} variant={'contained'} size={'medium'} sx={btnTwoStyle} />
      </Box>
    );
  };

  return (
    <>
      <CssBaseline />
      <HDModal open={logoutModal} title={'Session Expiring!'} description={<ModalDescription />} handleModal={closeModal} actions={<ModalAction />} />
    </>
  );
}

export default IdleTimer;
