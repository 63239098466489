import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import ReactGA from 'react-ga4';

import { headerStyle, subHeaderStyle, pageWrapper, titleUnderline, btnStyle, btnIconStyle, btnWrapper, infoBoxStyle, textFieldStyle } from './styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HDButton from '../../../hd-ui-kit/HDButton';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import HDCheckBox from '../../../hd-ui-kit/HDCheckBox/HDCheckBox';
import HDCitySelection from '../../../hd-ui-kit/HDCitySelection/HDCitySelection';
import HDMultipleCitySelection from '../../../hd-ui-kit/HDMultipleCitySelection';
import Api from '../../../Api';
import { validateFields, validationOptions } from '../../../ValidationSchema';
import { reactGAEvents } from '../../../commons/commonFunctions';
import HDSpinner from '../../../hd-ui-kit/HDSpinner/HDSpinner';

function LocationPreferences(props: any) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const themeMode = theme.palette.mode;
  const [data, setData] = useState<any>(null);
  const [topCity, setTopCity] = useState<string>('');
  const [secondCity, setSecondCities] = useState<any>(['']);
  const [wfh_interest, setWFHInterest] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [btnDisable, setBtnDisable] = useState(false);

  const textFieldWrap = {
    marginTop: '60px',
  };

  const noteStyle = {
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    color: themeMode === 'light' ? '#334255' : '#CCC',
  };

  const chkBoxStyle = {
    marginTop: '16px',
    border: '2px solid transparent',
  };

  useEffect(() => {
    if (props?.data) {
      setData(props?.data);
      setFormDataFromContext(props?.data);
    }
  }, [props?.data]);

  const onSuccessProcess = (output: any) => {
    props.onSuccess(true);
    setData({
      ...data,
      ...output,
    });
    window.scrollTo(0, 0);
  };

  const setFormDataFromContext = (data: any) => {
    setTopCity(data['top_city']);
    setSecondCities(data?.['secondary_cities']?.length ? data?.['secondary_cities'] : ['']);
    setWFHInterest(data['wfh_interest']);
  };

  useEffect(() => {
    if (topCity == '' && secondCity.length > 0 && secondCity?.[0]) {
      const cities = [...secondCity];
      setTopCity(cities.shift());
      setSecondCities([...cities]);
    }
  }, [topCity]);
  console.log('errorrrr', errors);
  const saveObject = (event: { preventDefault: () => void }) => {
    setErrors({});
    props.onSuccess(false);
    event.preventDefault();
    reactGAEvents('my-account', 'save');
    setBtnDisable(true);

    let secondCity_errors: any = {};
    let secondCityMsg: any = {};
    let secondCityMsgDup: any = {};
    if (secondCity.length !== [...new Set(secondCity)].length) {
      secondCityMsgDup = 'No duplicates are allowed';
    } else if (secondCity?.[0] !== '' && secondCity?.length !== 1) {
      if (secondCity.length) {
        secondCity.forEach((field: any, idx: number) => {
          if (!field) {
            secondCity_errors[`${idx}`] = 'This field may not be blank.';
          }
        });
        if (Object.keys(secondCity_errors).length) {
          secondCityMsg = secondCity_errors;
        }
      }
    }

    if (Object.keys(secondCityMsg).length || Object.keys(secondCityMsgDup).length) {
      setErrors({
        secondary_cities: secondCityMsg,
        secondary_cities_Dup: secondCityMsgDup,
      });
      setBtnDisable(false);
      return;
    }

    setErrors({});
    let secondCityPayload = secondCity.length === 1 && secondCity[0] === '' ? [] : secondCity;

    let payload = {
      top_city: topCity,
      secondary_cities: secondCityPayload,
      wfh_interest: wfh_interest,
    };

    // send data off to be saved
    if (data) {
      Api.updateLocationPreferences(payload, data['id'] as never)
        .then((res) => {
          onApiSuccess(res, 'Edit');
        })
        .catch((err) => {
          console.log(err);
          setBtnDisable(false);
        });
    } else {
      Api.saveLocationPreferences(payload)
        .then((res) => {
          onApiSuccess(res, 'Save');
        })
        .catch((err) => {
          console.log(err);
          setBtnDisable(false);
        });
    }
  };

  const onApiSuccess = (res: any, actItem: string) => {
    if (res?.success) {
      ReactGA.event({
        category: 'Profile Edit - Preferred location for future work',
        action: actItem,
      });
      setBtnDisable(false);
      onSuccessProcess(res?.response?.data);
    } else {
      const { data } = res;
      if (data?.non_field_errors) {
        setErrors({ secondary_cities: data?.non_field_errors });
      } else {
        setErrors(data);
      }
      setBtnDisable(false);
    }
  };

  return (
    <Box sx={pageWrapper(isMobile, themeMode)}>
      <Typography sx={headerStyle(themeMode)}>Preferred location for future work</Typography>
      <Divider sx={titleUnderline(themeMode)} />

      <Box sx={textFieldWrap}>
        <Typography sx={noteStyle}>Let us know where you'd like to work, and our search team will take it into consideration. (optional)</Typography>

        <Box sx={infoBoxStyle(themeMode)}>
          <Typography sx={subHeaderStyle(themeMode)}>Provide a top choice</Typography>
          <Box sx={textFieldStyle}>
            <HDCitySelection
              placeholder="Number one preferred city"
              label=""
              type={'text'}
              required={true}
              value={topCity}
              onChange={(value: any) => {
                reactGAEvents('my-account', 'city-top-choice');
                setTopCity(String(value));
              }}
              error={errors?.top_city}
              errorMsg={errors?.top_city ? errors.top_city : null}
              disabled={false}
              size={''}
            />
          </Box>
          <Typography sx={subHeaderStyle(themeMode)}>Provide a secondary choice</Typography>
          <HDMultipleCitySelection
            id="secondCities"
            lblText={''}
            placeholder={'Number two preferred city'}
            value={secondCity}
            onChange={(value: string) => {
              reactGAEvents('my-account', 'city-secondary-choice');
              setSecondCities([...secondCity, value]);
            }}
            buttonProps={{
              text: 'Add more cities',
              startIcon: <AddCircleOutlineRoundedIcon />,
            }}
            errorMsg={errors?.secondary_cities && Object.keys(errors?.secondary_cities)?.length ? errors.secondary_cities : null}
            errorMsgDup={errors?.secondary_cities_Dup && Object.keys(errors?.secondary_cities_Dup)?.length ? errors.secondary_cities_Dup : null}
            setterFn={setSecondCities}
            disabled={topCity == ''}
          />
          <HDCheckBox
            showBackground={true}
            labelTxt={"I'm interested in working remotely"}
            wrapperSX={chkBoxStyle}
            checked={wfh_interest}
            value={wfh_interest}
            onChange={({ target }: any) => {
              reactGAEvents('my-account', 'interested-working-remotely');
              setWFHInterest(target.checked);
            }}
            id="interested-working-remotely"
          />
        </Box>
      </Box>

      <Box sx={btnWrapper}>
        <HDButton text={'Save'} disabled={btnDisable} endIcon={btnDisable ? <HDSpinner size={'la-sm'} /> : <ArrowForwardIcon sx={btnIconStyle(themeMode)} />} variant={'contained'} sx={btnStyle(themeMode)} onClick={saveObject} showAnimation={true} />
      </Box>
    </Box>
  );
}

export default LocationPreferences;
