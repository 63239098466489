export const styledTitle = (isDarkTheme: boolean) => {
  return {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '29px',
    color: isDarkTheme ? '#FFFFFF' : '#002136',
  };
};

export const styledDescrition = (isDarkTheme: boolean) => {
  return {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '26px',
    color: isDarkTheme ? '#CCCCCC' : '#334255',
    marginBottom: '20px',
  };
};

export const styledReadMoreText = (isDarkTheme: boolean) => {
  return {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '23px',
    letterSpacing: '0.02em',
    color: isDarkTheme ? '#FFFFFF' : '#646A7C',
    textDecoration: 'underline',
    cursor: 'pointer',
  };
};

export const styledDot = (isDarkTheme: boolean) => {
  return {
    fontFamily: 'Aventa',
    fontSize: '0.8rem',
    marginTop: '5px',
    marginRight: '8px',
    color: isDarkTheme ? '#CCCCCC' : '#334255',
  };
};

export const styledDivider = (isDarkTheme: boolean) => {
  return {
    color: isDarkTheme ? 'rgba(255, 255, 255, 0.2)' : 'rgba(100, 106, 124, 0.2)',
    marginTop: '22px',
  };
};