import React, { useState, useRef, useEffect, useContext } from 'react';
import { Box, Divider, Typography, useMediaQuery, useTheme, Container, CssBaseline, styled } from '@mui/material';
import { titleUnderline } from '../profile/form-component/styles';
import HDButton from '../../hd-ui-kit/HDButton/HDButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FindCoach from './FindCoach';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { reactGAEvents } from '../../commons/commonFunctions';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import Api from '../../Api';
import Link from '@mui/material/Link';
import { globalContext } from '../../store';
import { backgroundTopLeft, backgroundTopLeftDark, generateCoachData } from '../../commons/commonFunctions';
import Loader from '../../commons/Loader';
import ViewCoachProfile from './ViewCoachProfile';
import { FullScreenModal } from './../../pages/FullScreenModal';

dayjs.extend(utc);
dayjs.extend(timezone);

function ChemistrySessionDetails(props: any) {
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  const originHost = window.location.origin;

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const [loading, setLoading] = useState<boolean>(false);
  const [openToaster, setOpenToaster] = React.useState(false);
  const [openViewProfile, setOpenViewProfile] = React.useState(false);
  const [sessionsData, setSessionsData] = useState<any>({});
  const [coachData, setAllCoachData] = useState<any>();
  const [coachItem, setCoachItem] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<any>('');
  const { globalState, dispatch } = useContext(globalContext);

  const coachModalRef = useRef(null);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const BodyWrapper = styled(Box)(() => ({
    marginTop: '32px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexFlow: isMobile ? 'column' : 'row',
  }));

  const LeftSection = styled(Box)(() => ({
    background: themeMode === 'light' ? '#F8F8F8' : '#002136',
    border: themeMode === 'light' ? '1px solid rgba(100, 106, 124, 0.2)' : 'none',
    borderRadius: '6px',
    padding: '24px',
    width: isMobile ? '100%' : '35%',
    marginBottom: isMobile ? '20px' : '0px',
  }));

  const RightSection = styled(Box)(() => ({
    background: 'transparent',
    border: themeMode === 'light' ? '1px solid rgba(100, 106, 124, 0.2)' : '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: '6px',
    padding: '24px',
    width: isMobile ? '100%' : '63%',
  }));

  const fontFamily = { fontFamily: 'Aventa' };
  const fontColor = { color: themeMode === 'light' ? '#002136' : '#fff' };

  const boxTitleStyle = {
    ...fontFamily,
    ...fontColor,
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '29px',
  };

  const dateStyle = {
    ...fontFamily,
    ...fontColor,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    paddingTop: '16px',
  };

  const coachLinkStyle = {
    ...fontFamily,
    ...fontColor,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '23px',
    textDecorationLine: 'underline',
    marginTop: '16px',
    cursor: 'pointer',
  };

  const dividerStyle = {
    marginTop: '16px',
    borderColor: themeMode === 'light' ? 'rgba(100, 106, 124, 0.2)' : 'rgba(255, 255, 255, 0.2)',
  };

  const zoomBtnWrap = {
    marginTop: '48px',
  };

  const divLinkStyle = {
    marginTop: '15px'
  }

  const zoomBtnStyle = {
    ':disabled': {
      ...fontFamily,
      ...fontColor,
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '22px',
      background: themeMode === 'light' ? '#FFFFFF' : '#0B0C10',
      borderRadius: '6px',
      width: '100%',
      ':hover': {
        background: themeMode === 'light' ? '#FFFFFF' : '#0B0C10',
      },
    },
  };

  const copyLinkStyle = {
    ...fontFamily,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '23px',
    textDecorationLine: 'underline',
    color: themeMode === 'light' ? '#646A7C' : '#FFF',
    padding: 0,
    marginTop: '16px',
    ':hover': {
      color: themeMode === 'light' ? '#646A7C' : '#FFF',
    },
    ':focus': {
      color: themeMode === 'light' ? '#646A7C' : '#FFF',
    },
    cursor: 'pointer'

  };

  const discussionStyle = {
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: themeMode === 'light' ? '#334255' : '#CCC',
    marginTop: '16px',
  };

  const TextIconStyle = styled('img')(() => ({
    width: '12px',
    marginRight: '10px',
    top: '1px',
    position: 'relative',
  }));

  const ArrowIcon = styled(ArrowForwardIcon)(() => ({
    color: themeMode === 'light' ? '#66FCFC' : '#18768C',
  }));

  const StyledViewProfile = {
    color: themeMode === 'light' ? '#334255' : '#FFF',
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    cursor: 'pointer',
  };

  const containerStyle = {
    padding: '0 !important',
  };

  const pageWrapper = (isMobile: boolean, themeMode?: string) => {
    const backColor = themeMode === 'light' ? { ...backgroundTopLeft } : { ...backgroundTopLeftDark };
    return {
      textAlign: 'left',
      paddingTop: '54px',
      marginBottom: '64px',
      ...backColor,
    };
  };

  const titleNote = (themeMode: string) => {
    return {
      color: themeMode === 'light' ? '#334255' : '#CCC',
      fontFamily: 'Avenir',
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '24px',
      paddingLeft: isMobile ? '11px' : '',
    };
  };

  const StyledAccountIcon = {
    border: '1px solid' + (isDarkTheme ? '#646A7C' : '#CCCCCC'),
    borderRadius: '50px',
    width: 'auto',
    display: 'flex',
  };

  const ProfileInfoStyle = {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    textAlign: 'right',
    cursor: 'default',
    marginRight: '8px',
    width: 'max-content',
    color: '#002136',
  };

  const headerStyle = (themeMode: string) => {
    return {
      fontFamily: 'Aventa',
      fontWeight: 500,
      fontSize: isMobile ? '20px' : '31px',
      lineHeight: isMobile ? '30px' : '41px',
      color: themeMode === 'light' ? '#002136' : '#fff',
    };
  };
  const StyledPaddingForMobile = { paddingTop: isMobile ? '20px' : '' };
  const StyledViewCoach = { display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '90%', padding: '8px 8px 8px 24px', alignItems: 'center' };
  const StyledCoachName = { paddingTop: '5px' };
  const StyledUpcomingText = { display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : '', paddingLeft: isMobile ? '11px' : '' };

  useEffect(() => {
    getCoachSession();
  }, [globalState?.sessionsData?.response?.data, globalState?.myCoachData]);

  const getCoachSession = async () => {
    setLoading(true);
    let res: any;
    if (globalState?.sessionsData?.response?.data) {
      res = globalState?.sessionsData;
    }
    if (res?.success) {
      let coachResp: any;
      if (globalState?.myCoachData) {
        coachResp = globalState.myCoachData;
      }
      if (coachResp?.success) {
        const sessionData = { coach_full_name: res?.response?.data?.coach_full_name, coach_avatar_url: res?.response?.data?.coach_avatar_url, sessions: res?.response?.data?.sessions };
        setSessionsData(sessionData);
        let item = coachResp.response.data;
        setCoachItem(item);
        let data = generateCoachData(item, isDarkTheme);
        setAllCoachData(data);
        setLoading(false);
        setErrorMessage('');
      } else {
        setLoading(false);
        var link = (
          <a href="https://heidricksupport.zendesk.com/hc/en-us" target="_blank" style={{ color: 'inherit' }}>
            contact support
          </a>
        );
        const errorMsg = <p>There seems to be a problem accessing your coach feature. Please contact support for help. {link}.</p>;
        setErrorMessage(errorMsg);
      }
    } else {
      setLoading(false);
    }
  };

  const handleClick = (link: string) => {
    setOpenToaster(true);
    if ('clipboard' in navigator) {
      navigator?.clipboard?.writeText(link);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToaster(false);
  };

  const handleFSModal = () => {
    setOpenViewProfile(false);
    // if (coachModalRef.current) coachModalRef.current.style.display = 'none';
  };

  const handleViewProfile = () => {
    // if (coachModalRef.current) coachModalRef.current.style.display = 'block';
    setOpenViewProfile(true);
  };

  const sendEmailTo = () => {
    const leaderDetails = globalState?.account?.personal_details;
    const coachEmail = coachItem?.email;
    const coachName = coachItem?.first_name + ' ' + coachItem?.last_name;
    const leaderName = leaderDetails?.first_name + ' ' + leaderDetails?.last_name;
    const lineBreak = '%0d%0a%0d%0a';
    window.location.href = `mailto:${coachEmail}?subject=${leaderName} would like to reschedule their session&body=Dear ${coachName},${lineBreak}I would like to reschedule my appointment.${lineBreak}Here are some availabilities: %0d%0a%0d%0a%0d%0a`;
  };

  return (
    <>
      <CssBaseline />
      {loading && <Loader />}
      <Header />
      <Box sx={isDarkTheme ? { ...backgroundTopLeftDark } : { ...backgroundTopLeft }}>
        <Container maxWidth="lg" sx={isMobile ? containerStyle : {}}>
          <Box sx={pageWrapper(isMobile, themeMode)}>
            <>
              <Typography sx={titleNote(themeMode)}>Upcoming Session</Typography>
              <Box sx={StyledUpcomingText}>
                <Box>
                  <Typography sx={headerStyle(themeMode)}>Intro Session with {sessionsData?.coach_full_name}</Typography>
                  <Typography sx={headerStyle(themeMode)}>
                    {sessionsData?.sessions?.[0]?.start_date_hfl_specific ? (
                      <>
                        {dayjs(new Date(sessionsData?.sessions?.[0]?.start_date_hfl_specific?.replace(/-/g, '/'))).format('dddd, MMM D')} at
                        {dayjs(new Date(sessionsData?.sessions?.[0]?.start_date_hfl_specific?.replace(/-/g, '/'))).format(' h:mm A')}
                      </>
                    ) : (
                      ''
                    )}
                  </Typography>
                </Box>
                {isMobile ? <Divider sx={titleUnderline(themeMode)} /> : <></>}
                <Box sx={StyledPaddingForMobile}>
                  <Box sx={StyledAccountIcon}>
                    <Box sx={StyledViewCoach}>
                      <Box>
                        <Box>
                          <Typography sx={ProfileInfoStyle}>{sessionsData?.coach_full_name}</Typography>
                        </Box>
                        <Box onClick={() => handleViewProfile()}>
                          <Typography sx={StyledViewProfile}>View Profile</Typography>
                        </Box>
                      </Box>

                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img style={{ borderRadius: '50%' }} height={'45px'} width={'45px'} src={coachItem?.avatar_url} alt="Profile icon" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {!isMobile ? <Divider sx={titleUnderline(themeMode)} /> : <></>}

              <BodyWrapper>
                <LeftSection>
                  <Box sx={boxTitleStyle}>
                    Details
                    <Divider sx={dividerStyle} />
                  </Box>
                  <Box sx={dateStyle}>
                    <TextIconStyle src={themeMode === 'light' ? '../res/calendarLight.svg' : '../res/calendarDark.svg'} />
                    {sessionsData?.sessions?.[0]?.start_date_hfl_specific ? (
                      <>
                        {dayjs(new Date(sessionsData?.sessions?.[0]?.start_date_hfl_specific?.replace(/-/g, '/'))).format('ddd, MMM D')} at
                        {dayjs(new Date(sessionsData?.sessions?.[0]?.start_date_hfl_specific?.replace(/-/g, '/'))).format(' h:mm A')}{' '}
                      </>
                    ) : (
                      ''
                    )}

                    <Divider sx={dividerStyle} />
                  </Box>
                  <Box sx={dateStyle}>
                    <TextIconStyle src={themeMode === 'light' ? '../res/clockLight.svg' : '../res/clockDark.svg'} />
                    {sessionsData?.sessions?.[0]?.duration} minutes
                    <Divider sx={dividerStyle} />
                  </Box>
                  <Box sx={{ marginTop: '15px' }}>
                    <Link
                      tabIndex={0}
                      underline='none'
                      onClick={sendEmailTo}
                      sx={coachLinkStyle}
                    >
                      <TextIconStyle src={themeMode === 'light' ? '../res/profileLight.svg' : '../res/profileDark.svg'} />
                      {sessionsData?.coach_full_name}
                    </Link>
                  </Box>
                  <Box sx={zoomBtnWrap}>
                    {sessionsData?.sessions?.[0]?.zoom_url ? (
                      <HDButton
                        variant={'contained'}
                        text={'Join video session'}
                        showAnimation={true}
                        endIcon={<ArrowIcon />}
                        onClick={() => {
                          reactGAEvents('coach-session-management', 'start-session');
                          window.open(sessionsData?.sessions?.[0]?.zoom_url, '_blank');
                        }}
                      />
                    ) : (
                      <HDButton variant={'contained'} text={'Zoom link not yet available'} sx={zoomBtnStyle} disabled />
                    )}
                  </Box>
                  {sessionsData?.sessions?.[0]?.zoom_url ? (
                    <Box sx={divLinkStyle}>
                      <Link
                        onClick={() => {
                          reactGAEvents('coach-session-management', 'copy-session-link');
                          handleClick(sessionsData?.sessions?.[0]?.zoom_url);
                        }}
                        sx={copyLinkStyle}
                        tabIndex={0}
                        underline="none"
                      >
                        Copy session link
                      </Link>
                    </Box>
                  ) : (
                    ''
                  )}
                  {/* {sessionsData?.sessions?.[0]?.zoom_url ? (
                    <Box sx={divLinkStyle}>
                      {' '}
                      <Link
                        onClick={() => {
                          reactGAEvents('coach-session-management', 'request-new-time');
                        }}
                        sx={copyLinkStyle}
                        tabIndex={0}
                        underline='none'
                      >
                        Request new time
                      </Link>{' '}
                    </Box>
                  ) : (
                    ''
                  )} */}
                </LeftSection>
                <RightSection>
                  <Box sx={boxTitleStyle}>Discussion Topics</Box>
                  <Box>
                    {sessionsData?.sessions?.[0]?.action_items && sessionsData?.sessions?.[0]?.action_items.length > 0 ? (
                      sessionsData?.sessions?.[0]?.action_items.map((items: any, index: any) => {
                        return (
                          <Box key={index} sx={{ marginTop: '20px' }}>
                            <Typography sx={titleNote(themeMode)}>{items}</Typography>
                          </Box>
                        );
                      })
                    ) : (
                      <Box sx={discussionStyle}>Your coach has yet to add any information.</Box>
                    )}
                  </Box>
                </RightSection>
              </BodyWrapper>
              <Snackbar open={openToaster} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                  Session link copied to clipboard!
                </Alert>
              </Snackbar>
            </>
          </Box>
          {openViewProfile && (
            <FullScreenModal
              handleFSModal={() => {
                handleFSModal();
              }}
              openFSModal={openViewProfile}
              footer={null}
              modalRef={props.coachModalRef}
            >
              <ViewCoachProfile
                openViewProfile={openViewProfile}
                handleFSModal={() => {
                  handleFSModal();
                }}
                coachModalRef={coachModalRef}
                data={coachData}
                lastName={coachItem?.last_name}
                avatar={coachItem?.avatar_url}
                firstName={coachItem?.first_name}
              />
            </FullScreenModal>
          )}
        </Container>
      </Box>

      <Footer />
    </>
  );
}

export default ChemistrySessionDetails;
