import * as React from 'react';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import { FormControl, FormLabel, FormHelperText, styled, useTheme } from '@mui/material';

export interface HDMobileFieldProps {
  errorMsg: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal;
  error: boolean;
  label: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal;
  defaultCountry: string;
  value: any;
  onChange: any;
  type?: string;
  required?: boolean;
  inputProps?: any;
  id: any;
  helperText?: any;
  belowHelperTxt?: any;
  onClick?: any;
}

function HDMobileField(props: HDMobileFieldProps) {
  const theme = useTheme()?.palette.mode;

  const MuiPhoneStyle = {
    '& .MuiOutlinedInput-input': {
      fontFamily: 'Avenir',
      padding: '16.5px 14px',
    },
    '& .MuiPhoneNumber-flagButton svg': {
      width: '30px !important',
    },
  };

  const FormHelperTextStyled = styled(FormHelperText)(() => ({
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme === 'light' ? '#646A7C' : '#A9A9A9',
    marginLeft: 0,
    marginTop: '8px',
  }));

  const ErrorIcon = styled('img')(() => ({
    marginRight: '8px'
  }));

  return (
    <FormControl fullWidth={true}>
      {props?.label && <FormLabel htmlFor={props?.id} error={props?.error}>{props?.label}</FormLabel>}

      {props?.defaultCountry != '' && (
        <MuiPhoneNumber
          sx={MuiPhoneStyle}
          key={props?.defaultCountry?.toLowerCase()}
          name="phone"
          defaultCountry={props?.defaultCountry?.toLowerCase()}
          disableAreaCodes
          fullWidth
          error={props?.error}
          helperText={props?.helperText}
          id={props?.id}
          size="small"
          value={props?.value}
          variant="outlined"
          onChange={props?.onChange}
          countryCodeEditable={false}
          inputProps={props?.inputProps}
          onClick={props?.onClick}
        />
      )}
      {props?.error && <FormHelperTextStyled error={true}>
        <ErrorIcon src={(window.location.origin) + '/res/hd-ui-kit-images/' + ((theme === 'light') ? 'warning-icon.svg' : 'warning-icon-dark.svg')} />
        {props?.errorMsg}
      </FormHelperTextStyled>}
      {props?.belowHelperTxt && <FormHelperTextStyled>{props?.belowHelperTxt}</FormHelperTextStyled>}
    </FormControl>
  );
}

export default HDMobileField;
