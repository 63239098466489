import * as React from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  styled,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HDSlider from '../../hd-ui-kit/HDSlider';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router';
import { detailsBtnStyle, handleForward } from '../../commons/commonFunctions';

export interface SkillTypes {
  text: string;
  checked: boolean;
  progress: number,
  benchMark: number,
}

export interface HDExperienceCardTypes {
  id: number;
  title: string;
  skills: Array<SkillTypes>;
  isPDFCard?: boolean;
  forwardClick: () => void;
  pathname: any | null;
  state: any;
}

function HDExperienceCard({ id, title, skills, isPDFCard = false, forwardClick, pathname, state }: HDExperienceCardTypes) {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let navigate = useNavigate();

  const StyledCardBox = styled(Card)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background: isPDFCard ? 'transparent' : isDark ? '#002136' : '#f8f8f8',
    color: isDark ? '#ccc' : '#646a7c',
    width: '100%',
    maxWidth: isPDFCard ? '100%' : '1080px',
    boxShadow: 'none',
    borderRadius: '6px',
    padding: isPDFCard ? '4px 0' : isMobile ? '1.5rem' : '2rem',
    border: '1px solid ' + ((isDark) ? 'transparent' : '#DADCDF')
  }));

  const StyledCardContent = styled(CardContent)(() => ({
    width: '100%',
    padding: '0px',
    marginBottom: isMobile ? '14px' : '43.5px',
  }));

  const StyledCardContentLeft = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    //borderRight: isMobile ? 'none' : '1px solid rgb(100, 106, 124, 0.3)',
    borderBottom: isMobile ? '1px solid rgb(100, 106, 124, 0.3)' : 'none',
    //paddingBottom: isMobile ? 24 : 0,
  }));

  const StyledCardContentCenter = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    //paddingBottom: ((isMobile) ? 24 : 0),
    paddingLeft: ((isMobile) ? 0 : 32),
  }));

  const StyledCardTitle = styled(Typography)(() => ({
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '28.7px',
    color: isDark ? '#fff' : '#002136',
    marginBottom: isMobile ? 16 : 0,
  }));

  const StyledScoreDetail = styled(Box)(() => ({
    fontFamily: 'Avenir',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: '10px',
    color: isDark ? '#CCC' : '#334255',
  }));

  const StyledScoreDescription = styled(Typography)(({ theme }) => ({
    ...theme.typography.body1,
    marginTop: '2px',
  }));

  const StyledBottomLineWrapper = styled(Box)(() => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '4px',
    paddingTop: isMobile ? '20px' : '0px',
    paddingBottom: isMobile ? '10px' : '0px',
  }));

  const StyledBottomLine = styled('div')(() => ({
    width: isMobile ? '92%' : '87%',
    height: '1px',
    backgroundColor: isDark ? '#5C717E' : '#9FABB2',
    paddingRight: '15px'
  }));

  return (
    <StyledCardBox>
      <StyledCardContent>
        <Grid container spacing={0}>
          <Grid item sm={4} xs={12} sx={{ borderRight: (isPDFCard || isMobile) ? 'none' : '1px solid ' + ((isDark) ? '#334D5E' : '#C6CDD1') }}>
            <StyledCardContentLeft>
              <StyledCardTitle>{`${id}. ${title}`}</StyledCardTitle>
            </StyledCardContentLeft>
          </Grid>
          <Grid item sm={8} xs={12}>
            <StyledCardContentCenter>
              {skills?.map((skill, idx) => {
                return (
                  <Grid container spacing={0} key={`skill_${idx}`}>
                    <Grid item sm={6} xs={12}>
                      <StyledScoreDetail key={`score_${idx}`}>
                        <StyledScoreDescription>{skill.text}</StyledScoreDescription>
                      </StyledScoreDetail>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <HDSlider progress={skill.progress} benchMark={skill.benchMark} />
                    </Grid>
                  </Grid>
                );
              })}
            </StyledCardContentCenter>
          </Grid>

        </Grid>
      </StyledCardContent >
      {!isPDFCard && (
        <StyledBottomLineWrapper>
          <StyledBottomLine />
          <Link
            onClick={() => { handleForward('/experience/details', navigate, state); }}
            sx={detailsBtnStyle(theme.palette.mode)}
            tabIndex={0}
            underline="none"
          >
            View details
            <ArrowForwardIcon style={{ marginLeft: '5px', fontSize: '20px' }} />
          </Link>
        </StyledBottomLineWrapper>
      )}
    </StyledCardBox >
  );
}

export default HDExperienceCard;
