import React from 'react';
import { styled, Box, LinearProgress, linearProgressClasses, Typography, useTheme } from '@mui/material';

export interface HDProgressBarTypes {
  benchMark: number,
  progressDescription: string,
  progress: number;
  primaryColor?: string,
  aboveBMBarColor?: string,
  belowBMBarColor?: string,
  progressNote?: string,
  height?: number,
  titleStyle?: Object,
  noteStyle?: Object,
  progressLegend?: any,
  withoutTheme?: any
}

const HDProgressBar = (props: HDProgressBarTypes) => {
  const theme = useTheme();
  //  theme.platter.mode = 'dark'
  // const benchMark = props.benchMark;
  let primaryColor: any, barColor: any = '';
  const progressParts = props.progressDescription.split('~');
  const textColor = (theme.palette.mode === 'light' && !props.withoutTheme) ? '#334255' : '#CCCCCC';

  if (theme.palette.mode === 'light' && !props.withoutTheme) {
    primaryColor = (props.primaryColor) ? props.primaryColor : '#D6D9DB';
    barColor = (props.progress < props.benchMark) ? (props.belowBMBarColor) ? props.belowBMBarColor : '#BC7D01' : (props.aboveBMBarColor) ? props.aboveBMBarColor : '#18768C';
  } else {
    primaryColor = (props.primaryColor) ? props.primaryColor : '#244052';
    barColor = (props.progress < props.benchMark) ? (props.belowBMBarColor) ? props.belowBMBarColor : '#FEC350' : (props.aboveBMBarColor) ? props.aboveBMBarColor : '#2CDADA';
  }

  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: (props.height) ? props.height : 10,
    borderRadius: 100,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: primaryColor,
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: barColor,
    },
  }));

  const StyledTypoGraphy = styled(Typography)(() => ({
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    color: textColor,
    marginBottom: '16px',
  }));

  const PercentageSpan = styled(Box)(() => ({
    color: barColor
  }));

  const StyledNote = styled(Typography)(() => ({
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: 300,
    color: textColor,
  }));

  const StyledLegend = styled(Box)(() => ({

  }));

  const noteWrapStyle = {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'space-between',
  };

  return (
    <Box>
      <StyledTypoGraphy sx={props.titleStyle}>
        {progressParts.map((part, idx) => {
          return part.match(/%/) ? (
            <PercentageSpan
              key={`percentage_${idx}`}
              component='span'
            >
              {part}
            </PercentageSpan>
          ) : (
            part
          );
        })}
      </StyledTypoGraphy>
      <BorderLinearProgress variant='determinate' value={props.progress} />
      <Box sx={noteWrapStyle}>
        {props.progressNote &&
          <StyledNote sx={props.noteStyle}>{props.progressNote}</StyledNote>
        }

        {props.progressLegend &&
          <StyledLegend>{props.progressLegend}</StyledLegend>
        }
      </Box>
    </Box>
  );
};

export default HDProgressBar;