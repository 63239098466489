import { Box, Container, styled, Typography, useMediaQuery, useTheme, Divider } from '@mui/material';
import { CssBaseline } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import Loader from '../../commons/Loader';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import HDButton from '../../hd-ui-kit/HDButton';
import { ContainerStyle, contentWrapStyle, backImage, styledCardWrappers, titleStyle, fieldStyle, btnStyle, Stepper, dividerStyle, borderFormWrap, subTitleStyle } from '../auth/NewSignupStyles';
import HDTextField from '../../hd-ui-kit/HDTextField';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import HDDropDown from '../../hd-ui-kit/HDDropDown';
import formData from '../../utils/form-data';
import HDCheckBox from '../../hd-ui-kit/HDCheckBox/HDCheckBox';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import Api from '../../Api';
import HDMobileField from '../../hd-ui-kit/HDMobileField';
import { getTimeZoneOptions } from '../../commons/timezones';
import HDCard from '../../hd-ui-kit/HDCard';
import { validateFields, validationOptions } from '../../ValidationSchema';
import { reactGAEvents, backgroundTopRight, backgroundTopRightDark } from '../../commons/commonFunctions';
import { globalContext } from '../../store';

const PersonalDetails = () => {
  const theme = useTheme();
  const themeStyle = theme.palette.mode;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  let navigate = useNavigate();
  let context = useOutletContext<any>();
  const oauthExtraData = JSON.parse(sessionStorage.getItem('oauth_extra_data'));
  let oauthFirstName = oauthExtraData?.first_name?.localized?.en_US;
  let oauthLastName = oauthExtraData?.last_name?.localized?.en_US;
  const [data, setData] = useState(null);
  const [experienceData, setExperienceData] = useState(null);
  const [firstname, setFirstname] = useState(oauthFirstName || '');
  const [lastname, setLastname] = useState(oauthLastName || '');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('us');
  const [textOpt, setTextOpt] = useState(false);
  const [country, setCountry] = useState('');
  const [gender, setGender] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [lgbtq, setLgbtq] = useState('');
  const [diversity, setDiversity] = useState('');
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [timezone, setTimeZone] = useState({ value: '', label: '' });
  const [timezoneOptions, setTimeZoneOptions] = useState(getTimeZoneOptions('Other'));

  const [executivePlacement, setExecutivePlacement] = React.useState(false);
  const [executiveConsulting, setExecutiveConsulting] = React.useState(false);
  const [boardPlacemenet, setBoardPlacement] = React.useState(false);
  const [professionalDevelopment, setProfessionalDevelopment] = React.useState<boolean>(false);
  const [oppPrefData, setOppData] = useState(null);

  const { dispatch } = useContext(globalContext);

  React.useEffect(() => {
    if (context?.data && context?.data?.personal_details && context?.data?.personal_details?.first_name) {
      setData(context.data.personal_details);
      setTimeZone(context.data.timezone);
      setOppData(context?.data?.opportunity_preferences);
      if (context.data?.step_completed === 3) {
        navigate('/home');
      }
    }
    if (context?.data?.experience && context?.data?.experience?.current_location) {
      setExperienceData(context?.data?.experience);
    }
  }, [context?.data]);

  useEffect(() => {
    if (data) {
      setFormDataFromContext(data);
    } else {
      setCountryCode('us');
    }
    if (oppPrefData) {
      setFormDataFromContextOpp(oppPrefData);
    }
  }, [data, oppPrefData]);

  useEffect(() => {
    if (experienceData) {
      formData?.countryList?.forEach((i) => {
        if (experienceData?.current_location?.includes(i.keyValue) || (experienceData?.current_location?.includes('USA') && i.keyValue === 'United States of America')) {
          setCountry(i.keyValue);
          setTimeZone({ value: getTimeZoneOptions(i.keyValue)?.[0]?.value, label: getTimeZoneOptions(i.keyValue)?.[0]?.label });
          setTimeZoneOptions(getTimeZoneOptions(i.keyValue));
        }
      });
    }
  }, [experienceData]);

  useEffect(() => {
    if (country === 'United States of America') {
      return;
    } else if (country === 'United Kingdom') {
      return;
    } else if (country === 'Canada') {
      setLgbtq('');
    } else if (country === 'Australia') {
      setLgbtq('');
    } else if (country === '') {
      setLgbtq('');
      setDiversity('');
    } else {
      setLgbtq('');
    }
    return;
  }, [country]);

  // set form data function
  function setFormDataFromContext(data: { [key: string]: React.SetStateAction<string> }) {
    if (!data) {
      setCountryCode('us');
      return;
    }
    setFirstname(data['first_name']);
    setLastname(data['last_name']);
    setPhone(data['mobile_number']);
    setCountryCode((data['mobile_country_code']) ? data['mobile_country_code'] : 'us');
    setTextOpt(data['text_opt_in'] as unknown as boolean);
    setCountry(data['country']);
    setGender(data['gender']);
    setEthnicity(data['ethnicity']);
    setLgbtq(data['lgbtq']);
    setDiversity(data['diversity']);
    // setTimeZone(data)
    setExecutivePlacement(data['executive_placement'] as unknown as boolean);
    setExecutiveConsulting(data['executive_consulting'] as unknown as boolean);
    setBoardPlacement(data['board_placement'] as unknown as boolean);
    setProfessionalDevelopment(data['professional_development'] as unknown as boolean);
  }

  function setFormDataFromContextOpp(data: { [key: string]: React.SetStateAction<string> }) {
    setExecutivePlacement(data['executive_placement'] as unknown as boolean);
    setExecutiveConsulting(data['executive_consulting'] as unknown as boolean);
    setBoardPlacement(data['board_placement'] as unknown as boolean);
    setProfessionalDevelopment(data['professional_development'] as unknown as boolean);
  }

  function validate() {
    let dataIsValid = true;
    let errorObject: any = {};

    // phone validation
    try {
      const number = phoneUtil.parse(phone, countryCode);
      const numberIsValid = phoneUtil.isValidNumberForRegion(number, countryCode);
      if (!numberIsValid) {
        let countryRegion;
        switch (countryCode) {
          case 'us':
            countryRegion = 'U.S.';
            break;
          case 'gb':
            countryRegion = 'UK';
            break;
          default:
            countryRegion = countryCode?.toUpperCase();
            break;
        }
        errorObject['mobile_number'] = `Mobile number is not valid for ${countryRegion} region`;
        dataIsValid = false;
      }
    } catch (e) {
      dataIsValid = false;
      errorObject['mobile_number'] = 'The string supplied did not seem to be a phone number';
    }
    //setErrors(errorObject);
    return errorObject;
  }

  function saveObject() {
    //const isValid = validate();
    const firstNameMsg = validateFields.validate({ name: firstname }, validationOptions)?.error?.details[0]?.message;
    const lastNameMsg = validateFields.validate({ name: lastname }, validationOptions)?.error?.details[0]?.message;
    const mobileNumberMsg = validate();
    const countryMsg = validateFields.validate({ dropDown: country }, validationOptions)?.error?.details[0]?.message;
    const timeZoneMsg = validateFields.validate({ dropDown: timezone?.value ? timezone?.value : '' }, validationOptions)?.error?.details[0]?.message;
    const genderMsg = validateFields.validate({ dropDown: gender }, validationOptions)?.error?.details[0]?.message;

    if (firstNameMsg || lastNameMsg || mobileNumberMsg?.mobile_number || countryMsg || timeZoneMsg || genderMsg) {
      setErrors({
        first_name: firstNameMsg,
        last_name: lastNameMsg,
        mobile_number: mobileNumberMsg.mobile_number,
        country: countryMsg,
        timezone: timeZoneMsg,
        gender: genderMsg,
      });
      return;
    }
    setLoading(true);
    const defaultSelect = formData.declineSelectDefault;
    const payloadPhone = phone.replace(/\D/g, '');
    // consolidate payload
    const payload: any = {
      first_name: firstname,
      last_name: lastname,
      mobile_number: payloadPhone,
      mobile_country_code: countryCode,
      text_opt_in: textOpt,
      country: country,
      gender: gender || defaultSelect,
      ethnicity: ethnicity || defaultSelect,
      lgbtq: lgbtq || defaultSelect,
      diversity: diversity || defaultSelect,
      iana_timezone: timezone?.value,
      executive_placement: executivePlacement,
      executive_consulting: executiveConsulting,
      board_placement: boardPlacemenet,
      professional_development: professionalDevelopment,
    };
    if (data) {
      Api.updatePersonalDetail(payload, data['id'] as never)
        .then((res) => {
          onApiSuccess(res);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      // send data off to be saved
      Api.savePersonalDetail(payload)
        .then((res) => {
          onApiSuccess(res);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }

  const onApiSuccess = (res: any) => {
    if (res?.success) {
      // navigate to the next form
      reactGAEvents('Create Profile - Resume', 'Next');
      setLoading(false);
      getAccountDataApi();
      navigate('/home');
    } else {
      const { data } = res;
      setErrors(data);
      setLoading(false);
    }
  };

  const getAccountDataApi = async () => {
    await Api.getAccount()
      .then((res) => {
        let data = res.response?.data;
        dispatch({ type: 'SET_ACCOUNT', payload: data });
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const subHeading = 'What types of Heidrick career opportunities are you interested in?  This is optional.';
  const exePlacement = 'I\'m looking for a new executive role on a full-time, permanent basis.';
  const exeConsulting = 'I\'m interested in consulting or temporary assignments.';
  const boardPlacement = 'I\'m interested in joining a board of directors.';

  const professionalDevelopmentText = 'I want to develop my leadership capabilities and get guidance on improving my performance or advancing my career.';

  const ArrowForwardSharpIconStyled = styled(ArrowForwardSharpIcon)(() => ({
    color: themeStyle == 'light' ? '#66FCFC' : '#18768C',
  }));

  const cardStyle = {
    background: theme.palette.mode == 'light' ? '#f8f8f8' : '#002136',
    textAlign: 'left',
    border: '1px solid ' + (theme.palette.mode == 'light') ? '#DADCDF' : 'transparent',
    marginTop: '24px',
    boxShadow: 'none',
  };

  const CardSubTitleStyle = {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
    color: theme.palette.mode == 'light' ? '#334255' : '#FFFFFF',
    marginLeft: '30px',
  };

  return (
    <>
      <CssBaseline />
      {loading && <Loader />}
      <Header />
      <Box sx={themeStyle === 'light' ? { ...backgroundTopRight, display: 'grid' } : { ...backgroundTopRightDark, display: 'grid' }}>
        <Container maxWidth="lg" sx={ContainerStyle(isMobile, isTablet)}>
          <Box
            sx={{
              background: theme.palette.mode == 'light' ? (isMobile || isTablet ? 'url(../res/SignInBGLightMobile.svg)' : 'url(../res/SignInBGLight.svg)') : isMobile || isTablet ? 'url(../res/SignInBGDarkMobile.svg)' : 'url(../res/SignInBGDark.svg)',
              ...backImage(isMobile, isTablet),
              backgroundSize: isMobile || isTablet ? '115%' : '60%',
            }}
          ></Box>
          <Box sx={contentWrapStyle(isMobile, isTablet)}>
            {Stepper(2)}
            <Box sx={styledCardWrappers(isMobile, isTablet)}>
              <Typography sx={titleStyle(themeStyle)}>Finish up with some basic personal details.</Typography>
              <Box sx={borderFormWrap(themeStyle)}>
                <Typography sx={subTitleStyle(themeStyle)}>Career priorities</Typography>
                <Divider sx={dividerStyle(themeStyle)} />

                <Box sx={fieldStyle}>
                  <HDCard sx={cardStyle}>
                    <HDCheckBox
                      value={executivePlacement}
                      checked={executivePlacement}
                      onChange={({ target }) => {
                        reactGAEvents('profile-register-personal-details', 'opportunity-executive-placement');
                        setExecutivePlacement(target.checked);
                      }}
                      labelTxt={'Full-time opportunities'}
                      showBackground={false}
                      id='executive-placement'
                    />
                    <Typography sx={CardSubTitleStyle}>{exePlacement}</Typography>
                  </HDCard>

                  <HDCard sx={cardStyle}>
                    <HDCheckBox
                      value={executiveConsulting}
                      checked={executiveConsulting}
                      onChange={({ target }) => {
                        reactGAEvents('profile-register-personal-details', 'opportunity-executive-consulting');
                        setExecutiveConsulting(target.checked);
                      }}
                      labelTxt={'On-demand opportunities'}
                      showBackground={false}
                      id='executive-consulting'
                    />
                    <Typography sx={CardSubTitleStyle}>{exeConsulting}</Typography>
                  </HDCard>

                  <HDCard sx={cardStyle}>
                    <HDCheckBox
                      value={boardPlacemenet}
                      checked={boardPlacemenet}
                      onChange={({ target }) => {
                        reactGAEvents('profile-register-personal-details', 'opportunity-board-placement');
                        setBoardPlacement(target.checked);
                      }}
                      labelTxt={'Board opportunities'}
                      showBackground={false}
                      id='board-placement'
                    />
                    <Typography sx={CardSubTitleStyle}>{boardPlacement}</Typography>
                  </HDCard>

                  <HDCard sx={cardStyle}>
                    <HDCheckBox
                      value={professionalDevelopment}
                      checked={professionalDevelopment}
                      onChange={({ target }) => {
                        reactGAEvents('profile-register-personal-details', 'opportunity-development-coaching');
                        setProfessionalDevelopment(target.checked);
                      }}
                      labelTxt={'Professional Development and Coaching'}
                      showBackground={false}
                      id='professional-development'
                    />
                    <Typography sx={CardSubTitleStyle}>{professionalDevelopmentText}</Typography>
                  </HDCard>
                </Box>
              </Box>
              <Box sx={borderFormWrap(themeStyle)}>
                <Typography sx={subTitleStyle(themeStyle)}>Contact details</Typography>
                <Divider sx={dividerStyle(themeStyle)} />
                <Box sx={fieldStyle}>
                  <HDTextField
                    type="text"
                    lblText="First name"
                    placeholder="First name"
                    value={firstname}
                    onChange={({ target }: any) => setFirstname(target.value)}
                    error={errors?.first_name}
                    errorMsg={errors?.first_name ? errors.first_name : null}
                    onClick={() => reactGAEvents('profile-register-personal-details', 'first-name')}
                    id='first-name'
                  />
                </Box>
                <Box sx={fieldStyle}>
                  <HDTextField
                    type="text"
                    lblText="Last name"
                    placeholder="Last name"
                    value={lastname}
                    onChange={({ target }: any) => setLastname(target.value)}
                    error={errors?.last_name}
                    errorMsg={errors?.last_name ? errors.last_name : null}
                    onClick={() => reactGAEvents('profile-register-personal-details', 'last-name')}
                    id='last-name'
                  />
                </Box>
                <Box sx={fieldStyle}>
                  <HDDropDown
                    fieldLabel="Country of Residence"
                    defaultValue=""
                    value={country}
                    dropDownItem={formData.countryList}
                    fullWidth={true}
                    disabled={false}
                    error={errors?.country}
                    errorMsg={errors?.country ? errors.country : null}
                    onChange={({ target }: any) => {
                      setCountry(target.value);
                      setTimeZone({ value: '', label: '' });
                      setTimeZoneOptions(getTimeZoneOptions(target.value));
                      reactGAEvents('profile-register-personal-details', `country-residence-${target.value}`);
                    }}
                    id='country-of-residence'
                  />
                </Box>

                <Box sx={fieldStyle}>
                  <HDDropDown
                    fieldLabel="Time zone"
                    fullWidth={true}
                    disabled={false}
                    defaultValue=""
                    dropDownItem={[
                      { keyValue: 1, keyName: 'Select 1' },
                      { keyValue: 2, keyName: 'Select 2' },
                      { keyValue: 3, keyName: 'Select 3' },
                    ]}
                    isAutoComplete={true}
                    autoCompleteOptions={timezoneOptions}
                    value={timezone}
                    onChange={(value) => {
                      setTimeZone(value);
                      reactGAEvents('profile-register-personal-details', `time-zone-${value?.label}`);
                    }}
                    error={errors?.timezone}
                    errorMsg={errors?.timezone ? errors.timezone : null}
                    id='time-zone'
                  />
                </Box>
                <Box sx={fieldStyle}>
                  <HDMobileField
                    id={'telephone'}
                    label="Mobile Number"
                    type="telephone"
                    inputProps={{ style: { fontFamily: 'Avenir', padding: '16.5px 14px' } }}
                    value={phone}
                    defaultCountry={countryCode}
                    onChange={(number: React.SetStateAction<string>, context: { countryCode: React.SetStateAction<string> }) => {
                      reactGAEvents('profile-register-personal-details', 'mobile-number');
                      setPhone(number);
                      setCountryCode(context?.countryCode);
                    }}
                    required={true}
                    error={errors?.mobile_number}
                    errorMsg={errors?.mobile_number ? errors.mobile_number : null}
                  />
                </Box>

                <HDCheckBox
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontWeight: '400',
                    },
                  }}
                  disabled={false}
                  labelTxt={"It's ok to call or text me about Heidrick job openings."}
                  value={textOpt}
                  checked={textOpt}
                  onChange={({ target }) => {
                    setTextOpt(target.checked);
                    reactGAEvents('profile-register-personal-details', 'ok-to-contact-hs-job-openings');
                  }}
                  id='call-job-openings'
                />
              </Box>

              <Box sx={borderFormWrap(themeStyle)}>
                <Typography sx={subTitleStyle(themeStyle)}>Additional information</Typography>
                <Divider sx={dividerStyle(themeStyle)} />
                <Box sx={fieldStyle}>
                  <HDDropDown
                    fieldLabel={'Gender'}
                    value={(gender === null) ? '' : gender}
                    dropDownItem={formData.genderMenuNew}
                    fullWidth={true}
                    disabled={false}
                    defaultValue=""
                    onChange={({ target }) => {
                      reactGAEvents('profile-register-personal-details', `gender-${target.value}`);
                      setGender(target.value);
                    }}
                    error={errors?.gender}
                    errorMsg={errors?.gender ? errors.gender : null}
                    id='gender'
                  />
                </Box>
                <Box sx={fieldStyle}>
                  <HDDropDown
                    fieldLabel={'Ethnicity (optional)'}
                    dropDownItem={formData.ethnicityMenuNew(country)}
                    fullWidth={true}
                    value={(ethnicity === null) ? '' : ethnicity}
                    onChange={({ target }: any) => {
                      setEthnicity(target.value);
                      reactGAEvents('profile-register-personal-details', `ethnicity-${target.value}`);
                    }}
                    id='ethnicity'
                  />
                </Box>

                <Box sx={fieldStyle}>
                  <HDDropDown
                    fieldLabel={'LGBTQ (optional)'}
                    dropDownItem={formData.lgbtqMenuNew(country)}
                    fullWidth={true}
                    disabled={formData?.lgbtqMenuNew(country) && formData?.lgbtqMenuNew(country)?.length == 0}
                    defaultValue=""
                    value={lgbtq}
                    onChange={({ target }) => {
                      setLgbtq(target.value);
                      reactGAEvents('profile-register-personal-details', `lgbtq-${target.value}`);
                    }}
                    error={errors?.lgbtq}
                    id='lgbtq'
                  />
                </Box>

                <Box sx={fieldStyle}>
                  <HDDropDown
                    fieldLabel={'Diversity, Non-Specific (optional)'}
                    showToolTip={true}
                    toolTipText={'Refers to individuals who identify as diverse but either do not fall into one of the above categories (could include but is not limited to veteran status or disability), or do not wish to share further information with the firm as to how they are diverse.'}
                    value={(diversity === null) ? '' : diversity}
                    onChange={({ target }: any) => {
                      setDiversity(target.value);
                      reactGAEvents('profile-register-personal-details', `diversity-${target.value}`);
                    }}
                    error={errors?.diversity}
                    dropDownItem={formData.diversityMenuNew(country)}
                    fullWidth={true}
                    disabled={formData?.diversityMenuNew(country) && formData?.diversityMenuNew(country)?.length == 0}
                    defaultValue=""
                    id='diversity'
                  />
                </Box>
              </Box>

              <HDButton
                variant={'contained'}
                endIcon={<ArrowForwardSharpIconStyled />}
                text={'Next'}
                onClick={() => {
                  saveObject();
                  reactGAEvents('profile-register-personal-details', 'next');
                }}
                sx={btnStyle(themeStyle)}
                showAnimation={true}
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default PersonalDetails;
