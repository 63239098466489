import React, { useEffect, useState, useRef, useContext } from 'react';
import { Box, CssBaseline, Typography, styled, Container, Divider, useMediaQuery, useTheme, Link, StepIcon, StepConnector } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import HDCard from '../../hd-ui-kit/HDCard';
import HDButton from '../../hd-ui-kit/HDButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Api from './../../Api';
import { useNavigate } from 'react-router';
import { reactGAEvents } from '../../commons/commonFunctions';
import Loader from '../../commons/Loader';
import { globalContext } from '../../store';
import ReportCard from '../../pages/ReportCard';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useLocation } from 'react-router-dom';

function NewHomePage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const secondSection = useRef(null);
  const assessmentSection = useRef(null);
  const gridRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const [showMaintenanceModal, setShowMaintenanceModal] = useState<boolean>(false);
  const [profileStatus, setProfileStatus] = useState<any>({});
  const { dispatch, globalState } = useContext(globalContext);
  const [fullname, setFullname] = useState('');
  const [currentRolePage, setCurrentRolePage] = useState<string>('');
  const [statesPage, setAssessmentStatesPage] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [isStatusUpdated, setIsStatusUpdated] = useState<boolean>(false);
  const [coachCardTitle, setCoachCardTitle] = useState<string>('Explore how our executive coaching program can help you achieve your career goals');
  const [coachCardBtnTxt, setCoachCardBtnTxt] = useState<string>('Schedule your free intro call');
  const [coachCardBtnAction, setCoachCardBtnAction] = useState<string>('/my-coach');
  const [showAnimation, setShowAnimation] = useState<any>({});
  const location = useLocation();
  const originHost = window.location.origin;

  const styledTitle = {
    color: '#FFFFFF',
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: isMobile ? '31px' : '41px',
    lineHeight: isMobile ? '41px' : '54px',
    textAlign: 'start',
  };

  const styledParagraph = {
    color: '#E7E7E7',
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'start',
  };

  const bannerWrapper = {
    background: (isMobile) ? 'url(' + originHost + '/res/coachBannerBG.svg) no-repeat' : 'url(' + originHost + '/res/newhomeHero.png) no-repeat',
    display: 'flex',
    backgroundSize: 'cover',
    backgroundPosition:'center',
  };

  const bannerTxtStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    margin: (isMobile || isTablet) ? ((!isMobile && isTablet) ? '420px 0 60px 0' : '250px 0 60px 0') : '90px 0',
    width: isMobile || isTablet ? '100%' : '50%',
  };

  const bannerImgStyle = {
    width: '100%',
    backgroundImage: (isTablet) ? 'url(' + originHost + '/res/homeHeroMob.png)' : '',
    backgroundRepeat: 'no-repeat',
    backgroundSize: isMobile || isTablet ? 'contain' : '54%',
    backgroundPosition: isMobile || isTablet ? 'top' : 'bottom right',
  };

  const bannerBelowTitle = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: isMobile ? '41px' : '64px',
    lineHeight: isMobile ? '54px' : '64px',
    textAlign: 'start',
    color: '#002136',
  };

  const paragraphText = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'start',
    color: '#334255',
    marginTop: isMobile ? '20px' : '0',
  };

  const CardsTitle = styled(Typography)(() => ({
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: isMobile ? '31px' : '41px',
    lineHeight: isMobile ? '41px' : '54px',
    textAlign: 'start',
    color: '#002136',
  }));

  const bannerBelowWrapper = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    textAlign: 'left',
    padding: isMobile ? '20px 0' : '45px 0',
  };

  const cardsWrapper = {
    padding: isMobile ? '20px 0' : '45px 0',
  };

  const startedCardStyle = {
    height: '100%',
    background: '#f8f8f8',
    border: '1px solid #DADCDF',
    boxShadow: 'none',
    textAlign: 'left',
    borderRadius: '9px',
    padding: '32px',
    backgroundImage: isMobile ? '' : 'url(' + originHost + '/res/resumeBanner.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundPositionX: '90%',
  };

  const bannerBelowTxt = { width: isMobile ? '100%' : '50%' };

  const styledMainWrapper = {
    background: 'radial-gradient(100% 200% at 6.02% 0%, #E3ECEF 0%, rgba(241, 241, 241, 0) 40%) #FFF',
  };

  const btnStyle = {
    background: '#F1F1F1',
    color: '#002136',
    fontFamily: 'Aventa',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '22px',
    marginRight: '9px',
    whiteSpace: isMobile ? 'none' : 'nowrap',
    textWrap: 'balance',
    '&:hover': {
      background: '#F1F1F1',
    },
    '&:focus': {
      border: '1px solid #F1F1F1 !important;',
      background: '#F1F1F1 !important;',
    },
    '&::before': {
      background: '#F1F1F1 !important;',
      color: '#002136',
    },
  };

  const ArrowIcon = styled(ArrowForwardIcon)(() => ({
    color: '#18768C',
  }));

  const ArrowDownIcon = styled(ArrowDownwardIcon)(() => ({
    color: '#18768C',
  }));

  const textNoteBtn = {
    padding: 0,
    textDecoration: 'underline',
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: isMobile ? '28px' : '40px',
    cursor: 'pointer',
    color: '#18768C',
    marginLeft: isMobile ? '0px' : '6px',
  };

  const textNoteBtnUpdate = {
    padding: 0,
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: isMobile ? '28px' : '40px',
    color: '#18768C',
    marginLeft: isMobile ? '0px' : '6px',
    textDecoration: 'none',
    display: 'flex',
    cursor: 'default',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'flex-start'
  };

  const textNoteBtnBottom = {
    padding: 0,
    textDecoration: 'underline',
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: isMobile ? '28px' : '40px',
    cursor: 'pointer',
    color: '#18768C',
    marginLeft: '6px',
  };

  const exploreTextStyle = {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: isMobile ? '28px' : '31px',
    color: '#FFFFFF',
    marginBottom: '24px',
  };

  const basicStyle = {
    fontWeight: 600,
    fontSize: '15px',
    fontStyle: 'normal',
    lineHeight: '22px',
    marginTop: isMobile ? '16px' : '0',
    textWrap: 'nowrap',
    backgroundColor: '#334255',
    color: '#fff',
    '&:hover': {
      background: '#334255',
    },
    '&:focus': {
      border: '1px solid #334255 !important;',
      background: '#334255 !important;',
    },
    '&::before': {
      background: '#334255 !important;',
      color: '#fff',
    },
  };

  const resumeTextStyle = {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '22px',
    fontStyle: 'normal',
    lineHeight: '31px',
    color: '#0B0C10',
  };

  const resmSmallTextStyle = {
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '24px',
    color: '#334255',
  };

  const textLearnMore = {
    padding: 0,
    textDecoration: 'underline',
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: 'normal',
    cursor: 'pointer',
    color: '#18768C',
    letterSpacing: '0.4px',
    marginTop: isMobile ? '5px' : '0px',
    marginLeft: '28px'
  };

  const leanMoreParent = { display: 'flex', textAlign: (isMobile || isTablet) ? 'left' : 'none' };

  const scoreText = { marginLeft: '5px', color: '#334255', fontFamily: 'Aventa', fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', textAlign: isMobile ? 'left' : 'none' };

  useEffect(() => {
    if (globalState?.profile) {
      const dataResult = globalState?.profile;
      setCurrentRolePage(dataResult?.businessRole?.name ?? '');
      if (dataResult?.assessments?.length > 0) {
        const assessments: any = dataResult?.assessments;
        let assessmentArr: any = [];
        if (assessments) {
          assessments.forEach((data: any) => {
            assessmentArr[data.name] = { ...data };
          });
        }
        setAssessmentStatesPage(assessmentArr);
      }
    }
    //setIsStatusUpdated(true);
  }, [globalState?.profile]);

  const setRoleName = (roleName: string) => {
    setCurrentRolePage(roleName);
  };

  const getProfileStatus = async () => {
    const res = await Api.getProfileStatus();
    if (res.success) {
      const dummyData = res.response.data;
      setFullname(`${dummyData?.['calling-card']?.first_name}`);
      if (res?.response?.data?.opportunity_preferences?.opportunity_pref) {
        dummyData['opportunity_preferences_data'] = [];

        if (res?.response?.data?.opportunity_preferences?.opportunity_pref?.executive_placement) {
          dummyData['opportunity_preferences_data'].push({
            opportunity_preferences_title: 'Full-time opportunities',
            opportunity_preferences_Desc: 'I\'m looking for a new executive role on a full-time, permanent basis.',
          });
        }
        if (res?.response?.data?.opportunity_preferences?.opportunity_pref?.executive_consulting) {
          dummyData['opportunity_preferences_data'].push({
            opportunity_preferences_title: 'On-demand opportunities',
            opportunity_preferences_Desc: 'I\'m interested in consulting or temporary assignments.',
          });
        }
        if (res?.response?.data?.opportunity_preferences?.opportunity_pref?.board_placement) {
          dummyData['opportunity_preferences_data'].push({
            opportunity_preferences_title: 'Board opportunities',
            opportunity_preferences_Desc: 'I\'m interested in joining a board of directors.',
          });
        }
        if (res?.response?.data?.opportunity_preferences?.opportunity_pref?.professional_development) {
          dummyData['opportunity_preferences_data'].push({
            opportunity_preferences_title: 'Professional Development and Coaching',
            opportunity_preferences_Desc: 'I want to develop my leadership capabilities and get guidance on improving my performance or advancing my career.',
          });
        }
      }

      setProfileStatus(dummyData);
    }
  };

  useEffect(() => {
    getProfileStatus();
  }, []);

  useEffect(() => {
    if (!loading && location?.state?.openrolemodal !== undefined && location?.state?.openrolemodal === true) {
      if (globalState?.profile?.businessRole?.name === undefined) {
        if ((process.env.REACT_APP_ASSESSMENT_MAINTENANCE === 'false') || process.env.REACT_APP_SPECIAL_ACCESS?.includes(globalState?.account?.email)) {
          setOpen(true);
        } else {
          setShowMaintenanceModal(true);
        }
      } else {
        scrollToView();
      }
    }
  }, [loading]);

  const getCoachCardDetails = async () => {
    let res: any;
    if (globalState?.sessionsData?.response?.data) {
      res = globalState.sessionsData;
    } else {
      res = await Api.getCoachSessionApi();
      dispatch({ type: 'SET_SESSIONS_DATA', payload: res });
    }
    if (res?.success) {
      const sessionsListLocal = res?.response?.data?.sessions;
      if (sessionsListLocal && Object.keys(sessionsListLocal)?.length > 0) {
        const bookedSessionCount = sessionsListLocal.filter((item: any) => item.start_time !== null);
        const sessionsLength = bookedSessionCount?.length;
        const subscriptionStatus = globalState?.subscriptionStatus?.status;
        const isFutureSessionScheduled = new Date(bookedSessionCount?.[bookedSessionCount.length - 1]?.['start_date_hfl_specific']?.replace(/-/g, '/')).getTime() > new Date().getTime();
        const isChemistryCompleted = new Date(sessionsListLocal?.[0]?.['start_date_hfl_specific']?.replace(/-/g, '/')).getTime() < new Date().getTime();
        if (sessionsListLocal?.[0]?.['is_completed'] || (isChemistryCompleted && subscriptionStatus === 'NOT_SIGNED_UP')) {
          setCoachCardTitle('Subscribe to start working with your coach');
          setCoachCardBtnTxt('Go to coaching');
          setCoachCardBtnAction('/my-coach');
        }
        //not subscribed and has scheduled the chemistry session
        if (sessionsLength === 1 && subscriptionStatus === 'NOT_SIGNED_UP' && isChemistryCompleted === false) {
          setCoachCardTitle('You have an upcoming introductory session');
          setCoachCardBtnTxt('Go to coaching');
          setCoachCardBtnAction('/my-coach/dashboard');
        }
        //subscribed and has no scheduled future session
        if (sessionsLength >= 1 && subscriptionStatus === 'ACTIVE' && isFutureSessionScheduled === false) {
          setCoachCardTitle('It\'s time for you to schedule your next coaching session');
          setCoachCardBtnTxt('Go to coaching');
          setCoachCardBtnAction('/my-coach/dashboard');
        }
        //subscribed and has a future scheduled session
        if (sessionsLength > 1 && isFutureSessionScheduled) {
          setCoachCardTitle('You have an upcoming coaching session');
          setCoachCardBtnTxt('Go to coaching');
          setCoachCardBtnAction('/my-coach/dashboard');
        }
        // no longer subscribed to coaching
        if (subscriptionStatus === 'NOT_ACTIVE') {
          setCoachCardTitle('Your coaching subscription is no longer active');
          setCoachCardBtnTxt('Go to coaching');
          setCoachCardBtnAction('/profile-edit?active=6');
        }
      }
    }
  };

  useEffect(() => {
    // if (statesPage?.Impact?.status === 'C' && statesPage?.Capabilities?.status === 'C') {
    getCoachCardDetails();
    // }
  }, [statesPage, globalState?.subscriptionStatus]);

  const setApiErrorHandler = () => {
    setTimeout(() => {
      setIsStatusUpdated(true);
    }, 4000);
    console.log('error handler called');
  };

  const scrollBelow = () => {
    secondSection?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });


  };

  const setLoader = (status: boolean) => {
    setLoading(status);
  };

  function convertToDate(str: any) {
    if (str) {
      let date = new Date(Date.parse(str));
      return `${date.toLocaleString('default', { dateStyle: 'short' })} `;
    }
  }

  const scrollToView = () => {
    assessmentSection?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });



    setShowAnimation({
      animation: 'zoom-in-zoom-out 2s ease',
      animationIterationCount: 3,
    });

    reactGAEvents('my-reports', 'banner-take-assessments');
    setTimeout(() => {
      setShowAnimation({});
    }, 2000);
  };

  const stepIconStyle = {
    border: '2px solid #197E95',
    borderRadius: '50%',
    fill: 'transparent',
  };

  const CustomStepConnector = styled(StepConnector)(() => ({
    '& .MuiStepConnector-line': {
      //minHeight: '131px',
      borderLeft: '1px dashed #197E95', // Add a border to simulate the line
    },
    '& .MuiStepConnector-icon': {
      marginLeft: '-12px', // Adjust this value as needed to control the spacing
    },
  }));

  const stepperStyle = { borderLeft: '1px dashed #197E95' };
  const stepperWrapperStyle = { position: 'relative', top: '-34px', marginLeft: '24px' };
  const resumeContainer = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: isMobile ? 'column' : '',
  };

  const resumeTxtContainer = { display: 'flex', flexDirection: isMobile ? 'column' : '' };
  const resumeNote = { marginLeft: isMobile ? '0px' : '18px', marginTop: isMobile ? '10px' : '0px' };
  const linkedinWrap = { display: 'flex', paddingTop: '15px', textAlign: 'justify', alignItems: isMobile ? 'start' : 'none' };
  const stepperIcon = { display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '30px', fill: '#197E95' };

  const coachWrapper = {
    display: 'flex',
    textAlign: 'left',
    borderRadius: '6px',
    backgroundColor: '#197E95',
    flexFlow: (isTablet) ? 'column-reverse' : 'unset'
  };

  const coachTextWrap = {
    width: (isTablet) ? '100%' : '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '48px'
  };

  const coachImgWrap = {
    display: 'flex',
    width: (isTablet) ? '100%' : '50%',
  };

  const openRoleModal = () => {
    if (process.env.REACT_APP_ASSESSMENT_MAINTENANCE === 'false' || process.env.REACT_APP_SPECIAL_ACCESS?.includes(globalState?.account?.email)) {
      if (globalState?.profile?.businessRole?.name == undefined) {
        setOpen(true);
        setLoader(true);
      }
    } else {
      setShowMaintenanceModal(true);
    }
  };

  const textNoteWrap = {
    width: '100%',
    fontFamily: 'Avenir',
    fontSize: '15px',
    //marginTop: '30px',
    paddingBottom: isMobile ? '32px' : '64px',
    textAlign: 'left',
  };

  const textNoteTypoGraphy = {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    color: '#334255'
  };

  const roleBtnStyle = {
    backgroundColor: '#F8F8F8',
    color: '#0B0C10',
    borderColor: '#646A7C',
    textWrap: 'nowrap',
    '&:hover': {
      background: '#F8F8F8',
      borderColor: '#646A7C',
    },
    '&:focus': {
      border: '1px solid #646A7C !important;',
      background: '#F8F8F8 !important;',
      color: '#0B0C10 !important;',
    },
    '&::before': {
      background: '#F8F8F8 !important;',
      color: '#0B0C10 !important;',
    },
    '&:focus:before': {
      background: '#F8F8F8 !important;',
      color: '#0B0C10 !important;',
      zIndex: '-1 !important',
    }
  };

  const navigateToLinkedIn = () => {
    navigate('/profile-edit?active=1', {
      state: { scrollToLinkedIn: true },
    });
  };

  return (
    <>
      {loading && <Loader showNote={true} />}
      <CssBaseline />
      <Header />
      <Box>
        <Box sx={bannerWrapper}>
          <Box sx={bannerImgStyle}>
            <Container maxWidth="lg">
              <Box sx={bannerTxtStyle}>
                <Typography sx={styledTitle} variant="h1">
                  Welcome to Heidrick for Leaders, {fullname}
                </Typography>
                <Typography sx={styledParagraph} variant="h5">
                  Enjoy exclusive access to our powerful combination of leadership insights and world-class executive coaches.
                </Typography>
                <HDButton
                  text={'Explore to learn more'}
                  variant={'contained'}
                  size={'medium'}
                  sx={{ ...btnStyle, marginTop: '20px', width: 'fit-content' }}
                  endIcon={<ArrowDownIcon />}
                  onClick={() => scrollBelow()}
                // disabled={(showMessage && messageType === 'err') ? true : false}
                />
              </Box>
            </Container>
          </Box>
        </Box>

        <Box sx={styledMainWrapper} ref={secondSection}>
          <Container maxWidth="lg">
            <Box sx={bannerBelowWrapper}>
              <Box sx={bannerBelowTxt}>
                <Box sx={bannerBelowTitle}>Take the next step in your career</Box>
              </Box>
              <Box sx={bannerBelowTxt}>
                <Box sx={paragraphText}>
                  Through decades of experience serving the world's top organizations, we know what makes leaders successful — and we have created Heidrick for Leaders to share our insights and help you take action.
                  <br></br>
                  <br></br>
                  Share your experience with us, assess your strengths and areas for growth, and receive individual guidance to elevate your career.
                </Box>
              </Box>
            </Box>
            <Divider sx={{ borderColor: 'rgba(100, 106, 124, 0.2)' }} />

            <Stepper activeStep={0} sx={cardsWrapper} orientation="vertical" connector={<CustomStepConnector />}>
              <Step key={1} expanded>
                <StepIcon icon={profileStatus?.resume?.uploaded ? <CheckCircleIcon sx={stepperIcon} /> : ''} sx={stepIconStyle} />
                <StepContent sx={stepperStyle}>
                  <Box sx={stepperWrapperStyle}>
                    <CardsTitle variant="h2">Share your Experience </CardsTitle>
                    <Box sx={{ ...paragraphText, paddingBottom: '30px' }}>Keep your resume up-to-date and visible to our Heidrick executive search team. If you are a good fit for an opportunity, they will reach out to you directly.</Box>

                    <HDCard sx={startedCardStyle}>
                      <Box sx={resumeContainer}>
                        <Box sx={resumeTxtContainer}>
                          <Box>
                            <img alt="Add resume logo" src={originHost + '/res/addResume.svg'} />
                          </Box>
                          <Box sx={resumeNote}>
                            <Box sx={resumeTextStyle}>{!profileStatus?.resume?.uploaded ? 'Add your resume' : 'We have your resume on file'}</Box>
                            <Box sx={resmSmallTextStyle}>{profileStatus?.resume?.uploaded ? `The file ${globalState?.account?.resume?.document_name} was uploaded on ${convertToDate(profileStatus?.resume?.last_uploaded_date)}` : 'We accept PDF (Preferred), DOCX, DOC, RTF, TXT up to 2MB'}</Box>
                          </Box>
                        </Box>

                        <Box sx={{ display: 'flow', alignContent: 'center' }}>
                          <HDButton
                            endIcon={profileStatus?.resume?.uploaded ? <></> : <ArrowForwardIcon sx={{ color: '#66FCFC' }} />}
                            onClick={() => {
                              const action = profileStatus?.resume?.uploaded ? 'view-resume' : 'add-resume';
                              reactGAEvents('my-career', action);
                              navigate('/profile-edit?active=5');
                            }}
                            text={profileStatus?.resume?.uploaded ? 'Go to resume' : 'Add resume'}
                            sx={profileStatus?.resume?.uploaded ? roleBtnStyle : basicStyle}
                            showAnimation={true}
                            variant={profileStatus?.resume?.uploaded ? 'outlined' : 'contained'}
                          />
                        </Box>
                      </Box>
                    </HDCard>

                    <Box sx={linkedinWrap}>
                      {globalState?.account?.personal_details?.linkedin_url != '' ? (
                        <Box sx={textNoteBtnUpdate}>
                          <Box sx={{ display: 'flex', alignItems: isMobile ? 'flex-start' : 'center' }}>
                            <InfoOutlinedIcon sx={{ marginRight: '8px' }} />
                            We have your LinkedIn profile URL.
                          </Box>
                          <Link onClick={navigateToLinkedIn} sx={{ ...textNoteBtn, marginLeft: isMobile ? '32px' : '6px' }} tabIndex={0}>
                            Update the link
                          </Link>
                        </Box>
                      ) : (
                        <>
                          <img alt="" src={originHost + '/res/linkLogo.svg'} />
                          <Link onClick={navigateToLinkedIn} sx={textNoteBtn} tabIndex={0}>
                            Add a link to your LinkedIn profile
                          </Link>
                        </>
                      )}
                    </Box>
                  </Box>
                </StepContent>
              </Step>

              <Step key={2} expanded ref={assessmentSection}>
                <StepIcon icon={statesPage?.Impact?.status === 'C' && statesPage?.Capabilities?.status === 'C' ? <CheckCircleIcon sx={stepperIcon} /> : ''} sx={stepIconStyle} />
                <StepContent sx={stepperStyle}>
                  <Box sx={stepperWrapperStyle}>
                    <CardsTitle variant="h2">Benchmark your Profile </CardsTitle>
                    <Box sx={{ ...paragraphText, paddingBottom: '30px' }}>Measure yourself against thousands of successful leaders around the world. Our proprietary assessments are based on over 70 years of experience studying the characteristics of the most accomplished leaders.</Box>
                    <HDCard sx={{ ...startedCardStyle, backgroundImage: isMobile ? '' : 'url(' + originHost + '/res/roleBg.svg)', backgroundPositionX: '84%' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: isMobile ? 'column' : '',
                        }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : '' }}>
                          <Box>
                            <img alt="role logo" src={originHost + '/res/roleImg.svg'} />
                          </Box>
                          <Box sx={{ marginLeft: isMobile ? '0px' : '18px', marginTop: isMobile ? '10px' : '0px', marginRight: '10px' }}>
                            <Box sx={resumeTextStyle}>{currentRolePage ? `Your target role is ${currentRolePage}` : 'Select your target role'}</Box>
                            <Box sx={resmSmallTextStyle}>{currentRolePage ? `How do your experience and skills compare to ${currentRolePage} targets?` : "We'll compare your business impact and leadership capabilities to targets for this role."}</Box>
                          </Box>
                        </Box>
                        {!currentRolePage ? (
                          <Box sx={{ alignContent: 'center', marginTop: isMobile ? '10px' : 'none' }}>
                            <HDButton text="Select role" variant="outlined" onClick={() => openRoleModal()} sx={roleBtnStyle} />
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </HDCard>

                    <Box sx={{ ...showAnimation, paddingTop: '30px' }}>
                      <ReportCard
                        setLoaderHandler={setLoader}
                        setApiErrorHandler={setApiErrorHandler}
                        gridRef={gridRef}
                        pageType={'MyReports'}
                        open={open}
                        setOpen={setOpen}
                        showMaintenanceModal={showMaintenanceModal}
                        setShowMaintenanceModal={setShowMaintenanceModal}
                        setRoleName={setRoleName}
                      />
                    </Box>
                    <Box>
                      <Box sx={leanMoreParent}>
                        <Box>
                          <img src={window.location.origin + '/res/ScoreLogo.svg'} alt="scores mean" />
                        </Box>
                        <Box sx={scoreText}>Want to understand what those scores mean?</Box>
                      </Box>

                      <Box sx={leanMoreParent}>
                        <Link sx={textLearnMore} underline="always" href={`${originHost}/res/how_to_interpret_your_assessment_results.pdf`} target="_blank">
                          {' '}
                          Learn more about how to interpret your assessments results
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </StepContent>
              </Step>

              <Step key={3} expanded>
                <StepIcon icon={globalState?.subscriptionStatus?.status == 'ACTIVE' ? <CheckCircleIcon sx={stepperIcon} /> : ''} sx={stepIconStyle} />
                <StepContent>
                  <Box sx={stepperWrapperStyle}>
                    <CardsTitle variant="h2">Partner with an Executive Coach</CardsTitle>
                    <Box sx={{ ...paragraphText, marginBottom: '35px', marginTop: '10px' }}>Meet with a certified executive coach for several months. Discover strategies to reach your goals, navigate challenges and accelerate your professional growth.</Box>

                    <Box sx={coachWrapper}>
                      <Box sx={coachTextWrap}>
                        <Box sx={exploreTextStyle}>{coachCardTitle}</Box>
                        <Box>
                          <HDButton showAnimation={true} text={coachCardBtnTxt} variant={'contained'} size={'medium'} sx={{ ...btnStyle, textAlign: 'left', textWrap: 'unset' }} endIcon={<ArrowIcon />} onClick={() => navigate(coachCardBtnAction)} />
                        </Box>
                      </Box>

                      <Box sx={coachImgWrap}>
                        <img alt="" src={originHost + '/res/coachImg.png'} style={{ borderRadius: '6px', width: '100%' }} />
                      </Box>
                    </Box>
                  </Box>
                </StepContent>
              </Step>
            </Stepper>

            <Box sx={textNoteWrap}>
              <Typography sx={textNoteTypoGraphy}>
                To increase visibility across executive search firms, we recommend you submit your resume to BlueSteps, the executive career service of the Association of Executive Search and Leadership Consultants (AESC).
                <Link onClick={() => window.open('https://www.bluesteps.com/partner/heidrick', '_blank')} sx={textNoteBtnBottom} tabIndex={0}>
                  Learn more at BlueSteps.
                </Link>
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default NewHomePage;
