import './ConfirmationModal.css';
import '../../stylesheets/global.css';

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { confirmationModalTypes } from './types';
import HDModal from '../../hd-ui-kit/HDModal';
import HDButton from '../../hd-ui-kit/HDButton';

function ConfirmationModal(props: confirmationModalTypes) {
  // Close modal
  const onClose = () => {
    // handle close
    props.onClose();
  };

  // Yes Selected
  const onYes = () => {
    // handle yes
    props.onYes();
  };

  // No Selected
  const onNo = () => {
    // handle no
    props.onNo();
  };

  const modalStyle = {
    '& .MuiDialog-paper': {
      //padding: '0% 15% 0 0%',
      minWidth: '40%'
    },
  };

  return (
    <HDModal
      sx={modalStyle}
      open={true}
      handleModal={onClose}
      title={props.header}
      description={props.description}
      actions={
        <>
          <HDButton text={props.yesBtnText ? props.yesBtnText : 'Yes'} variant="contained" onClick={onYes} />
          <HDButton text={props.noBtnText ? props.noBtnText : 'No'} sx={props.noBtnSx} variant="outlined" onClick={onNo} />
        </>
      }
    />
  );
}

export default ConfirmationModal;
