import React, { useState, useEffect } from 'react';
import { Box, styled, Typography, useTheme } from '@mui/material';
import HDCoachTabs from '../../hd-ui-kit/HDCoachTabs/HDCoachTabs';
import CoachBio from './CoachBio';
import HDSpinner from '../../hd-ui-kit/HDSpinner';
import { reactGAEvents } from '../../commons/commonFunctions';

export interface SelectCoachProps {
  allCoachData?: any;
  tabsDataCoach?: any;
  loading?: any;
  loadingMsg?: string;
  errorMsg?: any;
}

const SelectCoach = (props: SelectCoachProps) => {
  const isDarkTheme = useTheme().palette.mode === 'dark';

  const StyledLoadingText = styled(Typography)((theme) => ({
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '26px',
    marginLeft: '10px',
    color: isDarkTheme ? '#CCCCCC' : '#002136',
  }));

  const StyledLoadingBox = { display: 'flex', marginTop: '25px' };
  const StyledSpinnerBox = { marginTop: '5px' };

  const errorMsgStyle = {
    color: '#cc33a1',
    fontFamily: 'Avenir',
    fontSize: '16px',
    marginBottom: '20px'
  };

  return (
    <>
      {props.loading ? (
        <Box sx={StyledLoadingBox}>
          <Box sx={StyledSpinnerBox}>
            <HDSpinner size={'la-sm'} />
          </Box>

          <StyledLoadingText>{props.loadingMsg}</StyledLoadingText>
        </Box>
      ) : (
        <>
          <Box sx={errorMsgStyle}>{props?.errorMsg}</Box>
          <HDCoachTabs
            selectedTab={0}
            tabs={props.tabsDataCoach}
            data={props.allCoachData}
            handleChange={(event, number) => reactGAEvents('coach-select-coach', `view-coach-${number + 1}`)}
          />
        </>
      )}
    </>
  );
};

export default SelectCoach;
