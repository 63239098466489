import { Box, CircularProgress, Fab, styled, useTheme } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';

export interface HDProgressCircleTypes {
  size?: number | undefined,
  thickness?: number | undefined,
  color?: string | undefined,
  progressColor?: string | undefined,
  completedColor?: string | undefined,
  completedIconColor?: string | undefined,
  status: 'noStarted' | 'started' | 'completed' | string;
  circleBackground?: string | undefined,
}

const HDProgressCircle = (props: HDProgressCircleTypes) => {
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const size = (props.size) ? props.size : 20;
  const thickness = (props.thickness) ? props.thickness : 1;
  const color = (props.color) ? props.color : (isDarkTheme) ? '#75A2B0' : '#74ADBA';
  const progressColor = (props.progressColor) ? props.progressColor : '#18768C';
  const completedColor = (props.completedColor) ? props.completedColor : (isDarkTheme) ? '#2CDADA' : '#18768C';
  const completedIconColor = (props.completedIconColor) ? props.completedIconColor : (isDarkTheme) ? '#66FCFC' : '#18768C';

  const BeginBox = styled(Box)((theme) => ({
    width: size + 'px',
    height: size + 'px',
    border: thickness + 'px solid ' + color,
    borderRadius: '100%',
  }));

  const StartedBox = styled(Box)((theme) => ({
    width: size,
    height: size,
    border: thickness + 'px solid ' + progressColor,
    borderRadius: '100%',
    position: 'absolute',
    left: 0,
    background: 'rgba(18, 171, 171, 0.4)',
    clipPath: 'inset(0px 0px 0px 50%)',
    top: 0
  }));

  const CompletedBox = styled(Box)((theme) => ({
    border: thickness + 'px solid ' + completedColor,
    width: size + 'px',
    borderRadius: '100%',
    height: size + 'px',
    display: 'flex',
    justifyContent: 'center'
  }));

  const CompletedIcon = styled(CheckIcon)((theme) => ({
    color: completedIconColor,
    fontSize: size + 'px',
    padding: '15%',
    height:'auto !important',
  }));

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        {props.status === 'notStarted' && <BeginBox></BeginBox>}
        {props.status === 'started' && <><BeginBox></BeginBox><StartedBox></StartedBox></>}
        {props.status === 'completed' && <CompletedBox><CompletedIcon /></CompletedBox>}
      </Box>
    </>
  );
}

export default HDProgressCircle;
