import React, { useEffect } from 'react';
import {
  Box,
  ThemeProvider,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CssBaseline, styled } from '@mui/material';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import HDButton from '../../hd-ui-kit/HDButton';
import { useNavigate } from 'react-router';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import Api from '../../Api';

const HomePage = () => {
  const themeMode = false;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const TitleStyle = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: isMobile || isTablet ? '31px' : '41px',
    lineHeight: isMobile || isTablet ? '41px' : '54px',
    color: theme.palette.mode == 'light' ? '#002136' : '#FFFFFF',
    textAlign: 'left',
  };

  const MainStyledBox = {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5px',
  };

  const StyledText = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '32px',
    color: theme.palette.mode == 'light' ? '#002136' : '#FFFFFF',
    textAlign: 'left',
    // marginTop: '15px',
  };

  const StyledTextTwo = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '32px',
    color: theme.palette.mode == 'light' ? '#334255' : '#CCCCCC',
    textAlign: 'left',
    // marginTop: '20px',
    // marginBottom: '10px',
  };

  const StyledSignUp = {
    display: 'flex',
    marginTop: '10px',
    alignItems: 'center',
    paddingBottom: '20px',
  };

  const StyledSignUpBtn = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.mode == 'light' ? '#18768C' : '#66FCFC',
    padding: '0px',
  };

  const TitleStyleHaveAccount = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.mode == 'light' ? '#002136' : '#CCCCCC',
    textAlign: 'left',
  };

  const StyledDividerLeft = {
    borderLeft:
      isMobile || isTablet
        ? ' '
        : theme.palette.mode == 'light'
          ? '1px solid rgba(0, 33, 54, 0.2)'
          : '1px solid  rgba(255, 255, 255, 0.2)',
    paddingLeft: isMobile || isTablet ? ' ' : '40px',
    marginTop: '20px',
    width: isMobile || isTablet ? '100%' : '50%',
  };

  const ArrowForwardSharpIconStyled = styled(ArrowForwardSharpIcon)(() => ({
    color: theme.palette.mode == 'light' ? '#66FCFC' : '#18768C',
  }));

  const StyledSignIn = {
    backgroundColor: theme.palette.mode == 'light' ? '#334255' : '#F1F1F1',
    color: theme.palette.mode == 'light' ? '#FFFFFF' : '#002136',
    marginTop: '20px',
    padding: '6px 16px',
    marginBottom: '20px',
  };

  const StyledBtn = {
    display: 'flex',
    paddingTop: '12px',
  };

  const StyledBoxMainWrapper = {
    background:
      theme.palette.mode == 'light'
        ? isMobile || isTablet
          ? 'url(res/homeBGLightMobile.svg) no-repeat top'
          : 'url(res/homeBGLight.svg) no-repeat top'
        : isMobile || isTablet
          ? 'url(res/homeBGDarkMobile.svg) no-repeat top'
          : 'url(res/homeBGDark.svg) no-repeat top',
    backgroundPosition: isMobile || isTablet ? 'right bottom' : 'right 0px',
    height: isMobile ? '1100px' : isTablet ? '1000px' : 'auto',
    backgroundSize: isTablet ? '80%' : 'unset',
  };

  const StyledDivWrapper = {
    paddingTop: '30px',
  };

  useEffect(() => {
    let isAuth = Api.isAuthorized();
    if (isAuth) navigate('/home');
  }, []);

  return (
    <>
      <CssBaseline />
      <Header />
      <Box sx={StyledBoxMainWrapper}>
        <Container maxWidth="lg">
          <Box sx={StyledDividerLeft}>
            <Box sx={MainStyledBox}>
              <Typography variant="h2" sx={TitleStyle}>
                Welcome to Heidrick for Leaders. By creating a profile, you can:
              </Typography>
            </Box>
            <Box sx={StyledDivWrapper}>
              <Box sx={MainStyledBox}>
                <Typography variant="h2" sx={StyledText}>
                  Submit your resume
                </Typography>
              </Box>
              <Box sx={MainStyledBox}>
                <Typography variant="h2" sx={StyledTextTwo}>
                  Add your resume into the Heidrick system for search consideration.
                </Typography>
              </Box>
            </Box>
            <Box sx={StyledDivWrapper}>
              <Box sx={MainStyledBox}>
                <Typography variant="h2" sx={StyledText}>
                  Manage preferences for your future
                </Typography>
              </Box>
              <Box sx={MainStyledBox}>
                <Typography variant="h2" sx={StyledTextTwo}>
                  Let us know what you’re interested in, so we can provide helpful guidance.
                </Typography>
              </Box>
            </Box>
            <Box sx={StyledDivWrapper}>
              <Box sx={MainStyledBox}>
                <Typography variant="h2" sx={StyledText}>
                  Learn where you need to grow
                </Typography>
              </Box>
              <Box sx={MainStyledBox}>
                <Typography variant="h2" sx={StyledTextTwo}>
                  See how your experience and Heidrick capabilities compare to accomplished leaders.
                </Typography>
              </Box>
            </Box>
            <Box sx={StyledBtn}>
              <HDButton
                variant={'contained'}
                sx={StyledSignIn}
                endIcon={<ArrowForwardSharpIconStyled />}
                text={'Create your profile'}
                onClick={() => navigate('/register')}
                showAnimation={true}
              />
            </Box>
            <Box sx={StyledSignUp}>
              <Box>
                <Typography variant="h2" sx={TitleStyleHaveAccount}>
                  Already have an account?
                </Typography>
              </Box>
              <Box>
                <HDButton
                  sx={StyledSignUpBtn}
                  variant={'text'}
                  text={'Sign In'}
                  onClick={() => navigate('/login')}
                />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default HomePage;
