import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import { lightTheme } from './hd-ui-kit/HDTheme';
import { redirectionApi } from './commons/commonFunctions';
import IdleTimer from './IdleTime';
import Api from './Api';
import './App.css';

// Home page
import Home from './pages/Home';
import MarketingHomePage from './pages/MarketingHomePage';

// Onboarding
import SignIn from './pages/auth/SignIn';
import NewSignUp from './pages/auth/NewSignUp';
import MarketingSignUp from './pages/auth/MarketingSignUp';
import HomePage from './pages/auth/HomePage';
import ForgetPassword from './pages/auth/ForgetPassword';
import ForgetPasswordActivate from './pages/auth/ForgetPasswordActivate';
import NewPassword from './pages/auth/NewPassword';
import ProfileCreationNew from './pages/onboarding/ProfileCreationNew';
import AccountActivation from './pages/auth/AccountActivation';
import PersonalDetails from './pages/onboarding/PersonalDetails';
import ProfessionalBackground from './pages/onboarding/ProfessionalBackground';

// Maintenance
import Maintenance from './pages/maintenance/Maintenance';

// My Profile, Account and Subscription
import ProfileEditNew from './pages/profile/ProfileEditNew';

// Reports
import ReportDetails from './pages/reports/ReportDetails';
import ExperienceReport from './pages/reports/ExperienceReport';
import ReportOverview from './pages/reports/ReportOverview';
import SkillReportDetails from './pages/reports/SkillReportDetails';

// Email
import UnsubscribeEmail from './pages/landing/UnsubscribeEmail';
import EmailActivation from './pages/auth/EmailActivation';

// Coach
import CoachPage from './pages/coach/CoachPage';
import CoachingSession from './pages/coach/CoachingSession';
import EmailChangeActivation from './pages/auth/EmailChangeActivation';
import NewHomePage from './pages/auth/NewHomePage';
import ScrollToTop from './ScrollToTop';
export interface TitleDictionaryProps {
  [key: string]: string | undefined;
}

const GoogleAnalytics = (): any => {
  const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

  useEffect(() => {
    ReactGA.initialize(GA_TRACKING_ID);
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: pathname,
    });
    console.log('pageview :: ' + pathname);
  }, [pathname]);

  return null;
};

function App() {
  //const { globalState } = useContext(globalContext);
  let location = useLocation();
  let navigate = useNavigate();
  // let themeMode = 'light';
  const isAuthorized = Api.isAuthorized();

  // if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  //   themeMode = 'light';
  // }

  const titleDictionary: TitleDictionaryProps = {
    '/': 'Profile | Heidrick for Leaders | Heidrick & Struggles',
    '/register': 'Profile | Create Your Account | Heidrick for Leaders | Heidrick & Struggles',
    '/account-activation': 'Profile | Account Activation | Heidrick for Leaders | Heidrick & Struggles',
    '/email-activation': 'Profile | Email Activation | Heidrick for Leaders | Heidrick & Struggles',
    '/login': 'Profile | Sign In | Heidrick for Leaders | Heidrick & Struggles',
    '/signin': 'Profile | Sign In | Heidrick for Leaders | Heidrick & Struggles',
    '/forget-pass-verify': 'Profile | Forgot Password | Heidrick for Leaders | Heidrick & Struggles',
    '/password-reset-activation/': 'Profile | Forgot Password | Heidrick & Struggles', // NOSONAR
    '/reset-activated': 'Profile | Reset Password Activated | Heidrick for Leaders | Heidrick & Struggles',
    '/create-profile/personal-details': 'Profile | Create Profile | Personal Details | Heidrick for Leaders | Heidrick & Struggles',
    '/create-profile/experience': 'Profile | Create Profile | Current Or Most Recent Experience | Heidrick for Leaders | Heidrick & Struggles',
    '/profile-edit': 'My Account | Heidrick for Leaders | Heidrick & Struggles',

    '/experience/overview': 'Experience | Overview | Heidrick for Leaders | Heidrick & Struggles',
    '/experience/details': 'Experience | Details | Heidrick for Leaders | Heidrick & Struggles',
    '/leadership-skills/details': 'Leadership Skills  | Details | Heidrick for Leaders | Heidrick & Struggles',
    '/leadership-skills/overview': 'Leadership Skills  | Overview | Heidrick for Leaders | Heidrick & Struggles',
    '/home': 'Home | Heidrick for Leaders | Heidrick & Struggles',

    '/my-account': 'My Account | Heidrick for Leaders | Heidrick & Struggles',
    '/my-coach': 'Coach | Sign Up | Heidrick for Leaders | Heidrick & Struggles',
    '/my-coach/dashboard': 'My Coach | Heidrick for Leaders | Heidrick & Struggles',

  };

  useEffect(() => {
    let currentPath = location.pathname;
    document.title = titleDictionary[currentPath] || 'Profile | Heidrick & Struggles';
  }, [location]);

  useEffect(() => {
    if (isAuthorized) {
      //const data = globalState;
      //if (data.account) {
      //handleRerdiretion(data, navigate);
      //} else {
      redirectionApi(navigate);
      //}
    }
  }, []);

  const RequireAuth = (params: {
    children: JSX.Element;
  }) => {
    let location = useLocation();

    if (!isAuthorized) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" replace state={{ path: location.pathname, search: location.search }} />;
    }

    return params.children;
  };

  if (process.env.REACT_APP_MAINTENANCE === 'true') return <Maintenance />;

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <div className="App">
        <ScrollToTop />
        <GoogleAnalytics />
        {process.env.REACT_APP_ONTRUST_DISABLE === 'false' && (
          <Helmet>
            <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" data-domain-script="55938bf8-add1-4463-9152-d4ea6e31e1ee"></script>
          </Helmet>
        )}
        <Routes>
          <Route index element={<Home />} />
          <Route path="/linkedin" element={<LinkedInCallback />} />
          <Route path="*" element={<Navigate to="/login" />} />
          <Route
            path="/home"
            element={
              <RequireAuth>
                <NewHomePage />
              </RequireAuth>
            }
          />
          {/* My reports page */}

          <Route
            path="/experience/overview"
            element={
              <RequireAuth>
                <ExperienceReport />
              </RequireAuth>
            }
          />
          <Route
            path="/experience/details"
            element={
              <RequireAuth>
                <ReportDetails />
              </RequireAuth>
            }
          />
          <Route
            path="/leadership-skills/overview"
            element={
              <RequireAuth>
                <ReportOverview />
              </RequireAuth>
            }
          />
          <Route
            path="/leadership-skills/details"
            element={
              <RequireAuth>
                <SkillReportDetails />
              </RequireAuth>
            }
          />
          {/* Onboarding, Login, Register page */}
          <Route
            path="/create-profile"
            element={
              <RequireAuth>
                <ProfileCreationNew />
              </RequireAuth>
            }
          >
            <Route path="experience" element={<ProfessionalBackground />} />
            <Route path="personal-details" element={<PersonalDetails />} />
          </Route>
          <Route path="/forget-pass-verify" element={<ForgetPassword />} />
          <Route path="/reset-activated" element={<ForgetPasswordActivate />} />
          <Route path="/password-reset-activation" element={<NewPassword />} />
          <Route path="/register" element={<NewSignUp />} />
          <Route path="/coaching-register" element={<MarketingSignUp />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/account-activation" element={<AccountActivation />} />
          <Route path="/new-email-activation" element={<EmailChangeActivation />} />
          <Route path="/email-activation" element={<EmailActivation />} />
          {/* Home page */}
          <Route path="/HomePage" element={<HomePage />} />
          {/* Profile edit page */}
          <Route path="/profile-edit" element={<ProfileEditNew />} />
          <Route path="unsubscribe-email" element={<UnsubscribeEmail />} />
          <Route path="/my-coach" element={<CoachPage />} />
          <Route path="/my-coach/dashboard" element={<CoachingSession />} />
          <Route path="/marketing-home-page" element={<MarketingHomePage />} />
        </Routes>
        <IdleTimer />
      </div>
    </ThemeProvider>
  );
}

export default App;
