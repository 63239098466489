import React, { useState, useContext } from 'react';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router';

import Api from '../Api';
import ConfirmationModal from '../organisms/modals/ConfirmationModal';
import HDNavigation from '../hd-ui-kit/HDNavigation';
import HDHeader from '../hd-ui-kit/HDHeader';
import { exportToJson } from '../utils/profile';
import { globalContext } from '../store';
import HDButton from '../hd-ui-kit/HDButton/HDButton';

function Header(props: any) {
  const { dispatch, globalState } = useContext(globalContext);
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const originHost = window.location.origin;
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  //const [data, setData] = useState(null);

  const getAccountDetails = async () => {
    await Api.getAccount()
      .then((res) => {
        let data = res.response.data;
        dispatch({ type: 'SET_ACCOUNT', payload: data });
        //setData(data);
      })
      .catch((err) => {
        console.log(err);
        navigate('/register');
      });
  };

  React.useEffect(() => {
    let isAuth = Api.isAuthorized();
    const checkEditData = props?.dataFromEditProfile && Object.keys(props?.dataFromEditProfile)?.length;
    if (isAuth) { getSubscriptionStatus(); }
    if (isAuth && !checkEditData && Object.keys(globalState?.account).length === 0) {
      getAccountDetails();
    }
    if (isAuth && checkEditData) {
      //setData(props?.dataFromEditProfile);
    }
  }, [props?.dataFromEditProfile]);

  const getSubscriptionStatus = async () => {
    if (Object.keys(globalState?.subscriptionStatus).length === 0) {
      try {
        const { success, response } = await Api.getSubscriptionStatus();
        if (success) {
          dispatch({ type: 'SET_SUBSCRIPTION_STATUS', payload: response?.data });
        }
      } catch (err) {
        console.log('err', err);
      }
    }
  };

  const logoStyle = {
    margin: '20px 20px 20px 0',
    cursor: Api.isAuthorized() && globalState?.account?.step_completed === 3 ? 'pointer' : 'default',
  };

  const logoClick = () => {
    if (Api.isAuthorized() && globalState?.account?.step_completed === 3) {
      navigate('/home');
    }
  };

  const onNavClick = (url: string) => {
    if (url === 'downloadProfile') {
      handleDownloadProfile();
    } else if (url === 'signOut') {
      handleLogout();
    } else {
      navigate(url);
    }
  };

  const handleDownloadProfile = () => setShowDownloadModal(true);

  const mainNav = [
    {
      url: '/home',
      className: (pathName.includes('/home') || pathName.includes('/experience') || pathName.includes('/leadership-skills')) ? 'yes' : '',
      itemName: 'Home'
    },
  ];

  //|| data?.personal_details?.country === 'United States of America'
  mainNav.splice(3, 0, {
    url: globalState?.subscriptionStatus?.status === 'NOT_ACTIVE' ? '/profile-edit?active=6' : '/my-coach',
    className: (pathName.includes('/my-coach')) || (pathName.includes('/my-coach/dashboard')) ? 'yes' : '',
    itemName: 'Coaching'
  });

  let myAccount = [
    {
      url: '/profile-edit?active=0',
      itemName: 'Account Details'
    },
    {
      url: '/profile-edit?active=1',
      itemName: 'Personal Details'
    },
    {
      url: '/profile-edit?active=2',
      itemName: 'Experience'
    },
    {
      url: '/profile-edit?active=3',
      itemName: 'Opportunity Preferences'
    },
    {
      url: '/profile-edit?active=4',
      itemName: 'Location Preferences'
    },
    {
      url: '/profile-edit?active=5',
      itemName: 'My Resume'
    },
    {
      url: 'downloadProfile',
      itemName: 'Download Profile'
    },
    {
      url: 'signOut',
      itemName: 'Sign Out'
    },
  ];

  if (globalState?.subscriptionStatus?.status != 'NOT_SIGNED_UP') { //|| data?.personal_details?.country === 'United States of America'(data?.stripe_test_group === 'A' || data?.stripe_test_group === 'B')
    myAccount.splice(6, 0, {
      url: '/profile-edit?active=6',
      itemName: 'My Coaching Subscription'
    });
  }

  const onDownloadProfile = async () => {
    setShowDownloadModal(false);
    // setAnchorEl(null);
    try {
      const res = await Api.getAccount();
      if (res.success) {
        const { data } = res.response;
        const {
          email,
          experience,
          location,
          opportunity_preferences,
          personal_details,
          resume,
        } = data;
        const exportObject = {
          account_detail: {
            email,
          },
          experience,
          location,
          opportunity_preferences,
          personal_details,
          resume,
        };
        exportToJson(exportObject);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDownloadModalClose = () => setShowDownloadModal(false);
  const handleLogout = () => setShowLogoutModal(true);

  // handler function for logging out modal
  function onYesLogout() {
    dispatch({ type: 'PURGE_STATE', payload: {} });
    Api.logout()
      .then((res) => {
        console.log(res.success);
        navigate('/login');
      })
      .catch((err) => {
        console.log(err);
        navigate('/login');
      });
    setShowLogoutModal(false);
  }

  function onNoLogout() {
    setShowLogoutModal(false);
  }

  function onCloseLogout() {
    setShowLogoutModal(false);
  }

  const signinBtnStyle = {
    fontFamily: 'Aventa',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '22px',
    width: '140px',
    position: 'absolute',
    right: 0
  };

  return (
    <div>
      <HDHeader
        logoStyle={logoStyle}
        logoClick={logoClick}
        alt="Heidrick and Struggles header logo"
        logo={originHost + (isDarkTheme ? '/res/hd-ui-kit-images/appLogoLight.svg' : '/res/hd-ui-kit-images/appLogo.svg')}
      >
        <HDNavigation
          darkLogo={originHost + '/res/hd-ui-kit-images/appLogoLight.svg'}
          lightLogo={originHost + '/res/hd-ui-kit-images/appLogo.svg'}
          navItems={Api.isAuthorized() && globalState?.account?.step_completed === 3 ? mainNav : []}
          myAccount={Api.isAuthorized() && globalState?.account?.step_completed === 3 ? myAccount : [{
            url: 'signOut',
            itemName: 'Sign Out'
          }]}
          onNavigationClick={onNavClick}
          accountIcon={originHost + (isDarkTheme ? '/res/myprofile-dark.svg' : '/res/myprofile-light.svg')}
        />
        {(props?.showSignin && !Api.isAuthorized()) ?
          <HDButton
            variant={'outlined'}
            text={'Sign in'}
            onClick={props?.signinClick}
            sx={signinBtnStyle}
          />
          : ''}
      </HDHeader>
      {showLogoutModal && (
        <ConfirmationModal
          header="Sign out?"
          description="Would you like to sign out?"
          onYes={onYesLogout}
          onNo={onNoLogout}
          onClose={onCloseLogout}
        />
      )}
      {showDownloadModal && (
        <ConfirmationModal
          header="Are you sure?"
          description="Ready to download your profile?"
          onYes={onDownloadProfile}
          onNo={onDownloadModalClose}
          onClose={onDownloadModalClose}
        />
      )}
    </div>
  );
}

export default Header;