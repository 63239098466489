import HDButton from '../../hd-ui-kit/HDButton/HDButton';
import HDModal from '../../hd-ui-kit/HDModal/HDModal';
import React, { useState, useEffect } from 'react';

import { useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { validateFields, validationOptions } from '../../ValidationSchema';
import { getTimeZoneOptions } from '../../commons/timezones';
import HDDropDown from '../../hd-ui-kit/HDDropDown/HDDropDown';
import formData from '../../utils/form-data';
import { Box } from '@mui/material';
import Api from '../../Api';
import { reactGAEvents } from '../../commons/commonFunctions';
import HDSpinner from '../../hd-ui-kit/HDSpinner/HDSpinner';
import { btnStyle } from '../../pages/profile/form-component/styles';

const TimeZoneSelectionModal = (props: any) => {
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  const isDarkTheme = themeMode === 'dark';
  const checkTimeZone = !props?.accountDetails?.iana_timezone;
  const checkIndustry = props?.accountDetails?.experience?.industry === 'Prefer not to respond' || !props?.accountDetails?.experience?.industry;
  const [timezone, setTimeZone] = useState({ value: '', label: '' });
  const [timezoneOptions, setTimeZoneOptions] = useState(getTimeZoneOptions('Other'));

  const [btnDisable, setBtnDisable] = useState(false);
  const [industry, setIndustry] = useState('');
  const [industrySector, setIndustrySector] = useState('');
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (props?.accountDetails?.personal_details?.country) {
      setTimeZoneOptions(getTimeZoneOptions(props?.accountDetails?.personal_details?.country || 'Other'));
    }
  }, [props?.accountDetails]);

  const timeZoneApiCall = async () => {
    const payload: any = {
      iana_timezone: timezone?.value,
    };
    await Api.updatePersonalDetail(payload, props?.accountDetails?.personal_details?.['id'] as never)
      .then((res) => {
        if (res.success) {
          setBtnDisable(false);
          setTimeZone({ value: '', label: '' });
        } else {
          const { data } = res;
          setBtnDisable(false);
          setErrors(data);
        }
      })
      .catch((err) => {
        console.log(err);
        setBtnDisable(false);
      });
  };

  const industryApiCall = async () => {
    let payloadExp = {
      industry: industry,
      industry_sector: industrySector,
    };

    await Api.updateExperience(payloadExp, props?.accountDetails?.experience?.['id'] as never)
      .then((res) => {
        if (res.success) {
          setBtnDisable(false);
          setIndustry('');
          setIndustrySector('');
        } else {
          const { data } = res;
          setErrors(data);
          setBtnDisable(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setBtnDisable(false);
      });
  };

  const onSave = async () => {
    setBtnDisable(true);
    setErrors({});
    const timeZoneMsg = validateFields.validate({ dropDown: timezone?.value ? timezone?.value : '' }, validationOptions)?.error?.details[0]?.message;
    const industryMsg = validateFields.validate({ dropDown: industry }, validationOptions)?.error?.details[0]?.message;
    const industrySectorMsg = validateFields.validate({ dropDown: industrySector }, validationOptions)?.error?.details[0]?.message;

    let errors: any = {};
    if (timeZoneMsg && checkTimeZone) {
      Object.assign(errors, { timezone: timeZoneMsg });
    }
    if ((industryMsg || industrySectorMsg) && checkIndustry) {
      Object.assign(errors, { industry: industryMsg });
      Object.assign(errors, { industry_sector: industrySectorMsg });
    }
    if (Object.keys(errors)?.length) {
      setErrors(errors);
      setBtnDisable(false);
      return;
    }

    if (checkTimeZone) {
      await timeZoneApiCall();
    }

    if (checkIndustry) {
      await industryApiCall();
    }
    setErrors({});
    props.handleTzModalClose();
  };

  const styledDescription = {
    overflow: 'auto',
    marginBottom: '24px',
    height: checkTimeZone ? (Object.keys(errors)?.length ? '480px' : '385px') : Object.keys(errors)?.length ? '280px' : '250px',
  };

  const modalStyle = {
    '& .MuiDialog-paper': {
      minWidth: '50%',
    },
  };

  return (
    <HDModal
      open={props.openTzModal}
      sx={modalStyle}
      handleModal={() => {
        setTimeZone({ value: '', label: '' });
        setIndustry('');
        setIndustrySector('');
        setErrors({});
        props.handleTzModalClose();
      }}
      title="Please fill all details"
      styledDescription={styledDescription}
      description={
        <>
          <Box>
            {checkTimeZone && (
              <HDDropDown
                dataTestId={'Time zone'}
                fieldLabel="Time zone"
                disabled={false}
                defaultValue=""
                isAutoComplete={true}
                autoCompleteOptions={timezoneOptions}
                value={timezone}
                onChange={(value) => {
                  reactGAEvents('my-account', `time-zone-${value?.label}`);
                  setTimeZone(value);
                }}
                error={errors?.timezone}
                errorMsg={errors?.timezone ? errors.timezone : null}
                id='time-zone'
              />
            )}
          </Box>
          <Box>
            {checkIndustry && (
              <>
                <Box sx={{ marginTop: checkTimeZone ? '35px' : 'none' }}>
                  <HDDropDown
                    dataTestId={'Industry'}
                    fieldLabel={'Industry'}
                    value={industry}
                    onChange={({ target }: any) => {
                      reactGAEvents('my-account', `industry-${target.value}`);
                      setIndustry(target.value);
                      setIndustrySector('');
                    }}
                    error={errors?.industry}
                    errorMsg={errors?.industry ? errors.industry : null}
                    dropDownItem={formData.industryMenu.map((ci) => ({ keyName: ci, keyValue: ci }))}
                    id='industry'
                  />
                </Box>

                <Box sx={{ marginTop: '35px' }}>
                  <HDDropDown
                    value={industrySector}
                    onChange={({ target }: any) => {
                      reactGAEvents('my-account', `sector-${target.value}`);
                      setIndustrySector(target.value);
                    }}
                    error={errors?.industry_sector}
                    errorMsg={errors?.industry_sector ? errors.industry_sector : null}
                    dropDownItem={formData.industrySector(industry).map((ci) => ({ keyName: ci, keyValue: ci }))}
                    fieldLabel={'Industry sector'}
                    id='industry-sector'
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      }
      actions={
        <>
          <HDButton
            showAnimation={true}
            disabled={btnDisable}
            endIcon={btnDisable ? <HDSpinner buttonColor={true} size={'la-sm'} /> : <ArrowForwardIcon sx={{ color: isDarkTheme ? '#18768C' : '#66FCFC' }} />}
            text="Save"
            variant="contained"
            sx={btnStyle(themeMode)}
            onClick={() => {
              onSave();
            }}
          />
        </>
      }
    />
  );
};

export default TimeZoneSelectionModal;
