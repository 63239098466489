import { backgroundTopLeft, backgroundTopLeftDark } from '../../../commons/commonFunctions';

export const headerStyle = (themeMode: string) => {
  return {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '31px',
    lineHeight: '41px',
    color: (themeMode === 'light') ? '#002136' : '#fff',
  };
};

export const subHeaderStyle = (themeMode: string) => {
  return {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '32px',
    color: (themeMode === 'light') ? '#002136' : '#fff',
    marginBottom: '16px',
  };
};

export const pageWrapper = (isMobile: boolean, themeMode?: string) => {
  const backColor = (themeMode === 'light') ? { ...backgroundTopLeft } : { ...backgroundTopLeftDark };
  return {
    textAlign: 'left',
    paddingLeft: (isMobile) ? 0 : '93px',
    paddingTop: '54px',
    marginBottom: '64px',
    ...backColor
  };
};

export const titleUnderline = (themeMode: string) => {
  return {
    width: '24px',
    backgroundColor: (themeMode === 'light') ? '#18768C' : '#66FCFC',
    marginTop: '16px',
  };
};

export const btnStyle = (themeMode: string) => {
  return {
  };
};

export const btnIconStyle = (themeMode: string) => {
  return {
    color: (themeMode === 'light') ? '#66fcfc' : '#18768c',
  };
};

export const btnWrapper = () => {
  return {
    marginTop: '32px',
    display: 'flex',
    justifyContent: 'space-between'
  };
};

export const textFieldStyle = () => {
  return {
    marginBottom: '36px',
  };
};

export const infoBoxStyle = (themeMode: string, position?: string) => {
  return {
    border: '1px solid ' + ((themeMode === 'light') ? '#E0E1E5' : '#3C3D40'),
    padding: '24px',
    borderRadius: '6px',
    marginTop: (position === 'top') ? '60px' : '30px'
  };
};

export const titleDivider = (themeMode: string) => {
  return {
    color: ((themeMode === 'light') ? '#E0E1E5' : '#3C3D40'),
    marginBottom: '16px',
  };
};

export const titleNote = (themeMode: string) => {
  return {
    color: ((themeMode === 'light') ? '#002136' : '#CCC'),
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
  };
};

export const titleNoteDisable = (themeMode: string) => {
  return {
    color: ((themeMode === 'light') ? '#646A7C' : '#FFF'),
    fontFamily: 'Aventa',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '23px',
    textDecorationLine: 'underline',
  };
};
