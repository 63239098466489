import React, { useState } from 'react';
import { CssBaseline } from '@mui/material';
import HDCoachProfileCard from '../../hd-ui-kit/HDCoachProfileCard';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { reactGAEvents, backgroundTopLeft, backgroundTopLeftDark } from '../../commons/commonFunctions';
import { CoachBioDescrition } from '../../commons/CoachComponents';

function MyCoach(props: any) {
  const { coachData, coachItem, errorMsg } = props;
  const theme = useTheme();
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isReadMore, setIsReadMore] = useState(true);
  const backColor = (isDarkTheme) ? { ...backgroundTopLeftDark } : { ...backgroundTopLeft };

  const StyledContainer = {
    textAlign: 'left',
    paddingLeft: isMobile ? 0 : '93px',
    paddingTop: '36px',
    marginBottom: '20px',
    ...backColor,
  };

  const handleEmailCoach = () => {
    reactGAEvents('coach-bio', 'email-coach');
    window.location.href = `mailto:${coachItem?.email}`;
  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const errorMsgStyle = {
    color: '#cc33a1',
    fontFamily: 'Avenir',
    fontSize: '16px',
    marginBottom: '20px'
  };

  return (
    <>
      <CssBaseline />
      <Box sx={StyledContainer}>
        {(coachData && coachData?.length) ? (
          <>
            <HDCoachProfileCard
              showButton={true}
              variant={'outlined'}
              fromCoach={true}
              showAnimation={true}
              profileBGMobile={(isDarkTheme) ? '../res/coachProfileBGMobileDark.svg' : '../res/coachProfileBGMobileLight.svg'}
              profileBGDark={(isDarkTheme) ? '../res/coachProfileBGDark.svg' : '../res/coachProfileBGLight.svg'}
              profileImg={coachItem?.avatar_url}
              firstName={coachItem?.first_name}
              lastName={coachItem?.last_name}
              handleClick={() => handleEmailCoach()}
            />

            {CoachBioDescrition(coachData, isMobile, isDarkTheme, isReadMore, toggleReadMore, 'myCoach')}
          </>
        ) : (
          <Box sx={errorMsgStyle}>{errorMsg}</Box>
        )}
      </Box>
    </>
  );
}

export default MyCoach;
