import { Box } from '@mui/system';
import React from 'react';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { MenuItem, MenuList, styled } from '@mui/material';
import { GenerateFooterSocialIcons } from '../../commons/commonFunctions';

const HDFooterMobile = (props: any) => {
  const urls = { linkedInUrl: props?.logoUrls?.linkedInUrl, youtubeUrl: props?.logoUrls?.youtubeUrl, twitterUrl: props?.logoUrls?.twitterUrl };
  const StyledBoxMainWrapper = styled(Box)(() => ({
    display: 'flex',
    flexGrow: 1,
    backgroundColor: '#334255',
    alignItems: 'flex-start',
    padding: '32px',
  }));

  const StyledBoxMainLayout = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    height: '250px',
    width: '100%',
  }));

  const StyledImage = styled('img')(() => ({
    marginTop: '26px',
    marginRight: '20px',
  }));

  const StyledBoxItemsWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  }));

  const StyledMenuList = styled(MenuList)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '10px',
  }));

  const StyledMenuItemBar = styled(MenuItem)(() => ({
    minHeight: '0px',
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    width: '100%',
    opacity: '0.1',
    borderRadius: '4px',
    borderTop: '1px solid',
    color: '#ffffff',
    cursor: 'default',
    background: '#334255',
  }));

  const StyledMenuItem = styled(MenuItem)(() => ({
    minHeight: '40px',
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#CCCCCC',
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '10px',
    paddingBottom: '10px',
    background: '#334255',
  }));

  return (
    <StyledBoxMainWrapper>
      <StyledBoxMainLayout>
        <GenerateFooterSocialIcons
          LinkedInIcon={LinkedInIcon}
          YouTubeIcon={YouTubeIcon}
          urls={urls}
        />
        <StyledBoxItemsWrapper>
          <StyledMenuList>
            {props.footerItems.map((element: any, key: any) => {
              return (
                <>
                  {key == 0 && <StyledMenuItemBar></StyledMenuItemBar>}
                  <StyledMenuItem onClick={() => props.onNavigationClick(element.url)}>
                    <a style={{ color: 'inherit', textDecoration: 'none' }} href='javascript:void(0)'>
                      {element.itemName}
                    </a>
                  </StyledMenuItem>
                  <StyledMenuItemBar></StyledMenuItemBar>
                </>
              );
            })}
          </StyledMenuList>
        </StyledBoxItemsWrapper>
        <Box>
          <a href={props.logoUrls.mainLogo} onClick={() => props.logoClick()}>
            <StyledImage src={window.location.origin + '/res/hsn-word-logo-new.svg'} alt='Heidrick & Struggles word logo' width='220px' />
          </a>
        </Box>
      </StyledBoxMainLayout>
    </StyledBoxMainWrapper>
  );
};

export default HDFooterMobile;
