import { Box, Typography, styled } from '@mui/material';
import HDSpinner from '../hd-ui-kit/HDSpinner';
import React from 'react';
import { useTheme } from '@mui/material';

function Loader(props: any) {
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const LoaderWrap = styled(Box)(() => ({
    position: 'fixed',
    height: '100%',
    width: '100%',
    background: (isDarkTheme) ? 'rgba(0, 33, 54, 1)' : '#fff',
    opacity: '0.9',
    zIndex: 999,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  }));

  return (
    <LoaderWrap>
      <HDSpinner size={'la-2x'} buttonColor={false} />
      {(props?.showNote) ? <Typography sx={{ marginTop: '20px', fontWeight: 700 }}>{props?.noteText}</Typography> : ''}
    </LoaderWrap>
  );
}

export default Loader;