import React, { useEffect, useState } from 'react';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BreadCrumb from '../../hd-ui-kit/HDBreadcrumb/HDBreadCrumb';
import HDProgressCircle from '../../hd-ui-kit/HDProgressCircle';
import { Box, Grid, Typography, Container, useTheme, useMediaQuery, Divider } from '@mui/material';
import { CssBaseline, styled } from '@mui/material';
import HDCard from '../../hd-ui-kit/HDCard';
import { useLocation } from 'react-router';
import Loader from '../../commons/Loader';
import HDSlider from '../../hd-ui-kit/HDSlider';
import { useNavigate } from 'react-router-dom';
import { reactGAEvents, redirectionApi, LegendContent, backgroundBottom, backgroundBottomDark, CoachingBanner } from '../../commons/commonFunctions';

function ReportDetails() {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [benchMarkMet, setBenchMarkMet] = useState<any>({});
  const [benchMarkNotMet, setBenchMarkNotMet] = useState<any>({});
  const location = useLocation();
  let navigate = useNavigate();
  const locationParams: any = location.state;
  const skillTitle = locationParams?.skillDetails?.title;
  const roleName = locationParams?.skillDetails?.roleName;

  const breadCrumbRoutes = [
    { text: 'Home', href: '/home' },
    { text: 'Business Impact Report', href: '/experience/overview' },
    { text: skillTitle, href: '#' },
  ];

  const DescriptionBox = styled(Box)(() => ({
    textAlign: 'left',
    marginBottom: '30px',
  }));

  const cardBackground = {
    background: isDark ? '#002136' : '#f8f8f8',
    textAlign: 'left',
    border: '1px solid ' + (isDark ? 'transparent' : '#DADCDF'),
  };

  const titleStyle = {
    fontSize: '16px',
    fontWeight: 500,
    color: isDark ? '#fff' : '#002136',
  };

  const titleHeadStyle = {
    fontSize: '31px',
    fontWeight: 500,
  };

  const descStyleSubtitle = {
    width: isMobile ? '100%' : '50%',
    paddingTop: '15px',
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    color: isDark ? '#fff' : '#334255',
  };

  const descStyle = {
    paddingTop: '15px',
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    color: isDark ? '#fff' : '#334255',
  };

  const descWrap = {
    marginTop: '20px',
  };

  const BodyWrapper = styled(Box)(() => ({
    marginBottom: '60px',
  }));

  const wrapStyling = {
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
    marginBottom: '20px',
  };

  const columnHeadings = {
    marginLeft: '10px',
    fontSize: '22px',
  };

  useEffect(() => {
    let benchMarkMet: any = [];
    let benchMarkNotMet: any = [];
    locationParams?.skillDetails?.skills.map((data: any) => {
      if (data.checked) {
        benchMarkMet.push({ title: data.text, description: data.description, progress: data.progress, benchMark: data.benchMark });
      } else {
        benchMarkNotMet.push({ title: data.text, description: data.description, progress: data.progress, benchMark: data.benchMark });
      }
    });

    setShowLoader(false);
    setBenchMarkMet(benchMarkMet);
    setBenchMarkNotMet(benchMarkNotMet);
  }, []);

  useEffect(() => {
    redirectionApi(navigate);
  }, []);

  const handleGaTags = (el: any) => {
    let action = '';
    if (el?.text == 'My Reports') {
      action = 'my-reports';
    } else if (el?.text == 'Business Impact Report') {
      action = 'business-impact-report';
    }
    reactGAEvents('my-reports-experience-details', action);
  };

  const SCDivider = styled(Divider)(() => ({
    marginTop: '32px !important',
    marginBottom: '32px !important',
  }));

  const headWrapStyle = {
    marginTop: '48px',
    marginBottom: '48px',
  };

  const StyledCoachingBanner = {
    marginTop: '30px',
    marginBottom: '30px',
  };

  return (
    <>
      <CssBaseline />
      {showLoader && <Loader />}
      <Header />
      <Box sx={isDark ? { ...backgroundBottomDark } : { ...backgroundBottom }}>
        <Container maxWidth='lg'>
          <BodyWrapper>
            <Box sx={headWrapStyle}>
              <BreadCrumb routes={breadCrumbRoutes} handleClick={(el: any) => handleGaTags(el)} />
              {!isMobile && <SCDivider />}
              <DescriptionBox>
                <Typography variant='h1' sx={titleHeadStyle}>
                  {skillTitle}
                </Typography>
                <Typography sx={descStyleSubtitle}>
                  As you review your areas of impact, consider where you meet the target for the position to which you are being compared, and where you may have opportunities for growth and development. Talk with your coach to explore ways where you may be able to grow your experiences to
                  achieve the target(s) for your desired role.
                </Typography>
              </DescriptionBox>
            </Box>
            {!showLoader && (
              <Box sx={StyledCoachingBanner}>
                <CoachingBanner />
              </Box>
            )}
            <Box sx={{ display: 'flex', marginBottom: '30px' }}>
              <LegendContent />
            </Box>
            <Grid container spacing={4} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
              <Grid item xs={12} md={6}>
                <Box sx={wrapStyling}>
                  <HDProgressCircle size={30} status={'completed'} />
                  <Typography variant='h2' sx={columnHeadings}>
                    Targets you've met ({Object.keys(benchMarkMet).length})
                  </Typography>
                </Box>
                {Object.keys(benchMarkMet).length
                  ? Object.keys(benchMarkMet).map((resp: any, indx: number) => (
                    <React.Fragment key={indx}>
                      <HDCard sx={cardBackground}>
                        <Box>
                          <Box>
                            <Typography variant='h6' sx={titleStyle}>
                              {benchMarkMet[indx]['title']}
                            </Typography>
                          </Box>
                          <HDSlider progress={benchMarkMet[indx]['progress']} benchMark={benchMarkMet[indx]['benchMark']} />
                          <Box sx={descWrap}>
                            <Typography sx={descStyle}>{benchMarkMet[indx]['description']}</Typography>
                          </Box>
                        </Box>
                      </HDCard>
                      <br />
                    </React.Fragment>
                  ))
                  : ''}
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={wrapStyling}>
                  <img alt='Development oppurtunity logo' src='/res/developemntIconLight.svg' />
                  <Typography variant='h2' sx={columnHeadings}>
                    Development Opportunity ({Object.keys(benchMarkNotMet).length})
                  </Typography>
                </Box>
                {Object.keys(benchMarkNotMet).length
                  ? Object.keys(benchMarkNotMet).map((resp: any, indx: number) => (
                    <React.Fragment key={indx}>
                      <HDCard sx={cardBackground}>
                        <Box>
                          <Box>
                            <Typography variant='h6' sx={titleStyle}>
                              {benchMarkNotMet[indx]['title']}
                            </Typography>
                          </Box>
                          <HDSlider progress={benchMarkNotMet[indx]['progress']} benchMark={benchMarkNotMet[indx]['benchMark']} />
                          <Box sx={descWrap}>
                            <Typography sx={descStyle}>{benchMarkNotMet[indx]['description']}</Typography>
                          </Box>
                        </Box>
                      </HDCard>
                      <br />
                    </React.Fragment>
                  ))
                  : ''}
              </Grid>
            </Grid>
          </BodyWrapper>
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default ReportDetails;
