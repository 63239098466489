import { Box, Container, Typography, useMediaQuery, useTheme, Divider } from '@mui/material';
import { CssBaseline } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Loader from '../../commons/Loader';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import {
  addFirstName,
  addLastName,
  addEmailAddress,
  addPassword,
  confirmPassword,
  privacyPolicy,
  termsOfUse,
  mailmeAboutJob,
  createAccountBtn,
  dividerWithOr,
  LinkedInBtn,
  alreadyHavingAccount
} from '../../commons/SignUpComponents';
import { useNavigate } from 'react-router';
import { ContainerStyle, contentWrapStyle, backImage, styledCardWrappers, titleStyle, formWrapper, Stepper, dividerStyle } from './NewSignupStyles';
import Api from '../../Api';
import { validateFields, validationOptions } from '../../ValidationSchema';
import { reactGAEvents, backgroundTopRight, backgroundTopRightDark } from '../../commons/commonFunctions';

const MarketingSignUp = () => {
  const theme = useTheme();
  const themeStyle = theme.palette.mode;
  const [loading, setLoading] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const fromCampaign = queryParameters.get('source');


  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfrim, setPasswordConfirm] = useState('');
  const [privacyPolicyChecked, setPrivacyPolicyCheck] = useState(false);
  const [termsOfUseChecked, setTermsOfUseChecked] = useState(false);

  const [textOrCall, setTextOrCall] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    reactGAEvents('profile-register', 'create-account');

    const firstNameMsg = validateFields.validate({ textField: firstName }, validationOptions)?.error?.details[0]?.message;
    const lastNameMsg = validateFields.validate({ textField: lastName }, validationOptions)?.error?.details[0]?.message;
    const isValidField = validateFields.validate(
      {
        email: email,
        password: password,
        confirmPassword: passwordConfrim,
        privacyPolicy: privacyPolicyChecked,
        termsOfUse: termsOfUseChecked
      },
      validationOptions
    );

    if (isValidField?.error || firstNameMsg || lastNameMsg) {
      let errorKeys: any = { firstName: '', lastName: '', email: '', password: '', privacy_policy: '', terms_of_use: '' };
      if (firstNameMsg) {
        errorKeys.firstName = firstNameMsg;
      }
      if (lastNameMsg) {
        errorKeys.lastName = lastNameMsg;
      }
      isValidField?.error?.details?.forEach((val) => {
        if (val?.context?.key === 'email') {
          errorKeys.email = val?.message;
        }
        if (val?.context?.key === 'password' || val?.context?.key === 'confirmPassword') {
          errorKeys.password = val?.message;
        }
        if (val?.context?.key === 'privacyPolicy') {
          errorKeys.privacy_policy = val?.message;
        }
        if (val?.context?.key === 'termsOfUse') {
          errorKeys.terms_of_use = val?.message;
        }
        return;
      });
      setErrors(errorKeys);
      return;
    }

    setErrors({});
    const payload: any = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      job_opportunities_reminders_opt_in: textOrCall,
      privacy_policy: privacyPolicyChecked,
      terms_of_use: termsOfUseChecked,
    };
    if (fromCampaign) {
      payload['source'] = fromCampaign;
    }

    setLoading(true);
    Api.register(payload)
      .then((response) => {
        if (response.success) {
          navigate('/account-activation');
        } else {
          const { data } = response;
          setErrors(data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    let isAuth = Api.isAuthorized();
    if (isAuth) navigate('/home');
  }, []);

  return (
    <>
      <CssBaseline />
      {loading && <Loader />}
      <Header />
      <Box sx={themeStyle === 'light' ? { ...backgroundTopRight, display: 'grid' } : { ...backgroundTopRightDark, display: 'grid' }}>
        <Container maxWidth='lg' sx={ContainerStyle(isMobile, isTablet)}>
          <Box
            sx={{
              background: theme.palette.mode == 'light' ? (isMobile || isTablet ? 'url(../res/NewPasswordBGLightMobile.svg)' : 'url(../res/NewPasswordBGLight.svg)') : isMobile || isTablet ? 'url(../res/NewPasswordBGDarkMobile.svg)' : 'url(../res/NewPasswordBGDark.svg)',
              ...backImage(isMobile, isTablet),
            }}
          ></Box>
          <Box sx={contentWrapStyle(isMobile, isTablet)}>
            {Stepper(0)}
            <Box sx={styledCardWrappers(isMobile, isTablet)}>
              <Typography sx={titleStyle(themeStyle)}>Start by creating your Heidrick for Leaders account.</Typography>
              <Box sx={formWrapper}>
                {addFirstName(reactGAEvents, firstName, setFirstName, errors)}
                {addLastName(reactGAEvents, lastName, setLastName, errors)}
                {addEmailAddress(reactGAEvents, email, setEmail, errors)}
                {addPassword(reactGAEvents, password, setPassword, errors)}
                {confirmPassword(reactGAEvents, passwordConfrim, setPasswordConfirm, errors)}
                {privacyPolicy(themeStyle, reactGAEvents, privacyPolicyChecked, setPrivacyPolicyCheck, errors)}
                {termsOfUse(themeStyle, reactGAEvents, termsOfUseChecked, setTermsOfUseChecked, errors)}
                {mailmeAboutJob(reactGAEvents, textOrCall, setTextOrCall)}
                {createAccountBtn(themeStyle, reactGAEvents, handleSubmit)}
                {dividerWithOr(themeStyle)}

                {LinkedInBtn(themeStyle, reactGAEvents, setErrors)}
                <Divider sx={dividerStyle(themeStyle)} />
                {alreadyHavingAccount(themeStyle, reactGAEvents, navigate, isMobile)}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default MarketingSignUp;
