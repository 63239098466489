import * as React from 'react';
import {
  styled,
  useTheme,
  useMediaQuery,
} from '@mui/material';

export interface HDSliderTypes {
  progress: number,
  benchMark: number,
}

const HDSlider = (props: HDSliderTypes) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const progressBarHeight = '9px';
  const progressBarBGColor = isDark ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 33, 54, 0.2)';

  const StyledMainBarWrapper = styled('div')(() => ({
    position: 'relative',
    height: progressBarHeight,
    //marginLeft: isMobile ? '0px' : '30px',
    top: isMobile ? '10px' : '18px',
    marginBottom: isMobile ? '15px' : '0px',
  }));

  const StyledMainBar = styled('div')(() => ({
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: progressBarHeight,
    borderRadius: '10px',
    backgroundColor: progressBarBGColor,
  }));

  const StyledProgress = styled('div')(() => ({
    position: 'absolute',
    height: progressBarHeight,
    borderRadius: '10px',
  }));

  const StyledMark = styled('div')(() => ({
    position: 'absolute',
    width: '8px',
    height: progressBarHeight,
    borderRadius: '10px',
    backgroundColor: isDark ? '#fff' : '#0B0C10',
    border: '1px solid' + ((isDark) ? '#002136' : '#fff')
  }));
  return (
    <StyledMainBarWrapper>
      <StyledMainBar>
        <StyledProgress sx={{ width: `${(props.progress === props.benchMark) ? props.progress + 1 : props.progress}%`, backgroundColor: (isDark) ? (props.progress >= props.benchMark) ? '#2CDADA' : '#FEC350' : (props.progress >= props.benchMark) ? '#18768C' : '#BC7D01' }} />
        <StyledMark sx={{ left: `${(props.benchMark === 100) ? 98 : props.benchMark}%` }} />
      </StyledMainBar>
    </StyledMainBarWrapper>
  );
};

export default HDSlider;
