import { Alert, styled } from '@mui/material';
import * as React from 'react';

export interface HDErrorBannerTypes {
  errorText: string | undefined;
  severity: any;
  variant: any;
}

const HDErrorBanner = (props: HDErrorBannerTypes) => {
  const StyledBox = styled('div')(() => ({
    display: 'flex',
    width:'100%'
  }));

  const StyledAlert = styled(Alert)(() => ({
    color: '#DF43B6 ',
    background: 'rgba(223, 67, 182, 0.1) ',
    border: '1px solid rgba(223, 67, 182, 0.3)',
    fontSize: '16px',
    fontFamily: 'Avenir',
    width:'100%'
  }));

  return (
    <StyledBox>
      <StyledAlert  severity={props.severity} variant={props.variant}>
        {props.errorText}
      </StyledAlert>
    </StyledBox>
  );
};

export default HDErrorBanner;
