import { Button } from '@mui/material';
import * as React from 'react';

export interface HDButtonTypes {
  // eslint-disable-next-line
  onClick?: (arg0: any) => void;
  text:
  | string
  | number
  | boolean
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | React.ReactFragment
  | React.ReactPortal
  | null
  | undefined;
  variant?: string | any;
  size?: string | any;
  startIcon?: any;
  endIcon?: any;
  disabled?: boolean;
  disableRipple?: boolean;
  sx?: Object;
  id?: string;
  value?: any;
  showAnimation?: boolean;
}

const HDButton = (props: HDButtonTypes) => {



  return <Button {...props} disableRipple={true}>{props.text}</Button>;
};

export default HDButton;
