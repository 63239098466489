import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { FormControl, FormLabel, styled, FormHelperText, useTheme } from '@mui/material';

export interface HDCitySelectionTypes {
  variant?: string | any;
  size?: string | any;
  disabled?: boolean;
  error?: boolean;
  disableRipple?: boolean;
  placeholder?: string;
  type: string;
  id?: string;
  label?: string;
  errorMsg?: string;
  sx?: Object;
  required?: any;
  value?: any;
  onChange?: any;
  onClick?: any;
}

const HDCitySelection = (props: any) => {
  const theme = useTheme()?.palette.mode;
  const [cities, setCities] = React.useState<any>([]);
  let requestOptions = { types: ['(cities)'] };
  const {
    value,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({ requestOptions });

  console.log('value', value, data);

  React.useEffect(() => {
    setCities(data);
  }, [data]);

  const handleInput = (value: string) => {
    setValue(value);
  };

  const handleChange = (event: any, value: any, reason: string) => {
    if (reason == 'clear') {
      props.onChange('');
    } else {
      props.onChange(value);
    }
  };

  const styledTextField = {
    '& .MuiAutocomplete-input': {
      padding: '6.5px 14px !important',
    },
  };

  const FormHelperTextStyled = styled(FormHelperText)(() => ({
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme === 'light' ? '#646A7C' : '#A9A9A9',
    marginLeft: 0,
    marginTop: '8px',
  }));

  const ErrorIcon = styled('img')(() => ({
    marginRight: '8px'
  }));

  return (
    <FormControl fullWidth={true}>
      {props.label && (
        <FormLabel  htmlFor={props?.id}  sx={{ marginBottom: '12px' }} error={props.error}>
          {props.label}
        </FormLabel>
      )}

      <Autocomplete
        freeSolo
        value={props.value}
        onChange={handleChange}
        options={cities.map((i: any) => i.description)}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={styledTextField}
            value={value}
            size="small"
            onChange={(event: any) => {
              handleInput(event.target.value);
            }}
            onClick={props?.onClick}
            error={props.error}
            helperText={props.helperText}
          />
        )}
      />
      {props.error && <FormHelperTextStyled error={true}>
        <ErrorIcon src={(window.location.origin) + '/res/hd-ui-kit-images/' + ((theme === 'light') ? 'warning-icon.svg' : 'warning-icon-dark.svg')} />
        {props.errorMsg}
      </FormHelperTextStyled>}
      {props.belowHelperTxt && <FormHelperTextStyled>{props.belowHelperTxt}</FormHelperTextStyled>}
    </FormControl>
  );
};

export default HDCitySelection;
