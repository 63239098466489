import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import ReactGA from 'react-ga4';

import { headerStyle, pageWrapper, titleUnderline, btnStyle, btnIconStyle, btnWrapper } from './styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HDButton from '../../../hd-ui-kit/HDButton';
import HDCard from '../../../hd-ui-kit/HDCard';
import HDCheckBox from '../../../hd-ui-kit/HDCheckBox/HDCheckBox';
import Api from '../../../Api';
import { reactGAEvents } from '../../../commons/commonFunctions';
import HDSpinner from '../../../hd-ui-kit/HDSpinner/HDSpinner';

function OpportunityPreferences(props: any) {
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [data, setData] = useState(null);
  const [executivePlacement, setExecutivePlacement] = useState(false);
  const [executiveConsulting, setExecutiveConsulting] = useState(false);
  const [boardPlacemenet, setBoardPlacement] = useState(false);
  const [errors, setErrors] = useState([]);
  const [professionalDevelopment, setProfessionalDevelopment] = React.useState(false);
  const [btnDisable, setBtnDisable] = useState(false);

  const textFieldWrap = {
    marginTop: '60px',
  };

  const hdCardStyle = {
    background: themeMode === 'light' ? '#F8F8F8' : '#002136',
    border: '1px solid ' + (themeMode === 'light' ? '#DADCDF' : 'transparent'),
    boxShadow: 'none',
    marginBottom: '24px',
  };

  const chkBoxStyle = {
    '.MuiFormControlLabel-label': {
      fontWeight: 500,
      fontSize: '16px',
    },
  };

  const noteStyle = {
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    color: themeMode === 'light' ? '#334255' : '#CCC',
    marginLeft: '30px',
  };

  useEffect(() => {
    if (props?.data) {
      setData(props?.data);
      setFormDataFromContext(props?.data);
    }
  }, [props?.data]);

  const setFormDataFromContext = (data: { [x: string]: boolean }) => {
    setExecutivePlacement(data['executive_placement']);
    setExecutiveConsulting(data['executive_consulting']);
    setBoardPlacement(data['board_placement']);
    setProfessionalDevelopment(data['professional_development'] as unknown as boolean);
  };

  const saveObject = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    props.onSuccess(false);
    reactGAEvents('my-account', 'save');
    setBtnDisable(true);
    // consolidate payload
    let payload = {
      executive_placement: executivePlacement,
      executive_consulting: executiveConsulting,
      board_placement: boardPlacemenet,
      professional_development: professionalDevelopment,
    };

    // send data off to be saved
    if (data) {
      Api.updateCareerPreferences(payload, data['id'] as never)
        .then((res) => {
          onApiSuccess(res, 'Edit');
        })
        .catch((err) => {
          console.log(err);
          setBtnDisable(false);
        });
    } else {
      Api.saveCareerPreferences(payload)
        .then((res) => {
          onApiSuccess(res, 'Save');
        })
        .catch((err) => {
          console.log(err);
          setBtnDisable(false);
        });
    }
  };

  const onApiSuccess = (res: any, actItem: string) => {
    if (res?.success) {
      ReactGA.event({
        category: 'Profile Edit - Career Opportunity Preferences',
        action: actItem,
      });
      props.onSuccess(true);
      setBtnDisable(false);
      window.scrollTo(0, 0);
    } else {
      const { data } = res;
      setBtnDisable(false);
      setErrors(data);
    }
  };

  const professionalDevelopmentText = 'I want to develop my leadership capabilities and get guidance on improving my performance or advancing my career.';

  return (
    <Box sx={pageWrapper(isMobile, themeMode)}>
      <Typography sx={headerStyle(themeMode)}>Career priorities</Typography>
      <Divider sx={titleUnderline(themeMode)} />

      <Box sx={textFieldWrap}>
        <HDCard sx={hdCardStyle}>
          <HDCheckBox
            id='executive-placement'
            labelTxt={'Full-time opportunities'}
            wrapperSX={chkBoxStyle}
            checked={executivePlacement}
            value={executivePlacement}
            onChange={({ target }: any) => {
              reactGAEvents('my-account', 'opportunity-executive-placement');
              setExecutivePlacement(target.checked);
            }}
          />
          <Typography sx={noteStyle}>I'm looking for a new executive role on a full-time, permanent basis.</Typography>
        </HDCard>

        <HDCard sx={hdCardStyle}>
          <HDCheckBox
            id='executive-consulting'
            labelTxt={'On-demand opportunities'}
            wrapperSX={chkBoxStyle}
            checked={executiveConsulting}
            value={executiveConsulting}
            onChange={({ target }: any) => {
              reactGAEvents('my-account', 'opportunity-executive-consulting');
              setExecutiveConsulting(target.checked);
            }}
          />
          <Typography sx={noteStyle}>I'm interested in consulting or temporary assignments.</Typography>
        </HDCard>

        <HDCard sx={hdCardStyle}>
          <HDCheckBox
            id='board-placement'
            labelTxt={'Board opportunities'}
            wrapperSX={chkBoxStyle}
            checked={boardPlacemenet}
            value={boardPlacemenet}
            onChange={({ target }: any) => {
              reactGAEvents('my-account', 'opportunity-board-placement');
              setBoardPlacement(target.checked);
            }}
          />
          <Typography sx={noteStyle}>I'm interested in joining a board of directors.</Typography>
        </HDCard>

        <HDCard sx={hdCardStyle}>
          <HDCheckBox
            value={professionalDevelopment}
            checked={professionalDevelopment}
            onChange={({ target }) => {
              reactGAEvents('my-account', 'opportunity-development-coaching');
              setProfessionalDevelopment(target.checked);
            }}
            labelTxt={'Professional Development and Coaching'}
            showBackground={false}
            id='professional-development'
          />
          <Typography sx={noteStyle}>{professionalDevelopmentText}</Typography>
        </HDCard>

        {errors && <Typography sx={noteStyle}>{errors}</Typography>}
      </Box>

      <Box sx={btnWrapper}>
        <HDButton text={'Save'} disabled={btnDisable} endIcon={btnDisable ? <HDSpinner size={'la-sm'} /> : <ArrowForwardIcon sx={btnIconStyle(themeMode)} />} variant={'contained'} sx={btnStyle(themeMode)} onClick={saveObject} showAnimation={true} />
      </Box>
    </Box>
  );
}

export default OpportunityPreferences;
