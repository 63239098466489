import React, { useState, useEffect, useContext } from 'react';
import { Container, useMediaQuery, useTheme, CssBaseline } from '@mui/material';
import Api from '../../Api';

import Header from '../../commons/Header';
import Footer from '../../commons/Footer';

import SessionDetails from './SessionDetails';
import DevelopmentPlan from './DevelopmentPlan';
import HDProfileNav from '../../hd-ui-kit/HDProfileNav';
import MyCoach from './MyCoach';
import Loader from '../../commons/Loader';
import { reactGAEvents, generateCoachData } from '../../commons/commonFunctions';
import { useNavigate } from 'react-router';
import ChemistrySessionDetails from './ChemistrySessionDetails';
import { globalContext } from '../../store';
import dayjs from 'dayjs';

const CoachingSession = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isDarkTheme = useTheme().palette.mode === 'dark';
  const state = '';
  const activeTab = '';
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const fromEmail = queryParameters.get('fromEmail');
  const fromEmailDP = queryParameters.get('fromEmailDP');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(activeTab ? activeTab : state ? state : 0);
  const [success, setSuccess] = useState<boolean>(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [sessionState, setSessionState] = useState({ status: 'notScheduled', oldSessionData: null });
  const [notScheduled, setNotScheduled] = useState(true);

  const [futureSessions, setFutureSessions] = useState([]);

  const [scheduleFutureSessions, setScheduleFutureSessions] = useState([]);

  const [previousSessions, setPreviousSessions] = useState([]);

  const [skipPreviousSessions, setSkipPreviousSessions] = useState();

  const [allSessionData, setAllSessionData] = useState<any>();
  const [sessionApiData, setSessionApiData] = useState<any>();
  const [developmentPlanData, setDevelopmentPlanData] = useState<any>();
  const [detailSessionData, setDetailSessionData] = useState<any>({});
  const [coachData, setCoachData] = useState<any>();
  const [coachItem, setCoachItem] = useState<any>();
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [chemistrySessionCompleted, setChemistrySessionCompleted] = useState<boolean>();
  const { globalState, dispatch } = useContext(globalContext);

  useEffect(() => {
    if (localStorage.getItem('fromEmailDP')) {
      localStorage.removeItem('fromEmailDP');
      setActiveTabIndex(1);
    } else {
      if (activeTab) setActiveTabIndex(activeTab + 1);
    }
    window?.scrollTo({
      behavior: 'smooth',
      top: 0
    });
  }, [activeTab]);

  useEffect(() => {
    let isAuth = Api.isAuthorized();
    if (!isAuth) {
      if (fromEmail) localStorage.setItem('fromEmailSession', JSON.stringify('True'));
      if (fromEmailDP) localStorage.setItem('fromEmailDP', JSON.stringify('True'));
      navigate('/login');
    }
  }, []);

  function getValue(arr: any) {
    var res: any = [];
    arr.map((item: any, index: number) => {
      res.push(index);
    });
    return res;
  }

  const closestSession = (res: any) => {
    var temp: any = [];
    res.response.data.sessions.forEach((d: any) => {
      if (!d.is_completed && d.start_time != null && new Date(d.start_date_hfl_specific?.replace(/-/g, '/')).getTime() > new Date().getTime()) {
        temp[d.session_id] = (Math.abs(new Date(d.start_date_hfl_specific?.replace(/-/g, '/')).getTime() - new Date().getTime()));
      }
    });
    var indexNum = 0;
    if (Object.keys(temp).length > 0) {
      let minId: any = null;
      if (Object.keys(temp).length > 1) {
        minId = Math.min.apply(Math, getValue(temp));
      } else {
        minId = parseInt(Object.keys(temp)?.[0]);
      }
      res.response.data.sessions.find(function (ele: any, i: number) {
        if (ele.session_id === minId) {
          indexNum = i;
        }
      });
    }
    return res.response.data.sessions?.[indexNum];
  };

  const removedUpcomingSessions = (res: any) => {
    const data = res?.response?.data?.sessions?.filter((i: any) => {
      return i.is_completed || (i.start_date_hfl_specific && new Date(i.start_date_hfl_specific?.replace(/-/g, '/')) <= new Date());
    });
    return data;
  };

  const CheckPreviousSessions = (res: any) => {
    const data = res?.response?.data?.sessions?.filter((i: any) => {
      return i.is_completed && (i.start_date_hfl_specific && new Date(i.start_date_hfl_specific?.replace(/-/g, '/')) <= new Date());
    });
    return data?.length ? data : null;
  };

  const CheckFutureSessions = (sessionsList: any, res: any) => {
    const closestSessions = closestSession(res);
    const data = sessionsList?.filter((i: any) => {
      if (i.session_id !== closestSessions.session_id && (i.start_date_hfl_specific && new Date(i.start_date_hfl_specific?.replace(/-/g, '/')).getTime() > new Date().getTime())) {
        return i;
      }
    });
    return data?.length ? data : null;
  };

  function getOrdinalNum(n: number) {
    return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
  }

  const ScheduleFutureSessions = (sessionsList: any) => {
    const data = sessionsList?.filter((i: any) => {
      return !i.start_date_hfl_specific;
    });

    return data?.length ? data : null;
  };

  const CheckSkipPreviousSessions = (res: any) => {
    const data = res?.response?.data?.sessions?.filter((i: any) => {
      if (i.is_completed === false && (i.start_date_hfl_specific && new Date(i.start_date_hfl_specific?.replace(/-/g, '/')).getTime() <= new Date().getTime())) {
        return i;
      }
    });

    return data?.length ? data : null;
  };

  const getCoachSession = async () => {
    setLoading(true);
    let res: any;
    if (!globalState?.sessionsData?.response?.data === false || globalState?.sessionsData === null || globalState?.sessionsData == undefined) {
      res = await Api.getCoachSessionApi();
      dispatch({ type: 'SET_SESSIONS_DATA', payload: res });
    }
    if (!res?.success) {
      setLoading(false);
      var supportLink = <a href='https://heidricksupport.zendesk.com/hc/en-us' target='_blank' style={{ color: 'inherit' }}>contact support</a>;
      const errorMsg = <p>There seems to be a problem accessing your coach feature. Please contact support for help. {supportLink}.</p>;
      setErrorMsg(errorMsg);
      setChemistrySessionCompleted(true);

    }
  };

  const coachDataHandle = async (res: any) => {
    if (res.success) {
      const sessionsListLocal = res?.response?.data?.sessions;
      if (sessionsListLocal && Object.keys(sessionsListLocal)?.length > 0) {
        if ((sessionsListLocal?.[0]?.['is_completed'] || (new Date(sessionsListLocal?.[0]?.['start_date_hfl_specific']?.replace(/-/g, '/')).getTime() < new Date().getTime())) && globalState?.subscriptionStatus?.status === 'ACTIVE') {
          setChemistrySessionCompleted(true);
        } else {
          setChemistrySessionCompleted(false);
        }
      }
      let coachResp: any;
      if (globalState?.myCoachData) {
        coachResp = globalState.myCoachData;
      } else {
        coachResp = await Api.getMyCoachApi();
        dispatch({ type: 'SET_MYCOACH_DATA', payload: coachResp });
      }
      if (coachResp.success) {
        const allsessions = { coach_full_name: res.response.data.coach_full_name, coachEmailId: coachResp.response.data.email, coachAvatar: res.response.data.coach_avatar_url, sessions: removedUpcomingSessions(res) };
        setAllSessionData(allsessions);
        const sortedData = res?.response?.data?.sessions?.sort((a: any, b: any) => a.position - b.position);
        setSessionApiData(sortedData);
        const upComingDate = closestSession(res)?.start_date_hfl_specific;
        const CheckUpComingStatus = Boolean(upComingDate && new Date(upComingDate?.replace(/-/g, '/')) >= new Date());
        setDetailSessionData(closestSession(res));
        setSessionState({ ...sessionState, status: CheckUpComingStatus ? 'scheduled' : 'notScheduled' });

        const sessionsList = addSchedulingWindow(res);
        const previousSession = CheckPreviousSessions(res);
        setPreviousSessions(previousSession);
        setScheduleFutureSessions(ScheduleFutureSessions(sessionsList));
        const futureSession = CheckFutureSessions(sessionsList, res);
        setFutureSessions(futureSession);
        setSkipPreviousSessions(CheckSkipPreviousSessions(res));
        setLoading(false);
      } else {
        setLoading(false);
        var link = (
          <a href='https://heidricksupport.zendesk.com/hc/en-us' target='_blank' style={{ color: 'inherit' }}>
            contact support
          </a>
        );
        const errorMsg = <p>There seems to be a problem accessing your coach feature. Please contact support for help. {link}.</p>;
        setErrorMsg(errorMsg);
      }
    }
  };

  const addSchedulingWindow = (res: any) => {
    const subscriptionDet = globalState?.subscriptionStatus;
    let currentDate = new Date();
    let timeDiff = currentDate.getTime() - new Date(subscriptionDet?.start_date).getTime();
    let monthInMilliseconds = 1000 * 60 * 60 * 24 * 30.436875;
    let months = Math.floor(timeDiff / monthInMilliseconds);
    let counter = (subscriptionDet?.quantity === 1) ? 7 + months : 13 + months;
    let count = 1;
    let startDate = new Date(subscriptionDet?.start_date);
    const sessionDet = res?.response?.data?.sessions;
    const data: any = [];

    for (let i = 1; i < sessionDet.length; i++) {
      if (counter > count) {
        let bookingStartDate: any = '';
        if (new Date(startDate) < new Date()) {
          bookingStartDate = new Date();
        } else {
          bookingStartDate = new Date(startDate);
        }


        const showStartDate = startDate.toLocaleString('default', { month: 'long' }) + ' ' + getOrdinalNum(startDate.getDate());
        let endDate = new Date(startDate.setMonth(startDate.getMonth() + 1));
        endDate = new Date(endDate.setDate(endDate.getDate() - 1));
        const showEndDate = endDate.toLocaleString('default', { month: 'long' }) + ' ' + getOrdinalNum(endDate.getDate());
        const bookingEndDate: any = dayjs(new Date(endDate.setDate(endDate.getDate() + 10)));
        if (subscriptionDet?.quantity === 2 && count % 2 == 0) {
          startDate = new Date(startDate.setDate(startDate.getDate()));
        } else {
          if (subscriptionDet?.quantity === 2) {
            startDate = new Date(startDate.setMonth(startDate.getMonth() - 1));
          } else {
            startDate = new Date(startDate.setDate(startDate.getDate()));
          }
        }

        sessionDet[i].scheduling_period = `Schedule between ${showStartDate} and ${showEndDate}`;
        sessionDet[i].booking_window = {
          startDate: dayjs.utc(new Date(bookingStartDate)).format('YYYY-MM-DDTHH:mm:ss'), endDate: dayjs.utc(new Date(bookingEndDate)).format('YYYY-MM-DDTHH:mm:ss')
        };
        count += 1;
        data.push(sessionDet[i]);
      }
    }
    return data;
  };

  const getDevelopmentPlan = () => {
    setLoading(true);
    Api.getLeaderDevelopmentPlanApi()
      .then((res: any) => {
        if (res.success) {
          if (res?.response?.data?.length) setDevelopmentPlanData(res?.response?.data);
          setLoading(false);
        } else {
          setLoading(false);
          var link = <a href='https://heidricksupport.zendesk.com/hc/en-us' target='_blank' style={{ color: 'inherit' }}>contact support</a>;
          const errorMsg = <p>There seems to be a problem accessing your coach feature. Please contact support for help. {link}.</p>;
          setErrorMsg(errorMsg);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (activeTabIndex == 0 && !allSessionData) {
      getCoachSession();
    } else if (activeTabIndex == 1 && !developmentPlanData) {
      getDevelopmentPlan();
    } else if (activeTabIndex == 2 && !coachData) {
      getMyCoach();
    }
  }, [activeTabIndex]);

  useEffect(() => {
    if (globalState?.sessionsData) {
      coachDataHandle(globalState?.sessionsData);
    }
    if (globalState?.sessionsData?.response?.data?.sessions == undefined || globalState?.sessionsData?.response?.data?.sessions?.length === 0) {
      navigate('/my-coach');
    }

  }, [globalState?.sessionsData, globalState?.subscriptionStatus]);


  const getMyCoach = async () => {
    setLoading(true);
    let res: any;
    if (globalState?.myCoachData) {
      res = globalState.myCoachData;
    } else {
      res = await Api.getMyCoachApi();
      dispatch({ type: 'SET_MYCOACH_DATA', payload: res });
    }
    if (res.success) {
      let item = res.response.data;
      setCoachItem(item);
      const data = generateCoachData(item, isDarkTheme);
      setCoachData(data);
      setLoading(false);
    } else {
      setLoading(false);
      var link = <a href='https://heidricksupport.zendesk.com/hc/en-us' target='_blank' style={{ color: 'inherit' }}>contact support</a>;
      const errorMsg = <p>There seems to be a problem accessing your coach feature. Please contact support for help. {link}.</p>;
      setErrorMsg(errorMsg);
    }

  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    let action = newValue === 0 ? 'left-nav-coach-sessions' : newValue === 2 ? 'left-nav-my-coach' : 'left-nav-development-plan';
    let category = newValue === 0 ? 'coach-session-details-1' : newValue === 2 ? 'coach-bio' : 'coach-development-plan';
    reactGAEvents(category, action);
    setActiveTabIndex(newValue);
    setSuccess(false);
    setEmailSuccess(false);
    window?.scrollTo({
      behavior: 'smooth',
      top: 0
    });
  };

  const containerStyle = {
    padding: '0 !important',
  };


  const sessionDetailsProps = {
    notScheduled, setNotScheduled,
    setLoading: setLoading,
    sessionState: sessionState,
    futureSessions, setFutureSessions,
    scheduleFutureSessions, setScheduleFutureSessions,
    previousSessions, setPreviousSessions,
    skipPreviousSessions, setSkipPreviousSessions,
    setSessionState: setSessionState,
    allSessionData: allSessionData,
    setAllSessionData: setAllSessionData,
    detailSessionData: detailSessionData,
    setDetailSessionData: setDetailSessionData,
    setActiveTabIndex: setActiveTabIndex,
    sessionApiData: sessionApiData,
    leaderDetailsData: globalState?.account,
    errorMsg: errorMsg
  };

  const myCoachProps = {
    setLoading: setLoading,
    coachData: coachData,
    coachItem: coachItem,
    errorMsg: errorMsg
  };

  const developmentPlanProps = {
    setLoading: setLoading,
    developmentPlanData: developmentPlanData,
    handleTabChange: handleTabChange,
    errorMsg: errorMsg
  };

  const navData = [
    [<SessionDetails key={1} {...sessionDetailsProps} />, 'Dashboard'],
    [<DevelopmentPlan key={2} {...developmentPlanProps} />, 'Development Plan'],
    [<MyCoach key={3} {...myCoachProps} />, 'My Coach'],
  ];


  return (
    <>
      {chemistrySessionCompleted ? (
        <>
          <CssBaseline />
          {loading && <Loader />}
          <Header />
          <Container maxWidth='lg' sx={isMobile ? containerStyle : {}}>
            <HDProfileNav tabsData={navData} selectedTab={activeTabIndex} handleChange={handleTabChange} success={success} emailSuccess={emailSuccess} showHeader={true} headerText={'Heidrick Coaching'} />
          </Container>
          <Footer />
        </>
      ) :
        (
          <ChemistrySessionDetails />
        )
      }
    </>
  );
};

export default CoachingSession;
