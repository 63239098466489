import HDFooter from '../hd-ui-kit/HDFooter';
import React from 'react';

function Footer () {

  const originHost = window.location.origin;

  const footerItems = [
    {
      url: `${originHost}/res/privacy-policy.pdf`,
      itemName: 'Privacy Policy',
    },
    {
      url: `${originHost}/res/terms-of-use.pdf`,
      itemName: 'Terms of Use',
    },
    {
      url: 'https://www.heidrick.com/en/legal-notice',
      itemName: 'Legal Notice',
    },
    {
      url: 'https://heidricksupport.zendesk.com/hc/en-us',
      itemName: 'Contact',
    },
  ];

  const logoUrls = {
    twitterUrl: 'https://twitter.com/heidrick',
    linkedInUrl: 'http://www.linkedin.com/company/heidrick-&-struggles',
    youtubeUrl: 'http://www.youtube.com/user/HeidrickStruggles',
    mainLogo: 'https://www.heidrick.com/en/',
  };

  return (
    <HDFooter
      logoUrls={logoUrls}
      footerItems={footerItems}
      logoClick={() => {}}
      onNavigationClick={(url) => window.open(url, '_blank', 'noopener,noreferrer')}
    />
  );
}

export default Footer;