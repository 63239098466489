import React, { createContext, ReactElement, ReactNode, useReducer } from 'react';
import Reducer from './reducer';
import { ContextType } from './types';
import Api from '../Api';

export function GlobalStore({ children }: { children: ReactNode }): ReactElement {
  const [globalState, dispatch] = useReducer(Reducer, initializeState());

  return <globalContext.Provider value={{ globalState, dispatch }}>{children}</globalContext.Provider>;
}

export const globalContext = createContext({} as ContextType);

export const initialState: any = {
  isAuthenticated: Api.isAuthorized,
  account: {},
  profile: null,
  assessmentStatus: {},
  capabilityReport: { results: {} },
  impactReport: { reportData: {}, otherDetails: {} },
  capabilityReportBlob: {},
  impactReportBlob: {},
  subscriptionStatus: {},
  sessionsData:null,
  myCoachData:null
};

function initializeState() {
  return initialState;
}
