import { Box, ClickAwayListener, styled, Tooltip, useTheme } from '@mui/material';
import * as React from 'react';

export interface HDToolTipTypes {
  tooltipIcon?: any;
  tooltipText: string;
  sx?: object;
}

const HDToolTip = (props: HDToolTipTypes) => {
  const [open, setOpen] = React.useState(false);
  const isDarkTheme = useTheme().palette.mode === 'dark';

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const ToolTipIconWrap = styled(Box)(() => ({
    borderRadius: '50%',
    backgroundColor: isDarkTheme ? '#CCCCCC' : '#646A7C',
    display: 'flex',
    padding: '6px 9px',
    cursor: 'pointer',
  }));

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableTouchListener
          title={props.tooltipText}
          sx={props.sx}
        >
          <ToolTipIconWrap onClick={handleTooltipOpen}>
            {props.tooltipIcon ? (
              props.tooltipIcon
            ) : (
              <img alt='Tooltip default logo'
                src={
                  isDarkTheme
                    ? '../res/hd-ui-kit-images/defaultDark.svg'
                    : '../res/hd-ui-kit-images/defaultLight.svg'
                }
              />
            )}
          </ToolTipIconWrap>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default HDToolTip;
