import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import useWindowDimensions from './DimensionChecker';

import '../../stylesheets/backgrounds.css';

import Api from '../../Api';

function ProfileCreationNew() {
  const [data, setData] = useState(null);
  let location = useLocation();

  useEffect(() => {
    let currentForm = location.pathname.split('/')[2];
    handleLocationDataChange(currentForm);
    window.scrollTo(0, 0);
  }, [location]);

  function handleLocationDataChange(dataKey: string) {
    Api.getAccount()
      .then((res) => {
        if (res.success) {
          let injectionData = res.response.data;
          setData(injectionData);
        } else {
          setData(null);
        }
      })
      .catch((err) => {
        setData(null);
      });
  }

  return <Outlet context={{ data: data }} />;
}

export default ProfileCreationNew;
