import * as React from 'react';
import {
  Box,
  Grid,
  styled,
  Typography,
  useTheme
} from '@mui/material';

export interface HDStatusProgressBarTypes {
  width?: number;
  height?: number;
  progress: number;
  mark: number;
  markName: string;
  progressColor?: string;
  primaryColor?: string;
  markColor?: string;
  showProgressDots?: boolean;
  legendText?: any;
}

const HDStatusProgressBar = ({
  width,
  height,
  progress,
  mark,
  markName,
  progressColor,
  primaryColor,
  markColor,
  showProgressDots,
  legendText
}: HDStatusProgressBarTypes) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const barColor = primaryColor ? primaryColor : isDark ? 'rgba(255, 255, 255, 0.2)' : mark > progress ? 'rgba(100, 106, 124, 0.2)' : 'rgba(0, 33, 54, 0.2)';
  const percentColor = progressColor ? progressColor : isDark ? mark > progress ? '#FEC350' : '#2CDADA' : mark > progress ? '#BC7D01' : '#18768C';
  const markerColor = markColor ? markColor : isDark ? '#FFF' : '#002136';
  const statusText = mark > progress ? 'on your way to' : 'exceed';
  const statusTextAbbre = mark > progress ? 'are ' : '';
  const progressBarWidth = width ? width : '100%';
  const progressBarHeight = height ? height : 8;
  const borderRadius = progressBarHeight / 2;

  const StyledProgressBarContainer = styled('div')(() => ({
    position: 'relative',
    //fontFamily: 'Avenir',
  }));

  const StyledMainBarWrapper = styled('div')(() => ({
    position: 'relative',
    height: progressBarHeight,
  }));

  const StyledMainBar = styled('div')(() => ({
    position: 'absolute',
    left: 0,
    top: 0,
    width: progressBarWidth,
    height: progressBarHeight,
    borderRadius: `${borderRadius}px`,
    backgroundColor: barColor,
  }));

  const StyledProgress = styled('div')(() => ({
    position: 'absolute',
    width: `calc(${progress}% + ${borderRadius}px)`,
    height: progressBarHeight,
    backgroundColor: percentColor,
    borderRadius: `${borderRadius}px`,
  }));

  const StyledMark = styled('div')(() => ({
    position: 'absolute',
    left: `calc(${mark}% - ${borderRadius}px)`,
    width: progressBarHeight,
    height: progressBarHeight,
    //backgroundColor: isDark ? markerColor : '#0B0C10',
    backgroundColor: markerColor,
    borderRadius: `${borderRadius}px`,
    border: '1px solid #fff',
  }));

  const StyledBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center'
  }));

  const StyledRateWrapper = styled('div')(() => ({
    marginBottom: '17px',
  }));

  const StyledYouCircle = styled('div')(() => ({
    width: progressBarHeight,
    height: progressBarHeight,
    marginRight: progressBarHeight,
    backgroundColor: percentColor,
    borderRadius: `${borderRadius}px`,
  }));

  const StyledRoleCircle = styled('div')(() => ({
    width: progressBarHeight,
    height: progressBarHeight,
    marginRight: progressBarHeight,
    backgroundColor: markerColor,
    borderRadius: `${borderRadius}px`,
  }));

  const StyledScoreDescription = styled('div')(() => ({
    fontWeight: 300,
    lineHeight: '24px',
    'span': {
      color: isDark ? '#FFF' : '#002136',
      fontWeight: 600,
    }
  }));

  const StyledStatusWrapper = styled('div')(() => ({
    fontWeight: 300,
    lineHeight: '24px',
    color: (isDark) ? '#fff' : '#002136'
  }));

  const StyledYouStatusText = styled('span')(() => ({
    color: !isDark && mark > progress ? '#9E5F00' : percentColor,
  }));

  const styleWrap = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px'
  };

  return (
    <StyledProgressBarContainer>
      {showProgressDots &&
        <StyledRateWrapper>
          <Grid container spacing={0}>
            <Grid item md={3} sm={12} xs={12}>
              <StyledBox>
                <StyledYouCircle />
                <StyledScoreDescription>You: <span>{progress}%</span></StyledScoreDescription>
              </StyledBox>
            </Grid>
            <Grid item md={9} sm={12} xs={12}>
              <StyledBox>
                <StyledRoleCircle />
                <StyledScoreDescription>Target: <span>{mark}%</span></StyledScoreDescription>
              </StyledBox>
            </Grid>
          </Grid>
        </StyledRateWrapper>
      }
      <StyledMainBarWrapper>
        <StyledMainBar>
          <StyledProgress />
          <StyledMark />
        </StyledMainBar>
      </StyledMainBarWrapper>
      <Box sx={styleWrap}>
        <StyledStatusWrapper>
          You {statusTextAbbre}<StyledYouStatusText>{statusText}</StyledYouStatusText> the {markName} target.
        </StyledStatusWrapper>
        <Box>{legendText}</Box>
      </Box>
    </StyledProgressBarContainer>
  );
};

export default HDStatusProgressBar;
