import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface HDAccordionProps {
  title: string;
  expanded: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  children: any;
}

export default function CustomizedAccordions({ title, expanded, onChange, children}: HDAccordionProps) {
  const sxAccordion = {
    background: 'transparent',
    border: 'none',
    borderTop: '1px solid rgba(100, 106, 124, 0.2)',
  };

  const sxAccordionSummary = {
    padding: '18px 0',
    '.MuiAccordionSummary-content': {
      margin: 0,
    },
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '31px',
    marginBottom: '24px',
  };

  const sxAccordionDetails = {
    border: 'none',
    padding: '0 6px',
    display: 'flex',
  };

  return (
    <Accordion expanded={expanded} onChange={onChange} sx={sxAccordion}>
      <AccordionSummary sx={sxAccordionSummary}>
        {title}
      </AccordionSummary>
      <AccordionDetails sx={sxAccordionDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
