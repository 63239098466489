import HDButton from '../hd-ui-kit/HDButton';
import HDModal from '../hd-ui-kit/HDModal';
import React, { useContext, useState, useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { addGAEventsForChemistrySession } from './commonFunctions';
import Api from '../Api';
import Loader from './Loader';

const FreeSessionNotifyModal = (props: any) => {
  const themeMode = useTheme().palette.mode === 'dark';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [titleMsg, setTitleMsg] = useState('');
  const [showBtn, setShowBtn] = useState(false);
  const [descriptionMsg, setDescriptionMsg] = useState('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    Api.getAccount()
      .then((res) => {
        let data = res.response.data;
        if (data?.waiting_list) {
          setShowBtn(true);
          setTitleMsg('Added to waiting list');
          setDescriptionMsg('You are already on the waiting list.');
          setTimeout(() => {
            setShowBtn(false);
            props.setOpenNofityModal(false);
          }, 10000);
        } else {
          setShowBtn(false);
          setTitleMsg('Thank you for your interest in our introductory coaching session.');
          setDescriptionMsg('All our executive coaches are booked at this time. Click below to be added to the waiting list, and we will contact you via email as soon as more sessions become available.');
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const btnText: any = <p style={{ margin: '0px' }}>Add&nbsp;me&nbsp;to&nbsp;the&nbsp;waiting&nbsp;list</p>;
  const handleClick = () => {
    addGAEventsForChemistrySession('button-add-wait-list');
    Api.NotifyForFreeSession()
      .then((res: any) => {
        if (res.success) {
          setShowBtn(true);
          setTitleMsg('Success!');
          setDescriptionMsg('You have been added to the waiting list.');
          setTimeout(() => {
            setShowBtn(false);
            props.setOpenNofityModal(false);
          }, 10000);
        }
      })
      .catch((error) => {
        setShowBtn(true);
        setTitleMsg('Success!');
        setDescriptionMsg('You have been added to the waiting list.');
        setTimeout(() => {
          setShowBtn(false);
          props.setOpenNofityModal(false);
        }, 10000);
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <HDModal
          sx={{ '& .MuiDialog-paper': { width: isMobile ? '100%' : titleMsg ? '40%' : '50%' } }}
          open={props.openNotifyModal}
          title={titleMsg}
          description={descriptionMsg}
          handleModal={() => {
            props.setOpenNofityModal(false);
          }}
          actions={
            showBtn ? (
              <></>
            ) : (
              <HDButton
                text={btnText}
                variant={'contained'}
                size={'medium'}
                showAnimation={true}
                endIcon={<ArrowForwardIcon sx={{ color: themeMode ? '#18768C' : '#66FCFC' }} />}
                onClick={() => {
                  handleClick();
                }}
              />
            )
          }
        />
      )}
    </>
  );
};

export default FreeSessionNotifyModal;
