import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  List,
  ListItem,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import HDCard from '../../hd-ui-kit/HDCard';
import HDStatusProgressBar from '../../hd-ui-kit/HDStatusProgressBar';

const LeadershipReportDetails = (props: any) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [progressBar, setprogressBar] = useState<any>({});
  const [cardsData, setCardsData] = useState<any>({});
  const themeMode = false;

  useEffect(() => {
    setCardsData(props?.cardDetails);
    setprogressBar(props?.skillDetails);
  }, []);

  const DescriptionBox = styled(Box)(() => ({
    textAlign: 'left',
    marginBottom: '30px',
  }));

  const ProgressBarWrap = styled(Box)(() => ({
    border: '1px solid #CCD3D7',
    borderRadius: '5px',
    padding: '30px',
    textAlign: 'left',
    marginBottom: '60px',
    fontFamily: 'Aventa',
    fontSize: '20px',
    color: '#646A7C',
    fontWeight: 500,
  }));

  const descStyle = {
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    color: (themeMode) ? '#fff' : '#334255',
  };

  const wrapStyling = {
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
    marginBottom: '20px',
  };

  const cardBackground = {
    background: (themeMode) ? '#002136' : '#f1f1f1',
    textAlign: 'left',
  };

  const descWrap = {
    marginTop: '10px',
  };

  const ListStyles = {
    listStyleType: 'disc',
    padding: '0 0 0 30px',
    fontFamily: 'Avenir',
    fontSize: '16px',
    color: '#334255',
    fontWeight: 300,
    lineHeight: '24px',
  };

  const ListItemStyle = {
    display: 'list-item',
    padding: 0,
    marginBottom: '15px',
  };

  const WrapperYouAndBenchmark = styled(Box)(() => ({
    display: 'flex',
    justifyContent: isMobile ? 'flex-start' : 'flex-end',
    textAlign: 'left',
    alignItems: 'flex-start',
    //marginTop: isMobile ? '10px' : '-20px',

  }));

  const InnerWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'baseline',
  }));

  const RoleLegendWrapper = styled(Box)(() => ({
    height: '18px',
    width: '5%',
  }));

  const RoleLegend = styled(Box)(() => ({
    height: '100%',
    width: '18px',
    borderRadius: '50%',
    background: (themeMode) ? '#fff' : '#0b0c10',
    border: '1px solid' + ((themeMode) ? '#0b0c10' : 'transparent'),
    display: 'inline-block'
  }));

  const YouLegendWrap = styled(Box)(() => ({
    display: 'flex',
  }));

  const YouLengendLeft = styled(Box)(() => ({
    background: '#bc7d01',
    height: '16px',
    width: '8px',
    borderRadius: '150px 0 0 150px',
    marginRight: '1px',
  }));

  const YouLengendRight = styled(Box)(() => ({
    background: '#18768c',
    height: '16px',
    width: '8px',
    borderRadius: '0 150px 150px 0'
  }));

  const TypographyStyledYou = {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    paddingLeft: '5px',
    paddingRight: '13px',
    color: isDark ? '#FFFFFF' : '#002136',
    position: 'relative',
    top: '-5px',
  };

  const TypographyStyledBenchMark = {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    paddingLeft: '5px', color: isDark ? '#FFFFFF' : '#002136',
    position: 'relative',
    top: '-5px',
  };

  const titleHeadStyle = {
    fontSize: '31px',
    fontWeight: 500,
  };

  const subTitleStyle = {
    fontSize: '22px',
    fontWeight: 400,
  };

  const cardHeadingStyle = {
    fontSize: '16px',
    fontWeight: 500,
  };

  return (
    <>
      <DescriptionBox>
        <Typography variant='h1' sx={titleHeadStyle}>
          {progressBar?.title}
        </Typography>
        <Typography sx={descStyle}>
          {progressBar?.description}
        </Typography>
      </DescriptionBox>

      <ProgressBarWrap>
        <HDStatusProgressBar
          progress={progressBar?.youPercent}
          showProgressDots={false}
          mark={progressBar.rolePercent}
          markName={progressBar?.userDetails?.businessRole?.name}
          height={20}
          markColor={'#0B0C10'}
        />
        <WrapperYouAndBenchmark>
          <InnerWrapper>
            <YouLegendWrap>
              <YouLengendLeft>&nbsp;</YouLengendLeft>
              <YouLengendRight>&nbsp;</YouLengendRight>
            </YouLegendWrap>
            <Typography sx={TypographyStyledYou}>You</Typography>
          </InnerWrapper>
          <InnerWrapper>
            <RoleLegendWrapper>
              <RoleLegend>&nbsp;</RoleLegend>
            </RoleLegendWrapper>
            <Typography
              sx={TypographyStyledBenchMark}
            >
              {progressBar?.userDetails?.businessRole?.name} target
            </Typography>
          </InnerWrapper>
        </WrapperYouAndBenchmark>

      </ProgressBarWrap>

      <Grid container spacing={4} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Grid item xs={12} md={6}>
          <Box sx={wrapStyling}>
            <Typography variant='h2' sx={subTitleStyle}>Understanding your score</Typography>
          </Box>

          {(cardsData && cardsData.whereAreYouNow) &&
            <HDCard sx={cardBackground} >
              <Box>
                <Box>
                  <Typography variant='h6' sx={cardHeadingStyle}>Where are you now?</Typography>
                </Box>
                <Box sx={descWrap}>
                  {(Array.isArray(cardsData?.whereAreYouNow)) ?
                    <List sx={ListStyles}>
                      {cardsData?.whereAreYouNow.map((arrVal: any, index: number) => (
                        <ListItem sx={ListItemStyle} key={index}>{arrVal}</ListItem>
                      ))}
                    </List>
                    :
                    <Typography sx={descStyle}>{cardsData?.whereAreYouNow}</Typography>
                  }
                </Box>
              </Box>
            </HDCard>
          }
          <br />

          {(cardsData && cardsData.devTips) &&
            <HDCard sx={cardBackground} >
              <Box>
                <Box>
                  <Typography variant='h6' sx={cardHeadingStyle}>Development tips</Typography>
                </Box>
                <Box sx={descWrap}>
                  {(Array.isArray(cardsData?.devTips)) ?
                    <List sx={ListStyles}>
                      {cardsData?.devTips.map((arrVal: any, index: number) => (
                        <ListItem sx={ListItemStyle} key={index}>{arrVal}</ListItem>
                      ))}
                    </List>
                    :
                    <Typography sx={descStyle}>{cardsData?.devTips}</Typography>
                  }
                </Box>
              </Box>
            </HDCard>
          }
          <br />

          {(cardsData && cardsData.whatGreatLooksLike) &&
            <HDCard sx={cardBackground} >
              <Box>
                <Box>
                  <Typography variant='h6' sx={cardHeadingStyle}>
                    What great "{progressBar?.title?.toLowerCase()}" looks like
                  </Typography>
                </Box>
                <Box sx={descWrap}>
                  {(Array.isArray(cardsData?.whatGreatLooksLike)) ?
                    <List sx={ListStyles}>
                      {cardsData?.whatGreatLooksLike.map((arrVal: any, index: number) => (
                        <ListItem sx={ListItemStyle} key={index}>{arrVal}</ListItem>
                      ))}
                    </List>
                    :
                    <Typography sx={descStyle}>{cardsData?.whatGreatLooksLike}</Typography>
                  }
                </Box>
              </Box>
            </HDCard>
          }

        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={wrapStyling}>
            <Typography variant='h2' sx={subTitleStyle}>
              What makes up "{progressBar?.title?.toLowerCase()}"?
            </Typography>
          </Box>
          {(cardsData && cardsData['energizers']) && Object.keys(cardsData?.['energizers'])?.map((data: any, index: number) => (
            <React.Fragment key={index}>
              <HDCard sx={cardBackground} >
                <Box>
                  <Box>
                    <Typography variant='h6' sx={cardHeadingStyle}>
                      {cardsData?.['energizers']?.[data]?.['name']}
                    </Typography>
                  </Box>
                  <Box sx={descWrap}>
                    <Typography sx={descStyle}>
                      {cardsData?.['energizers']?.[data]?.['description']}
                    </Typography>
                  </Box>
                </Box>
              </HDCard>
              <br />
            </React.Fragment>
          ))}
        </Grid>
      </Grid>

    </>
  );
};

export default LeadershipReportDetails;