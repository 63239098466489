import {
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  styled,
  FormLabel,
  useTheme,
  FormGroup,
  Box,
} from '@mui/material';
import * as React from 'react';
import HDButton from '../HDButton';
import CancelIcon from '@mui/icons-material/Cancel';

//When using this component in our App Remove HDParent and stories file and remember to pass
//values as array and setter function from our app

export interface HDMultipleTextFieldTypes {
  disabled?: boolean;
  error?: any;
  placeholder?: string;
  id?: string;
  lblText?: string;
  errorMsg?: any;
  sx?: Object;
  value: any[];
  setterFn: (arg0: any[]) => void;
  buttonProps?: {
    text?: string;
    startIcon?: any;
    endIcon?: any;
  };
}

const HDMultipleTextFields = (props: HDMultipleTextFieldTypes) => {
  const theme = useTheme()?.palette.mode;

  function handleAddIndex() {
    props.setterFn([...props.value, '']);
  }

  function removeItem(
    removeIndex: number,
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    props.setterFn(props.value.filter((item, index) => index !== removeIndex));
  }

  function onChangeText(index: number, event: any) {
    let newArr = props.value;
    newArr[index] = event.target.value;
    props.setterFn(newArr);
  }

  function handleAddIndexInital(event: any) {
    props.setterFn([...props.value, event.target.value]);
  }

  const btnStyle = {
    width: 'max-content',
    padding: 0,
    '&:focus': {
      border: 'none'
    }
  }

  const textStyle = {
    marginBottom: '15px',
  };

  const FormHelperTextStyled = styled(FormHelperText)(() => ({
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: (theme === 'light') ? '#646A7C' : '#A9A9A9',
    marginLeft: 0,
    marginTop: '8px',
  }));

  const ErrorIcon = styled('img')(() => ({
    marginRight: '8px'
  }));

  const GenerateTextField = (props: any) => {
    const items = props.dataParams.value;
    const errors = props.dataParams.error ?? {};
    const errorMsg = props.dataParams.errorMsg ?? {};

    if (items.length === 0) {
      return (
        <Box sx={textStyle}>
          <OutlinedInput
            size={'medium'}
            disabled={props.dataParams.disabled}
            placeholder={props.dataParams.placeholder}
            error={errors['0']}
            type={'text'}
            id={props.dataParams.id}
            fullWidth
            onChange={(e) => handleAddIndexInital(e)}
            defaultValue={props.dataParams.value[0]}
          />
          {(!!errors['0']) && <FormHelperTextStyled error={true}>
            <ErrorIcon src={(window.location.origin) + '/res/hd-ui-kit-images/' + ((theme === 'light') ? 'warning-icon.svg' : 'warning-icon-dark.svg')} />
            {errorMsg['0']}
          </FormHelperTextStyled>}
        </Box>
      );
    }

    const listItems = items.map((item: any, index: number) => (
      <Box sx={textStyle} key={index}>
        <OutlinedInput
          disabled={props.dataParams.disabled}
          placeholder={props.dataParams.placeholder}
          error={errors[`${index}`]}
          type={'text'}
          id={props.dataParams.id}
          fullWidth
          onChange={(e) => onChangeText(index, e)}
          defaultValue={item}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={(event) => removeItem(index, event)}
              >
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        {(!!errors[`${index}`]) && <FormHelperTextStyled error={true}>
          <ErrorIcon src={(window.location.origin) + '/res/hd-ui-kit-images/' + ((theme === 'light') ? 'warning-icon.svg' : 'warning-icon-dark.svg')} />
          {errorMsg[`${index}`]}
        </FormHelperTextStyled>}
      </Box>
    ));

    return <>{listItems}</>;
  };

  return (
    <FormGroup>
      {props.lblText &&
        <FormLabel error={!!props.error}>
          {props.lblText}
        </FormLabel>
      }
      <GenerateTextField dataParams={props} />
      {props.error?.non_field_errors && <FormHelperTextStyled error={true}>
        <ErrorIcon src={(window.location.origin) + '/res/hd-ui-kit-images/' + ((theme === 'light') ? 'warning-icon.svg' : 'warning-icon-dark.svg')} />
        {props.error?.non_field_errors}
      </FormHelperTextStyled>}
      <HDButton
        text={props?.buttonProps?.text}
        startIcon={props?.buttonProps?.startIcon}
        endIcon={props?.buttonProps?.endIcon}
        onClick={handleAddIndex}
        variant={'text'}
        disableRipple={true}
        sx={btnStyle}
      />
    </FormGroup>
  );
};

export default HDMultipleTextFields;
