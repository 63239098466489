import React, { useEffect } from 'react';
import Api from '../Api';
import { useNavigate } from 'react-router-dom';

function Home() {
  let navigate = useNavigate();

  useEffect(() => {
    let isAuth = Api.isAuthorized();
    if (isAuth) {
      // authorized
      // check to see if user needs to complete profile
      Api.getAccount()
        .then((res) => {
          let data = res.response.data;
          if (data?.personal_details?.first_name && data.experience) {
            navigate('/home');
          } else {
            navigate('/create-profile/personal-details');
          }
        })
        .catch((err) => {
          navigate('/register');
        });
    } else {
      // unauthorized
      navigate('/login');
    }
  }, []); // <-- empty array means 'run once'

  return <div></div>;
}

export default Home;
