import { Grid, Box, Typography, styled, useTheme, useMediaQuery } from '@mui/material';
import HDCard from '../../hd-ui-kit/HDCard';
import HDSlider from '../../hd-ui-kit/HDSlider';
import React from 'react';

const ExperienceReportDetails = (props: any) => {
  const themeMode = false;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const WrapperYouAndBenchmark = styled(Box)(() => ({
    display: 'flex',
    justifyContent: isMobile ? 'flex-start' : 'flex-end',
    marginBottom: '30px',
  }));

  const InnerWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'baseline',
  }));

  const RoleLegendWrapper = styled(Box)(() => ({
    height: '18px',
    width: '5%',
  }));

  const RoleLegend = styled(Box)(() => ({
    height: '100%',
    width: '18px',
    borderRadius: '50%',
    background: themeMode ? '#fff' : '#0b0c10',
    border: '1px solid' + (themeMode ? '#0b0c10' : 'transparent'),
    display: 'inline-block',
  }));

  const YouLegendWrap = styled(Box)(() => ({
    display: 'flex',
  }));

  const YouLengendLeft = styled(Box)(() => ({
    background: '#bc7d01',
    height: '16px',
    width: '8px',
    borderRadius: '150px 0 0 150px',
    marginRight: '1px',
  }));

  const YouLengendRight = styled(Box)(() => ({
    background: '#18768c',
    height: '16px',
    width: '8px',
    borderRadius: '0 150px 150px 0',
  }));

  const DescriptionBox = styled(Box)(() => ({
    textAlign: 'left',
    marginBottom: '30px',
  }));

  const descStyleSubtitle = {
    width: isMobile ? '100%' : '50%',
    paddingTop: '15px',
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    color: themeMode ? '#fff' : '#334255',
  };

  const descStyle = {
    paddingTop: '15px',
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    color: themeMode ? '#fff' : '#334255',
  };

  const descWrap = {
    marginTop: '20px',
  };

  const wrapStyling = {
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
    marginBottom: '20px',
  };

  const columnHeadings = {
    marginLeft: '10px',
    fontSize: '22px',
  };

  const cardBackground = {
    background: themeMode ? '#002136' : '#f1f1f1',
    textAlign: 'left',
  };

  const titleStyle = {
    fontSize: '16px',
    fontWeight: 500,
    color: themeMode ? '#fff' : '#002136',
  };

  const titleHeadStyle = {
    fontSize: '31px',
    fontWeight: 500,
  };

  const TypographyStyledYou = {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    paddingLeft: '5px',
    paddingRight: '13px',
  };

  const TypographyStyledBenchMark = {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    paddingLeft: '5px',
  };

  const GenerateCards = (props: any) => {
    const resp = props.resp;
    if (resp.statementChecked === props.condition) {
      return (
        <React.Fragment key={props.indx}>
          <HDCard sx={cardBackground}>
            <Box>
              <Box>
                <Typography variant="h6" sx={titleStyle}>
                  {resp.text}
                </Typography>
              </Box>
              <HDSlider progress={resp.progress} benchMark={resp.benchMark} />
              <Box sx={descWrap}>
                <Typography sx={descStyle}>{resp.description}</Typography>
              </Box>
            </Box>
          </HDCard>
          <br />
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  };

  const report = props.report;
  return (
    <>
      <DescriptionBox>
        <Typography variant="h1" sx={titleHeadStyle}>
          {report.title}
        </Typography>
        <Typography sx={descStyleSubtitle}>
          As you review your areas of impact, consider where you meet the target for the position to which you are being compared, and where you may have opportunities for growth and development. Talk with your coach to explore ways where you may be able to grow your experiences to achieve the
          target(s) for your desired role.
        </Typography>
      </DescriptionBox>

      <WrapperYouAndBenchmark>
        <InnerWrapper>
          <YouLegendWrap>
            <YouLengendLeft>&nbsp;</YouLengendLeft>
            <YouLengendRight>&nbsp;</YouLengendRight>
          </YouLegendWrap>
          <Typography sx={TypographyStyledYou}>You</Typography>
        </InnerWrapper>
        <InnerWrapper>
          <RoleLegendWrapper>
            <RoleLegend>&nbsp;</RoleLegend>
          </RoleLegendWrapper>
          <Typography sx={TypographyStyledBenchMark}>{report.roleName} target</Typography>
        </InnerWrapper>
      </WrapperYouAndBenchmark>

      <Grid container spacing={4} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Grid item xs={12} md={6}>
          <Box sx={wrapStyling}>
            <img alt="Benchmark logo" src="/res/benchMarkIcon.svg" />
            <Typography variant="h2" sx={columnHeadings}>
              Targets you've met {`(${report.skills.filter((item: any) => item.statementChecked === true).length})`}
            </Typography>
          </Box>
          {report.skills.length ? report.skills.map((resp: any, indx: number) => <GenerateCards resp={resp} indx={indx} condition={true} key={indx} />) : ''}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={wrapStyling}>
            <img alt="Development oppurtunity logo" src="/res/developemntIconLight.svg" />
            <Typography variant="h2" sx={columnHeadings}>
              Development Opportunity {`(${report.skills.filter((item: any) => item.statementChecked === false).length})`}
            </Typography>
          </Box>
          {report.skills.length ? report.skills.map((resp: any, indx: number) => <GenerateCards resp={resp} indx={indx} condition={false} key={indx} />) : ''}
        </Grid>
      </Grid>
    </>
  );
};

export default ExperienceReportDetails;
