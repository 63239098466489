export const COUNTRIES = [
  {
    country_id: 52,
    country_value: 'Albania',
  },
  {
    country_id: 48,
    country_value: 'Algeria',
  },
  {
    country_id: 53,
    country_value: 'Andorra',
  },
  {
    country_id: 54,
    country_value: 'Angola',
  },
  {
    country_id: 55,
    country_value: 'Antigua and Barbuda',
  },
  {
    country_id: 1,
    country_value: 'Argentina',
  },
  {
    country_id: 56,
    country_value: 'Armenia',
  },
  {
    country_id: 2,
    country_value: 'Australia',
  },
  {
    country_id: 3,
    country_value: 'Austria',
  },
  {
    country_id: 57,
    country_value: 'Azerbaijan',
  },
  {
    country_id: 58,
    country_value: 'Bahamas',
  },
  {
    country_id: 35,
    country_value: 'Bahrain',
  },
  {
    country_id: 59,
    country_value: 'Bangladesh',
  },
  {
    country_id: 60,
    country_value: 'Barbados',
  },
  {
    country_id: 61,
    country_value: 'Belarus',
  },
  {
    country_id: 4,
    country_value: 'Belgium',
  },
  {
    country_id: 62,
    country_value: 'Belize',
  },
  {
    country_id: 63,
    country_value: 'Benin',
  },
  {
    country_id: 64,
    country_value: 'Bhutan',
  },
  {
    country_id: 65,
    country_value: 'Bolivia',
  },
  {
    country_id: 66,
    country_value: 'Bosnia and Herzegovina',
  },
  {
    country_id: 67,
    country_value: 'Botswana',
  },
  {
    country_id: 33,
    country_value: 'Brazil',
  },
  {
    country_id: 68,
    country_value: 'Brunei',
  },
  {
    country_id: 69,
    country_value: 'Bulgaria',
  },
  {
    country_id: 70,
    country_value: 'Burkina Faso',
  },
  {
    country_id: 72,
    country_value: 'Burundi',
  },
  {
    country_id: 73,
    country_value: 'Cambodia',
  },
  {
    country_id: 74,
    country_value: 'Cameroon',
  },
  {
    country_id: 5,
    country_value: 'Canada',
  },
  {
    country_id: 75,
    country_value: 'Cape Verde',
  },
  {
    country_id: 76,
    country_value: 'Central African Republic',
  },
  {
    country_id: 77,
    country_value: 'Chad',
  },
  {
    country_id: 6,
    country_value: 'Chile',
  },
  {
    country_id: 7,
    country_value: 'China',
  },
  {
    country_id: 8,
    country_value: 'Colombia',
  },
  {
    country_id: 78,
    country_value: 'Comoros',
  },
  {
    country_id: 79,
    country_value: 'Congo (Brazzaville)',
  },
  {
    country_id: 80,
    country_value: 'Congo (Kinshasa)',
  },
  {
    country_id: 81,
    country_value: 'Costa Rica',
  },
  {
    country_id: 82,
    country_value: "Cote d'Ivoire",
  },
  {
    country_id: 83,
    country_value: 'Croatia',
  },
  {
    country_id: 84,
    country_value: 'Cuba',
  },
  {
    country_id: 85,
    country_value: 'Cyprus',
  },
  {
    country_id: 86,
    country_value: 'Czech Republic',
  },
  {
    country_id: 9,
    country_value: 'Denmark',
  },
  {
    country_id: 87,
    country_value: 'Djibouti',
  },
  {
    country_id: 88,
    country_value: 'Dominica',
  },
  {
    country_id: 89,
    country_value: 'Dominican Republic',
  },
  {
    country_id: 91,
    country_value: 'Ecuador',
  },
  {
    country_id: 92,
    country_value: 'Egypt',
  },
  {
    country_id: 93,
    country_value: 'El Salvador',
  },
  {
    country_id: 94,
    country_value: 'Equatorial Guinea',
  },
  {
    country_id: 95,
    country_value: 'Eritrea',
  },
  {
    country_id: 96,
    country_value: 'Estonia',
  },
  {
    country_id: 183,
    country_value: 'Eswatini',
  },
  {
    country_id: 97,
    country_value: 'Ethiopia',
  },
  {
    country_id: 98,
    country_value: 'Fiji',
  },
  {
    country_id: 10,
    country_value: 'Finland',
  },
  {
    country_id: 11,
    country_value: 'France',
  },
  {
    country_id: 99,
    country_value: 'Gabon',
  },
  {
    country_id: 100,
    country_value: 'Gambia',
  },
  {
    country_id: 101,
    country_value: 'Georgia',
  },
  {
    country_id: 12,
    country_value: 'Germany',
  },
  {
    country_id: 102,
    country_value: 'Ghana',
  },
  {
    country_id: 103,
    country_value: 'Greece',
  },
  {
    country_id: 104,
    country_value: 'Grenada',
  },
  {
    country_id: 105,
    country_value: 'Guatemala',
  },
  {
    country_id: 106,
    country_value: 'Guinea',
  },
  {
    country_id: 107,
    country_value: 'Guinea-Bissau',
  },
  {
    country_id: 108,
    country_value: 'Guyana',
  },
  {
    country_id: 109,
    country_value: 'Haiti',
  },
  {
    country_id: 110,
    country_value: 'Holy See',
  },
  {
    country_id: 111,
    country_value: 'Honduras',
  },
  {
    country_id: 112,
    country_value: 'Hong Kong',
  },
  {
    country_id: 113,
    country_value: 'Hungary',
  },
  {
    country_id: 114,
    country_value: 'Iceland',
  },
  {
    country_id: 13,
    country_value: 'India',
  },
  {
    country_id: 115,
    country_value: 'Indonesia',
  },
  {
    country_id: 45,
    country_value: 'Iran',
  },
  {
    country_id: 116,
    country_value: 'Iraq',
  },
  {
    country_id: 117,
    country_value: 'Ireland',
  },
  {
    country_id: 118,
    country_value: 'Israel',
  },
  {
    country_id: 14,
    country_value: 'Italy',
  },
  {
    country_id: 119,
    country_value: 'Jamaica',
  },
  {
    country_id: 15,
    country_value: 'Japan',
  },
  {
    country_id: 43,
    country_value: 'Jordan',
  },
  {
    country_id: 120,
    country_value: 'Kazakhstan',
  },
  {
    country_id: 121,
    country_value: 'Kenya',
  },
  {
    country_id: 122,
    country_value: 'Kiribati',
  },
  {
    country_id: 36,
    country_value: 'Kuwait',
  },
  {
    country_id: 124,
    country_value: 'Kyrgyzstan',
  },
  {
    country_id: 125,
    country_value: 'Laos',
  },
  {
    country_id: 126,
    country_value: 'Latvia',
  },
  {
    country_id: 42,
    country_value: 'Lebanon',
  },
  {
    country_id: 127,
    country_value: 'Lesotho',
  },
  {
    country_id: 128,
    country_value: 'Liberia',
  },
  {
    country_id: 49,
    country_value: 'Libya',
  },
  {
    country_id: 129,
    country_value: 'Liechtenstein',
  },
  {
    country_id: 130,
    country_value: 'Lithuania',
  },
  {
    country_id: 131,
    country_value: 'Luxembourg',
  },
  {
    country_id: 132,
    country_value: 'Macau',
  },
  {
    country_id: 134,
    country_value: 'Madagascar',
  },
  {
    country_id: 135,
    country_value: 'Malawi',
  },
  {
    country_id: 136,
    country_value: 'Malaysia',
  },
  {
    country_id: 137,
    country_value: 'Maldives',
  },
  {
    country_id: 138,
    country_value: 'Mali',
  },
  {
    country_id: 139,
    country_value: 'Malta',
  },
  {
    country_id: 140,
    country_value: 'Marshall Islands',
  },
  {
    country_id: 141,
    country_value: 'Mauritania',
  },
  {
    country_id: 142,
    country_value: 'Mauritius',
  },
  {
    country_id: 17,
    country_value: 'Mexico',
  },
  {
    country_id: 143,
    country_value: 'Micronesia',
  },
  {
    country_id: 144,
    country_value: 'Moldova',
  },
  {
    country_id: 145,
    country_value: 'Monaco',
  },
  {
    country_id: 146,
    country_value: 'Mongolia',
  },
  {
    country_id: 147,
    country_value: 'Montenegro',
  },
  {
    country_id: 50,
    country_value: 'Morocco',
  },
  {
    country_id: 148,
    country_value: 'Mozambique',
  },
  {
    country_id: 71,
    country_value: 'Myanmar',
  },
  {
    country_id: 149,
    country_value: 'Namibia',
  },
  {
    country_id: 150,
    country_value: 'Nauru',
  },
  {
    country_id: 151,
    country_value: 'Nepal',
  },
  {
    country_id: 18,
    country_value: 'Netherlands',
  },
  {
    country_id: 153,
    country_value: 'New Zealand',
  },
  {
    country_id: 154,
    country_value: 'Nicaragua',
  },
  {
    country_id: 155,
    country_value: 'Niger',
  },
  {
    country_id: 156,
    country_value: 'Nigeria',
  },
  {
    country_id: 133,
    country_value: 'North Macedonia',
  },
  {
    country_id: 157,
    country_value: 'Norway',
  },
  {
    country_id: 37,
    country_value: 'Oman',
  },
  {
    country_id: 31,
    country_value: 'Other',
  },
  {
    country_id: 158,
    country_value: 'Pakistan',
  },
  {
    country_id: 159,
    country_value: 'Palau',
  },
  {
    country_id: 160,
    country_value: 'Palestine State',
  },
  {
    country_id: 161,
    country_value: 'Panama',
  },
  {
    country_id: 162,
    country_value: 'Papua New Guinea',
  },
  {
    country_id: 163,
    country_value: 'Paraguay',
  },
  {
    country_id: 19,
    country_value: 'Peru',
  },
  {
    country_id: 164,
    country_value: 'Philippines',
  },
  {
    country_id: 20,
    country_value: 'Poland',
  },
  {
    country_id: 21,
    country_value: 'Portugal',
  },
  {
    country_id: 38,
    country_value: 'Qatar',
  },
  {
    country_id: 165,
    country_value: 'Romania',
  },
  {
    country_id: 34,
    country_value: 'Russia',
  },
  {
    country_id: 166,
    country_value: 'Rwanda',
  },
  {
    country_id: 167,
    country_value: 'Saint Kitts and Nevis',
  },
  {
    country_id: 168,
    country_value: 'Saint Lucia',
  },
  {
    country_id: 169,
    country_value: 'Saint Vincent and the Grenadines',
  },
  {
    country_id: 170,
    country_value: 'Samoa',
  },
  {
    country_id: 171,
    country_value: 'San Marino',
  },
  {
    country_id: 172,
    country_value: 'Sao Tome and Principe',
  },
  {
    country_id: 39,
    country_value: 'Saudi Arabia',
  },
  {
    country_id: 173,
    country_value: 'Senegal',
  },
  {
    country_id: 174,
    country_value: 'Serbia',
  },
  {
    country_id: 175,
    country_value: 'Seychelles',
  },
  {
    country_id: 176,
    country_value: 'Sierra Leone',
  },
  {
    country_id: 22,
    country_value: 'Singapore',
  },
  {
    country_id: 177,
    country_value: 'Slovakia',
  },
  {
    country_id: 178,
    country_value: 'Slovenia',
  },
  {
    country_id: 179,
    country_value: 'Solomon Islands',
  },
  {
    country_id: 180,
    country_value: 'Somalia',
  },
  {
    country_id: 23,
    country_value: 'South Africa',
  },
  {
    country_id: 16,
    country_value: 'South Korea',
  },
  {
    country_id: 24,
    country_value: 'Spain',
  },
  {
    country_id: 181,
    country_value: 'Sri Lanka',
  },
  {
    country_id: 46,
    country_value: 'Sudan',
  },
  {
    country_id: 182,
    country_value: 'Suriname',
  },
  {
    country_id: 32,
    country_value: 'Sweden',
  },
  {
    country_id: 25,
    country_value: 'Switzerland',
  },
  {
    country_id: 44,
    country_value: 'Syria',
  },
  {
    country_id: 26,
    country_value: 'Taiwan',
  },
  {
    country_id: 184,
    country_value: 'Tajikistan',
  },
  {
    country_id: 185,
    country_value: 'Tanzania',
  },
  {
    country_id: 51,
    country_value: 'Thailand',
  },
  {
    country_id: 90,
    country_value: 'Timor-Leste',
  },
  {
    country_id: 186,
    country_value: 'Togo',
  },
  {
    country_id: 187,
    country_value: 'Tonga',
  },
  {
    country_id: 188,
    country_value: 'Trinidad and Tobago',
  },
  {
    country_id: 47,
    country_value: 'Tunisia',
  },
  {
    country_id: 27,
    country_value: 'Turkey',
  },
  {
    country_id: 189,
    country_value: 'Turkmenistan',
  },
  {
    country_id: 190,
    country_value: 'Tuvalu',
  },
  {
    country_id: 191,
    country_value: 'Uganda',
  },
  {
    country_id: 192,
    country_value: 'Ukraine',
  },
  {
    country_id: 40,
    country_value: 'United Arab Emirates',
  },
  {
    country_id: 28,
    country_value: 'United Kingdom',
  },
  {
    country_id: 29,
    country_value: 'United States of America',
  },
  {
    country_id: 193,
    country_value: 'Uruguay',
  },
  {
    country_id: 194,
    country_value: 'Uzbekistan',
  },
  {
    country_id: 195,
    country_value: 'Vanuatu',
  },
  {
    country_id: 30,
    country_value: 'Venezuela',
  },
  {
    country_id: 196,
    country_value: 'Vietnam',
  },
  {
    country_id: 41,
    country_value: 'Yemen',
  },
  {
    country_id: 197,
    country_value: 'Zambia',
  },
  {
    country_id: 198,
    country_value: 'Zimbabwe',
  },
];
