import {
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  styled,
  FormLabel,
  useTheme,
} from '@mui/material';
import * as React from 'react';

export interface HDTextFieldTypes {
  variant?: string | any;
  size?: string | any;
  disabled?: boolean;
  error?: boolean;
  disableRipple?: boolean;
  placeholder?: string;
  type: string;
  id?: string;
  name?: string;
  lblText?: string;
  errorMsg?: string;
  belowHelperTxt?: string;
  aboveHelperTxt?: string;
  sx?: Object;
  value?: any;
  onChange?: any;
  onClick?: any;
  readOnly?:boolean;
}

interface State {
  showPassword: boolean;
}

const HDTextFields = (props: HDTextFieldTypes) => {
  const theme = useTheme()?.palette.mode;
  const [values, setValues] = React.useState<State>({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const FormHelperTextStyled = styled(FormHelperText)(() => ({
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme === 'light' ? '#646A7C' : '#A9A9A9',
    marginLeft: 0,
    marginTop: '8px',
  }));

  const FormHelperTextAboveStyled = styled(FormHelperText)(() => ({
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme === 'light' ? '#646A7C' : '#A9A9A9',
    marginLeft: 0,
    marginTop: 0,
    marginBottom: '10px',
  }));

  const ErrorIcon = styled('img')(() => ({
    marginRight: '8px'
  }));

  const StyledIconBtn = { marginRight: '-5px' };

  return (
    <FormControl fullWidth={true} sx={props.sx}>
      {props.lblText && <FormLabel htmlFor={props.id} error={props.error}>{props.lblText}</FormLabel>}
      {props.aboveHelperTxt && (
        <FormHelperTextAboveStyled>{props.aboveHelperTxt}</FormHelperTextAboveStyled>
      )}
      <OutlinedInput
        readOnly={props.readOnly}
        error={props.error}
        size={props.size}
        disabled={props.disabled}
        placeholder={props.placeholder}
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onClick={props.onClick}
        fullWidth
        type={
          props.type === 'password' && values.showPassword
            ? 'text'
            : props.type !== 'password'
              ? 'text'
              : 'password'
        }
        endAdornment={
          props.type === 'password' && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                data-testid="/VisibilityIcon/"
                sx={StyledIconBtn}
              >
                {theme === 'light' ? (
                  values.showPassword ? (
                    <img alt='Show password logo' src="../res/hd-ui-kit-images/unseeLight.svg" />
                  ) : (
                    <img alt='Hide password logo' src="../res/hd-ui-kit-images/seeLight.svg" />
                  )
                ) : values.showPassword ? (
                  <img alt='Show password logo' src="../res/hd-ui-kit-images/unseeDark.svg" />
                ) : (
                  <img alt='Hide password logo' src="../res/hd-ui-kit-images/seeDark.svg" />
                )}
              </IconButton>
            </InputAdornment>
          )
        }
      />
      {props.error && <FormHelperTextStyled error={true}>
        <ErrorIcon src={(window.location.origin) + '/res/hd-ui-kit-images/' + ((theme === 'light') ? 'warning-icon.svg' : 'warning-icon-dark.svg')} />
        {props.errorMsg}
      </FormHelperTextStyled>}
      {props.belowHelperTxt && <FormHelperTextStyled>{props.belowHelperTxt}</FormHelperTextStyled>}
    </FormControl>
  );
};

export default HDTextFields;
