import { ActionType } from './types';
import { initialState } from './index';
import Api from '../Api';

const Reducer = (state: any, action: ActionType): any => {
  switch (action.type) {
    case 'SET_ACCOUNT':
      return {
        ...state,
        account: action.payload,
        isAuthenticated: Api.isAuthorized(),
      };
    case 'SET_PROFILE':
      return {
        ...state,
        profile: action.payload,
      };
    case 'SET_CAPABILITY_REPORT':
      return {
        ...state,
        capabilityReport: action.payload,
      };
    case 'SET_IMPACT_REPORT':
      return {
        ...state,
        impactReport: action.payload,
      };
    case 'SET_ASSESSMENT_STATUS':
      return {
        ...state,
        assessmentStatus: action.payload,
      };
    case 'SET_CAPABILITY_REPORT_BLOB':
      return {
        ...state,
        capabilityReportBlob: action.payload,
      };
    case 'SET_IMPACT_REPORT_BLOB':
      return {
        ...state,
        impactReportBlob: action.payload,
      };
    case 'SET_SUBSCRIPTION_STATUS':
      return {
        ...state,
        subscriptionStatus: action.payload,
      };
    case 'SET_SESSIONS_DATA':
      return {
        ...state,
        sessionsData: action.payload,
      };
    case 'SET_MYCOACH_DATA':
      return {
        ...state,
        myCoachData: action.payload,
      };
    case 'AUTHENTICATE_USER':
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case 'PURGE_STATE':
      return initialState;
    default:
      return state;
  }
};

export default Reducer;
