import { createTheme, useThemeProps } from '@mui/material';
import AventaMedium from '../HDFonts/AventaFont/Aventa-Medium.woff2';
import AvenirLight from '../HDFonts/AvenirFont/AvenirLTProLight.otf';
import './HDTheme.css';

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography: {
        fontFamily: ['Aventa', 'Avenir'].join(','),
    },
    components: {
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    color: '#cccccc',
                    fontWeight: 300,
                    fontFamily: 'Avenir',
                },
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    background: '#0B0C10'
                    //background: 'radial-gradient(97.1% 150.22% at 100% 0%, #002136 0%, #002136 28.91%, rgba(7, 29, 45, 0.22) 63.28%, rgba(11, 12, 14, 0) 83.07%), #0B0C10',
                    //backgroundRepeat: 'no-repeat',
                    //height: 'calc(100vh)',
                },
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0, 33, 54, 0.7)',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    background: '#0B0C10',
                    border: '1px solid rgba(255, 255, 255, 0.2)',
                    padding: '5%',
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Aventa',
                    fontSize: '15px',
                    fontWeight: 600,
                },
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    fontFamily: 'Aventa',
                    fontSize: '15px',
                    fontWeight: 600,
                    color: '#fff',
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    fontSynthesis: 'none',
                    color: '#002136',
                    border: '1px solid #616164',
                    borderRadius: '100px',
                    fontFamily: 'Aventa',
                    fontSize: '15px',
                    fontWeight: 600,
                    textTransform: 'none',
                    padding: '10px 25px',
                    boxShadow: 'none',
                    zIndex: 0,
                    letterSpacing: '0.5px',
                    '.MuiButton-endIcon': {
                        transition: 'transform 0.3s ease-out',
                    },
                    ...(ownerState.variant === 'contained' && {
                        backgroundColor: '#F1F1F1',
                        color: '#002136',
                        border: 'none',
                        '&:hover': {
                            backgroundColor: '#F1F1F1',
                            boxShadow: 'none',
                            ...(ownerState.showAnimation && {
                                '.MuiButton-endIcon': {
                                    webkitTransform: 'translateX(6px)',
                                    mozTransform: 'translateX(6px)',
                                    transform: 'translateX(6px)',
                                    transition: 'transform 0.3s ease-out',
                                }
                            })
                        },
                        '&:focus': {
                            border: '1px solid #18768C',
                            background: 'transparent !important',
                        },
                        '&:focus:before': {
                            content: '""',
                            height: '89%',
                            width: '96%',
                            position: 'absolute',
                            borderRadius: 'inherit',
                            background: '#F1F1F1',
                            zIndex: '-1',
                        },
                        '&:disabled': {
                            background: '#334255',
                            color: '#0B0C10',
                        }
                    }),
                    ...(ownerState.variant === 'text' && {
                        color: '#66FCFC',
                        border: '1px solid transparent',
                        '&:hover': {
                            color: '#12ABAB',
                            background: 'none',
                            outline: 'none',
                            textDecoration: 'underline',
                        },
                        '&:focus': {
                            color: '#66FCFC',
                            borderRadius: 0,
                            border: '1px solid #66FCFC',
                            outline: 'none',
                            textDecoration: 'none',
                        },
                        '&:active': {
                            outline: 'none',
                            background: 'transparent',
                        },
                        '&:disabled': {
                            color: '#334255'
                        }
                    }),
                    ...(ownerState.variant === 'outlined' && {
                        color: '#fff',
                        border: '1px solid #9D9E9F',
                        '&:hover': {
                            background: 'none',
                            border: '1px solid #fff',
                        },
                        '&:focus': {
                            border: '1px solid #18768C',
                        },
                        '&:focus:before': {
                            content: '""',
                            height: '86%',
                            width: '96%',
                            position: 'absolute',
                            borderRadius: 'inherit',
                            border: '1px solid #6D6D70',
                        },
                        '&:disabled': {
                            border: '1px solid #3C3D40',
                            color: '#334255',
                        }
                    })
                }),
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: '#0B0C10',
                    width: '100%',
                    height: '100%',
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    background: '#0B0C10'
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    background: 'linear-gradient(360deg, rgba(0,33,54,1) 0%, rgba(24,63,88,1) 50%, rgba(25,126,149,1) 100%)',
                    padding: '24px',
                    borderRadius: '6px',
                    boxShadow: 'none',
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    '&:last-child': {
                        paddingBottom: 0,
                    },
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    border: '1px solid #A9A9A9',
                    color: '#A9A9A9',
                    fontFamily: 'Avenir',
                    fontWeight: 300,
                    fontSize: '16px',
                    borderRadius: '10px',
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '.MuiOutlinedInput-input::placeholder': {
                        color: '#A9A9A9',
                        opacity: 1,
                        fontWeight: 300,
                    },
                    '.MuiOutlinedInput-input': {
                        color: '#FFFFFF',
                        fontFamily: 'Avenir',
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: '24px',
                        zIndex: 9,
                        borderColor: '#A9A9A9',
                        padding: '12px 16px',
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        borderRadius: '8px'
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#2CDADA'
                    },
                    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#A9A9A9',
                        backgroundColor: '#1D1F26',
                        color: '#A9A9A9',
                        opacity: 1,
                        zIndex: 0,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#2CDADA'
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E449BD',
                        borderWidth: '2px'
                    },
                },
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '.MuiFormLabel-root.Mui-focused': {
                        color: '#A9A9A9'
                    },
                    '.MuiFormLabel-root': {
                        fontFamily: 'Avenir',
                        fontWeight: 300,
                        fontSize: '16px',
                        lineHeight: '24px',
                        marginBottom: '10px',
                        color: '#A9A9A9'
                    },
                    '.MuiFormLabel-root.Mui-error': {
                        color: '#E449BD',
                    },
                    '.MuiFormHelperText-root.Mui-error': {
                        color: '#E449BD',
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#CCCCCC',
                    padding: '16px',
                    fontFamily: 'Aventa',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#002136',
                    maxWidth: '400px',
                    letterSpacing: '0.02em',
                    borderRadius: '10px',
                },
                tooltipArrow: {
                    backgroundColor: '#646A7C',
                }
            }
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    '.MuiFormLabel-root.Mui-focused': {
                        color: '#A9A9A9'
                    },
                    '.MuiFormLabel-root': {
                        fontFamily: 'Avenir',
                        fontWeight: 300,
                        fontSize: '16px',
                        lineHeight: '24px',
                        marginBottom: '10px',
                        color: '#A9A9A9'
                    },
                    '.MuiFormLabel-root.Mui-error': {
                        color: '#E449BD',
                    },
                    '.MuiFormHelperText-root.Mui-error': {
                        color: '#E449BD',
                    }
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    background: '#0B0C10 !important',
                },
            }
        },
    },
});

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
    typography: {
        fontFamily: ['Aventa', 'Avenir'].join(','),
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    //background: 'radial-gradient(80% 90% at 100% 9.36%, #E3ECEF 0%, rgba(241, 241, 241, 0) 100%), #FFFFFF;',
                    //backgroundRepeat: 'no-repeat',
                }
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(241, 241, 241, 0.7)',
                },
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    color: '#334255',
                    fontWeight: 300,
                    fontFamily: 'Avenir',
                },
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Aventa',
                    fontSize: '15px',
                    fontWeight: 600,
                },
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    fontFamily: 'Aventa',
                    fontSize: '15px',
                    fontWeight: 600,
                    color: '#002136',
                },
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#fff',
                    backgroundImage: '#fff',
                    border: '1px solid rgba(11, 12, 16, 0.2)',
                    padding: '5%',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    fontSynthesis: 'none',
                    color: '#fff',
                    border: '1px solid #616164',
                    borderRadius: '100px',
                    fontFamily: 'Aventa',
                    fontSize: '15px',
                    fontWeight: 600,
                    textTransform: 'none',
                    padding: '10px 25px',
                    boxShadow: 'none',
                    zIndex: 0,
                    letterSpacing: '0.5px',
                    '.MuiButton-endIcon': {
                        transition: 'transform 0.3s ease-out',
                    },
                    '&:disabled': {
                        background: '#CCCCCC',
                        color: '#A9A9A9',
                        border: 'none',
                    },
                    ...(ownerState.variant === 'contained' && {
                        backgroundColor: '#334255',
                        color: '#fff',
                        border: 'none',
                        '&:hover': {
                            backgroundColor: '#334255',
                            boxShadow: 'none',
                            ...(ownerState.showAnimation && {
                                '.MuiButton-endIcon': {
                                    webkitTransform: 'translateX(6px)',
                                    mozTransform: 'translateX(6px)',
                                    transform: 'translateX(6px)',
                                    transition: 'transform 0.3s ease-out',
                                }
                            })
                        },
                        '&:focus': {
                            border: '1px solid #334255',
                            background: 'transparent !important',
                        },
                        '&:focus:before': {
                            content: '""',
                            height: '89%',
                            width: '96%',
                            position: 'absolute',
                            borderRadius: 'inherit',
                            background: '#334255',
                            zIndex: '-1',
                        },
                    }),
                    ...(ownerState.variant === 'text' && {
                        color: '#18768C',
                        border: '1px solid transparent',
                        '&:hover': {
                            color: '#00566A',
                            background: 'none',
                            textDecoration: 'underline',
                        },
                        '&:focus': {
                            color: '#18768C',
                            border: 'none',
                            textDecoration: 'underline',
                        },
                        '&:active': {
                            background: 'transparent',
                            border: 'none',
                            textDecoration: 'underline',
                        },
                        '&:disabled': {
                            color: '#A9A9A9',
                            background: 'none',
                        }
                    }),
                    ...(ownerState.variant === 'outlined' && {
                        color: '#0B0C10',
                        border: '1px solid #646A7C',
                        '&:hover': {
                            border: '1px solid #002136',
                            background: 'none',
                        },
                        '&:focus': {
                            border: '1px solid #18768C',
                        },
                        '&:focus:before': {
                            content: '""',
                            height: '86%',
                            width: '96%',
                            position: 'absolute',
                            borderRadius: 'inherit',
                            border: '1px solid #646A7C',
                        },
                    })
                }),
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: '#FFF',
                    width: '100%',
                    height: '100%',
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: '#FFF',
                    color: '#002136',
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    background: '#18768C',
                    padding: '24px',
                    borderRadius: '6px',
                    boxShadow: 'none',
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    '&:last-child': {
                        paddingBottom: 0,
                    },
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    color: '#646A7C',
                    fontFamily: 'Avenir',
                    fontWeight: 300,
                    fontSize: '16px',
                    borderRadius: '10px',
                },
                icon: {
                    color: '#002136',
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '.MuiOutlinedInput-input::placeholder': {
                        color: '#646A7C',
                        opacity: 1,
                        fontWeight: 300,
                    },
                    '.MuiOutlinedInput-input': {
                        color: '#002136',
                        fontFamily: 'Avenir',
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: '24px',
                        padding: '12px 16px',
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        borderRadius: '8px',
                        borderColor: '#646A7C'
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#18768C'
                    },
                    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#646A7C',
                        backgroundColor: '#F1F1F1',
                        color: '#646A7C',
                        opacity: 1,
                        zIndex: 0,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#18768C'
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#C02195',
                        borderWidth: '2px'
                    },
                },
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '.MuiFormLabel-root.Mui-focused': {
                        color: '#646A7C'
                    },
                    '.MuiFormLabel-root': {
                        fontFamily: 'Avenir',
                        fontWeight: 300,
                        fontSize: '16px',
                        lineHeight: '24px',
                        marginBottom: '10px',
                        color: '#646A7C',
                    },
                    '.MuiFormLabel-root.Mui-error': {
                        color: '#C02195',
                    },
                    '.MuiFormHelperText-root.Mui-error': {
                        color: '#C02195',
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#646A7C',
                    padding: '16px',
                    fontFamily: 'Aventa',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#fff',
                    maxWidth: '400px',
                    letterSpacing: '0.02em',
                    borderRadius: '10px',
                },
                tooltipArrow: {
                    backgroundColor: '#646A7C',
                }
            }
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    '.MuiFormLabel-root.Mui-focused': {
                        color: '#646A7C'
                    },
                    '.MuiFormLabel-root': {
                        fontFamily: 'Avenir',
                        fontWeight: 300,
                        fontSize: '16px',
                        lineHeight: '24px',
                        marginBottom: '10px',
                        color: '#646A7C',
                    },
                    '.MuiFormLabel-root.Mui-error': {
                        color: '#C02195',
                    },
                    '.MuiFormHelperText-root.Mui-error': {
                        color: '#C02195',
                    }
                }
            }
        }
    },
});
