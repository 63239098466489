import React from 'react';
import { styled } from '@mui/material';
import { Box, Typography, Divider, useTheme, useMediaQuery } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { styledTitle, styledDescrition, styledReadMoreText, styledDot, styledDivider } from './CoachStyles';
import HDButton from '../hd-ui-kit/HDButton';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';

export const CoachBioDescrition = (data: any, isMobile: boolean, isDarkTheme: boolean, isReadMore: any, toggleReadMore: any, pageTpe: string, firstName?: string, props?: any) => {
  const StyledDividerSmall = styled(Divider)(() => ({
    color: isDarkTheme ? 'rgba(255, 255, 255, 0.2)' : 'rgba(100, 106, 124, 0.2)',
    marginTop: '7px',
  }));

  const StyledBoxMain = { display: 'flex', flexDirection: isMobile ? 'column' : 'none', marginTop: '30px' };
  const StyledBoxForTitle = { marginLeft: '30px', width: '100%' };
  const StyledDescritionBox = { display: 'flex', marginTop: '10px' };
  const styledMarginBox = { marginTop: '10px' };
  const StyledMobileMain = { display: 'flex', flexDirection: 'row' };
  const StyleMobileTitle = { marginLeft: '5px' };
  const StyledBtnBox = { marginTop: '30px' };
  const StyledBtn = {
    backgroundColor: isDarkTheme ? '#F1F1F1' : '#334255',
    color: isDarkTheme ? '#002136' : '#FFFFFF',
  };

  const ArrowForwardSharpIconStyled = styled(ArrowForwardSharpIcon)(() => ({
    color: isDarkTheme ? '#18768C' : '#66FCFC',
  }));


  return (data?.map((item: any, index: any) => {
    if (index === 0) {
      return (
        <Box sx={StyledBoxMain}>
          <Box sx={isMobile ? StyledMobileMain : {}}>
            <Box>
              <img alt={item.alt} src={item.imgUrl} />
            </Box>
            {isMobile && (
              <Box sx={StyleMobileTitle}>
                <Typography sx={styledTitle(isDarkTheme)}>{item.title}</Typography>
              </Box>
            )}
          </Box>

          <Box sx={isMobile ? {} : StyledBoxForTitle}>
            {!isMobile && (
              <Box>
                <Typography sx={styledTitle(isDarkTheme)}>{item.title}</Typography>
              </Box>
            )}
            <Box sx={styledMarginBox}>
              <Typography sx={{...styledDescrition(isDarkTheme), whiteSpace: 'pre-line'}}> {isReadMore && item?.des?.length > 150 ? `${item?.des?.slice(0, 150)}...` : item?.des}</Typography>
            </Box>
            {item?.des?.length > 150 && (
              <Box>
                <Typography sx={styledReadMoreText(isDarkTheme)} onClick={() => toggleReadMore()}>{isReadMore ? 'Read more' : 'Show less'}</Typography>
              </Box>
            )}
            <Divider sx={styledDivider(isDarkTheme)} />
          </Box>
        </Box>
      );
    } else {
      return (
        <>
          {' '}
          <Box sx={StyledBoxMain}>
            <Box sx={isMobile ? StyledMobileMain : {}}>
              <Box>
                <img alt={item.alt} src={item.imgUrl} />
              </Box>
              {isMobile && (
                <Box sx={StyleMobileTitle}>
                  <Typography sx={styledTitle(isDarkTheme)}>{item.title}</Typography>
                </Box>
              )}
            </Box>

            <Box sx={isMobile ? {} : StyledBoxForTitle}>
              {!isMobile && (
                <Box>
                  <Typography sx={styledTitle(isDarkTheme)}>{item.title}</Typography>
                </Box>
              )}
              {item.des.map((description: any) => {
                return (
                  <>
                    {' '}
                    <Box sx={StyledDescritionBox}>
                      <FiberManualRecordIcon sx={styledDot(isDarkTheme)}> </FiberManualRecordIcon>
                      <Typography sx={styledDescrition(isDarkTheme)}>{description}</Typography>
                    </Box>
                  </>
                );
              })}

              {index == 1 && <StyledDividerSmall />}
              {(pageTpe === 'coachBio') ?
                (index === 2) ? (
                  <Box sx={StyledBtnBox}>
                    <HDButton
                      showAnimation={true}
                      variant={'contained'}
                      sx={StyledBtn}
                      endIcon={<ArrowForwardSharpIconStyled />}
                      text={`Select ${firstName}`}
                      onClick={() => props.nextStep(item)}
                    />
                  </Box>
                ) : ''
                : <></>
              }
            </Box>
          </Box>
        </>
      );
    }
  }));
};