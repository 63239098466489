import HDModal from '../../hd-ui-kit/HDModal';
import React, { useEffect, useState, useCallback } from 'react';
import HDButton from '../../hd-ui-kit/HDButton';
import HDNotificationBanner from '../../hd-ui-kit/HDNotificationBanner';
import HDDropDown from '../../hd-ui-kit/HDDropDown';
import { FormGroup, List, ListItem } from '@mui/material';
import { HDRadio } from '../../hd-ui-kit/HDRadio';
import HDErrorBanner from '../../hd-ui-kit/HDErrorBanner';
import Api from '../../Api';
import Loader from '../../commons/Loader';
import { Box, Divider, styled, Grid, Typography, useTheme } from '@mui/material';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';
import { useLocation } from 'react-router-dom';
import { reactGAEvents } from '../../commons/commonFunctions';
import HDSpinner from '../../hd-ui-kit/HDSpinner';
import Link from '@mui/material/Link';



const btnStyleStartConfirm = {
  fontWeight: 600,
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontSize: '15px',
  lineHeight: '22px',
  '&:focus': {
    backgroundColor: '#00566A',
  }
};

const StyledListItem = styled(ListItem)(() => ({
  display: 'block',
  paddingTop: '0px',
  paddingLeft: '0px',
  paddingRight: '0px',
}));

const LightTheme = styled(Box)(() => ({
  background: '#fff',
}));

const ConatinerBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const MainBox = styled(Box)(() => ({
  width: '100%',
}));

const StyledDivider = styled(Divider)(() => ({
  width: '100%',
  paddingTop: '30px',
}));

const StyledDropDownOptions = styled(Box)(() => ({
  paddingTop: '20px',
}));

const StyledBannerBox = styled(Box)(() => ({
  paddingBottom: '20px',
}));

const StyledChangeHistoryOutlinedIcon = styled(ChangeHistoryOutlinedIcon)(() => ({
  transform: 'rotate(90deg)',
  fontSize: '15px !important',
}));

const RoleModalWrapper = (props: any) => {
  const theme = useTheme();
  const { open, setOpen, setBusinessRoleId, setLoader } = props;
  const { pathname } = useLocation();
  const category = pathname?.includes('my-career') ? 'my-career' : 'my-reports';
  const [roleOptions, setRoleOptions] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [selectedDropOption, setSelectedDropOption] = useState('');
  const [businessFunctions, setBusinessFunctions] = useState([]);
  const [errorText, setErrorText] = useState<string>(
    'Please fill out all required fields in order to continue.'
  );
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const [isSubmit, setIsSubmit] = useState(false);

  const [confirmModal, setConfirmModal] = useState({
    title: ' ',
    description: '',
    checked: false,
    fromConfirmRole: false,
  });

  const btnStyleModal = {
    fontWeight: 400,
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '22px',
    cursor: 'pointer',
  };

  const getBusinessFunctionsData = useCallback(() => {
    Api.getBusinessFunctions()
      .then((res) => {
        if (res?.response?.status == 200) {
          let list: any = [];
          res?.response?.data?.data?.forEach((item: any) => {
            list.push({ keyValue: item.id, keyName: item.fieldValue });
          });
          setBusinessFunctions(list);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleOptionClick = (value: any) => {
    setSelectedDropOption(value);
    reactGAEvents(category, `select-function-${value}`);
    setLoading(true);
    Api.getRoleProfilesById(value)
      .then((res) => {
        if (res?.response?.status == 200) {
          let list: any = [];
          res?.response?.data?.data?.forEach((item: any) => {
            list.push({
              ...item,
              label: item.name,
              checked: false,
              hasError: false,
            });
          });
          if (list.length) {
            setRoleOptions(list);
            setLoading(false);
            setError(false);
          } else {
            setRoleOptions(list);
            setErrorText(`We don't have any roles associated with that function.
              Please select another function.`);
            setLoading(false);
            setError(true);
          }
        } else {
          setErrorText(`We are having trouble retrieving the roles associated with that function.
            Please try again.`);
          setError(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    if (value == '') {
      setRoleOptions([]);
    }
  };

  const handleRoleSelection = (item: any, index: any) => {
    let newRoleOptions = [...roleOptions];
    newRoleOptions.forEach((i, key) => {
      if (key === index) {
        i.checked = true;
        i.hasError = false;
      } else {
        i.checked = false;
        i.hasError = false;
      }
    });
    reactGAEvents(category, `select-target-role-${item?.name}`);
    setError(false);
    setRoleOptions(newRoleOptions);
  };

  const handleValidation = () => {
    let isValid = false;
    let newRoleOptions = [...roleOptions];
    newRoleOptions.forEach((option) => {
      if (option.checked == true) isValid = true;
    });
    if (!isValid) {
      let newRoleOptions = [...roleOptions];
      newRoleOptions.forEach((i) => {
        i.hasError = true;
      });
      setRoleOptions(newRoleOptions);
    }
    return isValid;
  };

  const handleConfirmRole = () => {
    reactGAEvents(category, 'submit-role-yes');
    setIsSubmit(true);
    if (confirmModal.fromConfirmRole) {
      let businessRole: any = {};
      let businessFunction = {};
      roleOptions.some((item) => {
        if (item.checked == true) {
          businessRole = { name: item.name, id: item.id };
          return true;
        }
        return false;
      });

      businessFunctions.some((i) => {
        if (i.keyValue == selectedDropOption) {
          businessFunction = { name: i.keyName, id: i.keyValue };
          return true;
        }
        return false;
      });

      let payload = {
        clientName: 'Edge',
        businessRole: businessRole,
        businessFunction: businessFunction,
        businessRoleID: businessRole?.id
      };

      // const timeout = setTimeout(() => {
      //   window.location.reload();
      // }, 60000);

      Api.postSelectedRole(payload)
        .then((res: any) => {
          //clearTimeout(timeout);
          setOpen(false);
          setSelectedDropOption('');
          setRoleOptions([]);
          setConfirmModal({
            title: '',
            description: '',
            checked: false,
            fromConfirmRole: false,
          });
          setBusinessRoleId(businessRole);
          //window.location.reload();
        })
        .catch((err) => {
          //clearTimeout(timeout);
          setOpen(false);
          setSelectedDropOption('');
          setRoleOptions([]);
          setConfirmModal({
            title: '',
            description: '',
            checked: false,
            fromConfirmRole: false,
          });
          //window.location.reload();
        });
    } else {
      setIsSubmit(false);
      setOpen(false);
      setSelectedDropOption('');
      setRoleOptions([]);
      setConfirmModal({
        title: '',
        description: '',
        checked: false,
        fromConfirmRole: false,
      });
    }
  };

  useEffect(() => {
    getBusinessFunctionsData();
  }, []);

  if (!businessFunctions.length) return <div />;

  const StyledLoadingText = styled(Typography)(() => ({
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '26px',
    marginLeft: '10px',
    color: isDarkTheme ? '#CCCCCC' : '#002136',
  }));

  const StyledLoadingBox = { display: 'flex', marginTop: '25px', width: '100%' };
  const StyledSpinnerBox = { marginTop: '5px' };

  return (
    <>
      <HDModal
        open={open}
        handleModal={() => {
          reactGAEvents(category, 'close-modal-x');
          setConfirmModal({
            title: 'You have to select a role before taking your assessments.',
            description: 'Are you sure you want to close this window?',
            checked: true,
            fromConfirmRole: false,
          });
        }}
        title="Select a target role"
        description={`In your reports, we'll show the targets for this role —
      so you can see how you compare.`}
      >
        <>
          <ConatinerBox>
            <MainBox>
              <HDNotificationBanner
                content="This role cannot be changed once you start an assessment."
                variant="filled"
                severity="info"
              />
            </MainBox>
            <StyledDivider />
            <Grid container>
              <Grid item xs={12} md={12} lg={7} pt={2}>
                <HDDropDown
                  value={selectedDropOption}
                  fieldLabel={'Select a function to see related roles.'}
                  firstFieldLabel={'Select a function'}
                  dropDownItem={businessFunctions}
                  fullWidth={true}
                  disabled={false}
                  error={false}
                  defaultValue=""
                  onChange={(e) => handleOptionClick(e.target.value)}
                />
              </Grid>

              {loading &&
                <Box sx={StyledLoadingBox}>
                  <Box sx={StyledSpinnerBox}>
                    <HDSpinner size={'la-sm'} />
                  </Box>
                  <StyledLoadingText>Please wait...</StyledLoadingText>
                </Box>
              }
            </Grid>
            <StyledDropDownOptions>
              {error && !roleOptions?.length && (
                <>
                  <StyledBannerBox>
                    <HDErrorBanner errorText={errorText} variant={'filled'} severity={'warning'} />
                  </StyledBannerBox>
                  <HDButton
                    text={'Retry'}
                    onClick={() => {
                      handleOptionClick(selectedDropOption);
                    }}
                    variant={'contained'}
                    size={'medium'}
                    disabled={false}
                    disableRipple={true}
                    sx={btnStyleStartConfirm}
                  />
                </>
              )}
            </StyledDropDownOptions>

            {roleOptions && roleOptions?.length ? (
              <StyledDropDownOptions>
                <FormGroup>
                  <List>
                    {roleOptions.map((item: any, index: number) => (
                      <StyledListItem key={`radio_${index}`}>
                        <HDRadio {...item} onChange={() => handleRoleSelection(item, index)} showBackground={true} />
                      </StyledListItem>
                    ))}
                  </List>
                </FormGroup>
                {error && (
                  <StyledBannerBox>
                    <HDErrorBanner errorText={errorText} variant={'filled'} severity={'warning'} />
                  </StyledBannerBox>
                )}

                <HDButton
                  text={'Confirm role'}
                  onClick={() => {
                    reactGAEvents(category, 'confirm-role');
                    let valid = handleValidation();
                    setError(!valid);
                    setErrorText('Please fill out all required fields in order to continue.');
                    //api Call
                    if (valid) {
                      setConfirmModal({
                        title: 'You cannot change your role once you confirm!',
                        description: 'Are you ready to submit?',
                        checked: true,
                        fromConfirmRole: true,
                      });
                    }
                  }}
                  variant={'contained'}
                  size={'medium'}
                  endIcon={<StyledChangeHistoryOutlinedIcon />}
                  disabled={false}
                  disableRipple={true}
                  sx={btnStyleStartConfirm}
                />
              </StyledDropDownOptions>
            ) : (
              <></>
            )}
          </ConatinerBox>
        </>
      </HDModal>
      <HDModal
        open={confirmModal.checked}
        handleModal={() => {
          reactGAEvents(category, 'close-modal-x');
          setConfirmModal({ title: '', description: '', checked: false, fromConfirmRole: false });
        }}
        title={confirmModal.title}
        description={confirmModal.description}
        actions={
          <Box sx={{ flexDirection: 'column', textAlign: 'left', alignItems: 'flex-start' }}>
            <Box>
              {confirmModal.fromConfirmRole ?
                <HDButton
                  sx={{ ...btnStyleModal, padding: (confirmModal.fromConfirmRole) ? '' : '0 25px 0 0' }}
                  variant={confirmModal.fromConfirmRole ? 'contained' : 'text'}
                  onClick={() => {
                    handleConfirmRole();
                  }}
                  text={'Yes'}
                  endIcon={(isSubmit) ? <HDSpinner size={'la-sm'} /> : ''}
                  disabled={isSubmit}
                /> :
                <Link
                  onClick={() => {
                    handleConfirmRole();
                  }}
                  sx={{ ...btnStyleModal, color: theme.palette.mode == 'light' ? '#18768C' : '#66FCFC', padding: (confirmModal.fromConfirmRole) ? '' : '0 25px 0 0', ':hover': { textDecoration: 'underline' } }}
                  tabIndex={0}
                  underline='none'
                >
                  Yes, I will do this later
                  {(isSubmit) ? <HDSpinner size={'la-sm'} /> : ''}
                </Link>
              }

              {confirmModal.fromConfirmRole ?
                <Link
                  onClick={() => {
                    if (!isSubmit) {
                      reactGAEvents(category, 'submit-role-no');
                      setConfirmModal({
                        title: '',
                        description: '',
                        checked: false,
                        fromConfirmRole: false,
                      });
                    }
                  }
                  }
                  sx={{ ...btnStyleModal, color: theme.palette.mode == 'light' ? '#18768C' : '#66FCFC', marginLeft: '20px', ':hover': { textDecoration: 'underline' } }}
                  tabIndex={0}
                  underline='none'
                >
                  No, I need more time
                </Link> :
                <HDButton
                  sx={btnStyleModal}
                  variant={confirmModal.fromConfirmRole ? 'text' : 'contained'}
                  onClick={() => {
                    reactGAEvents(category, 'submit-role-no');
                    setConfirmModal({
                      title: '',
                      description: '',
                      checked: false,
                      fromConfirmRole: false,
                    })
                  }
                  }
                  text={'No'}
                />
              }
            </Box>
            <Box sx={{ color: '#DF43B6', marginTop: '10px' }}>
              {(isSubmit) ? <Box>Please wait, we are submitting your role.</Box> : ''}
            </Box>
          </Box>
        }
      />
    </>
  );
};

export default RoleModalWrapper;
